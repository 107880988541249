/*
 *   File : goal-redemption.js
 *   Author URI : https://evoqins.com
 *   Description :Goal redemption modal
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { Fragment, useEffect, useRef, useState } from "react"
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// Components
import { Icon } from "../Icon";
import { CustomAmountInput, CustomPINInput, RadioGroup } from "../FormElements";
import { LinkButton, PrimaryButton, SecondaryButton } from "../Buttons";
import { Loader } from "../Other";

// Services,helper 
import APIService from "../../Services/api-service";
import { _generate2Fa } from "../../Helper/api";
import { useCountdown } from "../../Helper/helper";

// Styles
import style from '../../Styles/Components/redemption.module.scss';
import Color from '../../Styles/color.module.scss';

// Store
import store from "../../Store";

const REDEMPTION_TYPES = [
    {
        name: "Smart redemption",
        id: 1
    },
    {
        name: "Custom",
        id: 2
    },
];

const GoalRedemptionModal = (props) => {
    const navigate = useNavigate();

    const PROFILE_DETAILS = store.getState().Reducer.PROFILE_DETAILS;
    const MASKED_NUMBER = PROFILE_DETAILS.phone.masked_number;

    const { seconds, resetCountdown } = useCountdown(30);
    const fundRef = useRef(null);

    const [apiLoader, setApiLLoader] = useState(true);
    const [goalOverview, setGoalOverview] = useState({});
    const [redeemAll, setRedeemAll] = useState(false);
    const [redemptionType, setRedemptionType] = useState(REDEMPTION_TYPES[0].id);
    const [amount, setAmount] = useState(props.amount);
    const [amountError, setAmountError] = useState("");

    const [stepLoader, setStepLoader] = useState(false);

    // screen number 1 redemption screen
    // screen number 2 otp screen
    // screen number 3 status screen
    const [screenNumber, setScreenNumber] = useState(1);

    const [redeemStep, setRedeemStep] = useState(1);
    const [mfList, setMfList] = useState([]);
    const [selectedMfs, setSelectedMfs] = useState([]);

    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");

    const [modalData, setModalData] = useState({});

    useEffect(() => {
        _goalRedeemOverview(redeemAll, null, 1);
        var my_modal = document.getElementById("goal-redemption");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        // eslint-disable-next-line
    }, []);

    // handle redemption type
    function _handleRedemptionType(id) {
        setRedemptionType(id);

    }

    // handle amount
    function _handleAmount(input_value) {
        setAmount(input_value);
        setAmountError("");
        setRedeemStep(1);
    }


    // handle OTP
    function _handleOTP(input_value) {
        if (input_value.length <= 4) {
            setOtp(input_value);
        }
        setOtpError("");
    }

    // resend OTP
    function _handleResend() {
        setOtp("");
        setOtpError("");
        _generate2FaApi();
    }

    // handle redeem all
    function _handleRedeemAll() {
        setRedeemAll(!redeemAll);
        setRedeemStep(1);
        setAmountError("");
        if (redeemAll === false) {
            setAmount(goalOverview.redeemable_amount);
        } else {
            setAmount("");
        }
    }

    // handle redeem all
    function _handleCustomRedeemAll(row, index, status) {
        const selected = [...selectedMfs];
        const mf_list = [...mfList];
        const selected_index = selected.findIndex((item) => item.mf_id === row.mf_id);
        mf_list[index].is_redeem_all = !status;
        selected[selected_index].is_redeem_all = !status;

        if (status === false) {
            mf_list[index].amount = row.total_amount; mf_list[index].amount = row.total_amount;
            selected[selected_index].amount = row.total_amount;
        } else {
            mf_list[index].amount = "";
            selected[selected_index].amount = "";
        }
        mf_list[index].error = ""
        setMfList(mf_list);
        setSelectedMfs(selected);
    }


    // handle select funds to redeem
    function _handleCustomRedeem(row, index) {
        const selected = [...selectedMfs];
        const mf_list = [...mfList];
        const selected_index = selected.findIndex((item) => item.mf_id === row.mf_id);
        if (selected_index === -1) {
            selected.push(row);
        } else {
            selected.splice(selected_index);
        }
        mf_list[index].error = "";
        mf_list[index].is_redeem_all = false;
        mf_list[index].amount = "";
        setMfList(mf_list);
        setSelectedMfs(selected);
    }

    // handle custom goal amount
    function _handleCustomAmount(row, index, amount_value) {
        const selected = [...selectedMfs];
        const mf_list = [...mfList];
        const selected_index = selected.findIndex((item) => item.mf_id === row.mf_id);
        mf_list[index].amount = amount_value;
        mf_list[index].error = "";
        selected[selected_index].amount = amount_value;
        setMfList(mf_list);
        setSelectedMfs(selected);
    }


    function _handleOrderNavigate() {
        navigate("/orders/goal-order", {
            state: {
                id: modalData.order_id,
                paymentTypes: [],
                orderTypes: [],
                orderStatus: [],
                startDate: null,
                endDate: null,
                type: props.type,
                page: 1
            }
        })
    }
    // validate custom
    const _validateCustom = () => {
        let valid = true;
        const mf_list = [...mfList];
        const selected_mfs = [...selectedMfs];
        if (selectedMfs.length === 0) {
            valid = false
            toast.dismiss()
            toast.error("Select funds and add amount to be redeemed", {
                type: "error"
            })
        }
        // below block will iterate each amount submitted by the user and will validate aganist amount 
        for (let fund_index = 0; fund_index < selected_mfs.length; fund_index++) {
            let selected_fund = selected_mfs[fund_index];
            const main_index = mf_list.findIndex((item) => item.mf_id === selected_fund.mf_id)

            if (selected_fund.amount === "") {
                mf_list[main_index].error = 'Enter valid amount or select Redeem all';
                valid = false;
            }
        }
        setSelectedMfs(selected_mfs);
        setMfList(mf_list)

        setMfList(mf_list)
        if (valid === true) {
            _goalRedeem();
        }
    }


    // API - resend otp
    const _generate2FaApi = () => {
        _generate2Fa("Goal", amount)
            .then((response) => {
                resetCountdown();
            }).catch((error) => {
                toast.dismiss();
                toast.error(error, {
                    type: "error",
                });
            });
    };

    // API -goal redeem overview
    const _goalRedeemOverview = (is_redeem_all, amount, step) => {
        setStepLoader(true)
        const url = "/goal/redeem-overview";
        const request = {
            goal_id: props.data.goal_id,
            redeem_all: is_redeem_all,
            amount: amount
        };

        if (step === 2 && amount === "") {
            setAmountError("Enter valid amount or select Redeem all");
            setStepLoader(false);
        } else {
            APIService(true, url, request).then((response) => {
                if (response.status_code === 200) {
                    setGoalOverview(response.data);
                    const mf_list = []
                    response.data.custom_redemption.map((item) => {
                        return (
                            mf_list.push({
                                is_redeem_all: false,
                                amount: "",
                                error: "",
                                mf_id: item.mf_id,
                                total_amount: item.amount,
                                total_amount_text: item.amount_text,
                                name: item.name,
                                image: item.image
                            })
                        )
                    })
                    setMfList(mf_list)
                    setRedeemStep(step);
                    if (step === 2) {
                        setTimeout(() => {
                            fundRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
                        }, 100);
                    }
                } else {
                    navigate("/portfolio");
                    toast.dismiss();
                    toast.error(response.message, {
                        type: "error"
                    })
                }
                setApiLLoader(false);
                setStepLoader(false);


            })
        }
    }



    // API -goal redeem overview
    const _goalRedeem = () => {
        const mfs = []
        selectedMfs.forEach((item) => {
            return (
                mfs.push({
                    mf_id: item.mf_id,
                    amount: item.amount,
                    redeem_all: item.is_redeem_all
                })
            )
        })
        setStepLoader(true);
        const url = "/goal/confirm-redeem";
        const request = {
            "goal_id": props.data.goal_id,
            "amount": redemptionType === 2 ? null : amount,
            "redeem_all": redemptionType === 2 ? null : redeemAll,
            "type": redemptionType === 1 ? null : "CUSTOM",
            "mf_list": redemptionType === 1 ? null : mfs
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {

                setScreenNumber(2);
                resetCountdown();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                })
            }
            setStepLoader(false)
        })
    }

    // handle submit otp validation
    async function _submitOTP() {
        let valid = true;
        if (otp === "") {
            setOtpError("OTP is required");
            valid = false;
        }
        if (otp !== "" && otp.length !== 4) {
            setOtpError("Invalid OTP");
            valid = false;
        }
        if (valid) {
            _redeemGoal()
        }
    }

    // API - redeem goal
    const _redeemGoal = () => {

        const mfs = []
        selectedMfs.forEach((item) => {
            return (
                mfs.push({
                    mf_id: item.mf_id,
                    amount: item.amount,
                    redeem_all: item.is_redeem_all
                })
            )
        })
        setStepLoader(true);

        const url = "/goal/redeem";
        const request = {
            "goal_id": props.data.goal_id,
            "amount": redemptionType === 2 ? null : amount,
            "otp": otp,
            "redeem_all": redemptionType === 2 ? null : redeemAll,
            "type": redemptionType === 1 ? null : "CUSTOM",
            "mf_list": redemptionType === 1 ? null : mfs
        }

        APIService(true, url, request).then((response) => {

            if (response.status_code === 200) {
                setScreenNumber(3);
                setModalData({
                    order_id: response.data.order_id,
                    status: 1,
                    image: require("../../Assets/Images/Modal/success.png"),
                    title: "Redeem order created",
                    description: response.data.t_days
                });

            } else {
                if (response.status_code === 400) {
                    toast.dismiss();
                    toast.error(response.message);
                    setOtp("");
                } else {
                    setScreenNumber(3);
                    setModalData({
                        image: require("../../Assets/Images/Modal/failed.png"),
                        status: 0,
                        title: "Redeem request failed",
                        description: response.message
                    });
                }
            }
            setStepLoader(false)
        })
    }


    return (
        <Fragment>
            <div className={`modal fade ${style.e_redemption_modal}`}
                id="goal-redemption"
                tabIndex="-1"
                aria-labelledby="goal-redemption"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content pb-4 ${style.e_content} `}>
                        {/* for screen number 1 and 2 */}
                        {
                            screenNumber !== 3 &&
                            <div className="border-bottom-1px border-ash-gray px-sm-4 px-3">
                                <div className="d-flex justify-content-between align-items-center pt-4 pb-3">
                                    <h5 className='e-font-16 mb-0 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                        {screenNumber === 2 && <Icon icon="Arrow-Right"
                                            size={22}
                                            className="e-transform-left-arrow cursor-pointer me-2"
                                            onClick={() => setScreenNumber(1)} />}  {screenNumber === 2 ? "Enter OTP " : "Redemption"}
                                    </h5>

                                    <Icon icon="close-btn"
                                        size={24}
                                        color={Color.eerie_black}
                                        id="close-goal"
                                        className="cursor-pointer"
                                        data-bs-dismiss="modal" />
                                </div>
                            </div>
                        }
                        {
                            apiLoader === true ?
                                <Loader height="h-60vh" />
                                :
                                // screen number 1 redemption screen
                                screenNumber === 1 ?
                                    <Fragment>
                                        <div className={style.e_height}>
                                            <div className="box-shadow-black p-3 ms-sm-4 me-sm-3 mx-3 border-radius-16px mt-3">
                                                <div className="d-flex align-items-center gap-8px">
                                                    <img src={goalOverview.icon}
                                                        alt="goal"
                                                        width={38}
                                                        height={38}
                                                        className="box-shadow-black border-radius-12px" />
                                                    <span className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black mb-0">Milestone: {goalOverview.name}</span>
                                                </div>
                                                <div className="row row-gap-8px mt-sm-3 mt-2">
                                                    <div className="col-6">
                                                        <span className="color-outer-space e-font-14 e-montserrat-regular e-line-height-16 mb-2">
                                                            Units
                                                        </span>
                                                        <p className="color-eerie-black e-font-14 e-montserrat-semi-bold e-line-height-20 mb-0">{goalOverview.units}</p>
                                                    </div>
                                                    <div className="col-6">
                                                        <span className="color-outer-space e-font-14 e-montserrat-regular e-line-height-16 mb-2">
                                                            Approx. value
                                                        </span>
                                                        <p className="color-eerie-black e-font-14 e-montserrat-semi-bold e-line-height-20 mb-0">{goalOverview.redeemable_amount_text}</p>
                                                    </div>
                                                    <div className="col-sm-6 mt-2">
                                                        <p className="color-outer-space e-font-14 e-montserrat-regular e-line-height-20 mb-0">
                                                            Redemption time
                                                        </p>
                                                        <LinkButton label="View TAT"
                                                            className="e-font-14 d-inline-block"
                                                            onPress={() => window.open(`${window.location.origin}/view-tat`, "_blank")} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-3 ms-sm-4 me-sm-3 mx-3">
                                                <h5 className='e-font-14 mb-1 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                                    Redeem type
                                                </h5>
                                                <RadioGroup data={REDEMPTION_TYPES}
                                                    selected={redemptionType}
                                                    handleSelect={_handleRedemptionType} />
                                                {redemptionType === 1 && <Fragment>
                                                    <p className="margin-12px-bottom mt-1 e-font-14 e-montserrat-regular e-line-height-24 color-eerie-black">Recommended amount by avoiding exit load and capital gain impact funds: <span className="color-rebecca-purple e-montserrat-bold">{goalOverview.recommended_smart_redemption_amount_text}</span></p>
                                                    <CustomAmountInput label="Enter redeem amount"
                                                        value={amount}
                                                        postfix="*"
                                                        disabled={redeemAll}
                                                        placeholder="50,000"
                                                        error={amountError}
                                                        handleChange={_handleAmount} />
                                                </Fragment>}
                                                {
                                                    redemptionType === 1 ?
                                                        <div className="d-flex align-items-start gap-8px mt-4 pt-1 e-montserrat-medium e-font-14 e-line-height-20 letter-spacing-007em cursor-pointer color-eerie-black mb-4 w-max-content"
                                                            onClick={_handleRedeemAll}>
                                                            <Icon icon={redeemAll ? "checked" : "unchecked"}
                                                                size={20} />
                                                            Redeem all
                                                        </div>
                                                        :
                                                        null
                                                }


                                                {
                                                    redemptionType === 1 ?
                                                        redeemStep === 2 ?
                                                            <div ref={fundRef} className="pb-1">
                                                                <h5 className='e-font-14 my-3 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                                                    Redemption breakdown
                                                                </h5>
                                                                <div className="box-shadow-black border-radius-24px py-sm-4 py-3 px-3 mb-4">
                                                                    <div className="row gx-1 padding-18px-bottom">
                                                                        <div className="col-9">
                                                                            <h6 className="e-font-12 color-eerie-black e-montserrat-semi-bold e-line-height-16 mb-0">Fund Name</h6>
                                                                        </div>
                                                                        <div className="col-3 text-end">
                                                                            <h6 className="e-font-12 color-eerie-black e-montserrat-semi-bold e-line-height-16 mb-0">Amount</h6>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        goalOverview.smart_redemption.map((item, index) => {
                                                                            return (
                                                                                <div className={index > 0 ? "row align-items-center gx-1 padding-18px-top" : "row gx-1 align-items-center"}
                                                                                    key={index}>
                                                                                    <div className="col-9">
                                                                                        <div className="d-flex align-items-center gap-8px">
                                                                                            <img src={item.image}
                                                                                                alt="goal"
                                                                                                width={32}
                                                                                                height={32}
                                                                                                className="box-shadow-black border-radius-6px" />
                                                                                            <span className="e-montserrat-semi-bold e-font-12 e-line-height-18 color-eerie-black mb-0 truncate-second-line">{item.name}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-3 text-end">
                                                                                        <h6 className="e-montserrat-semi-bold e-font-12 e-line-height-18 color-eerie-black mb-0">{item.amount_text}</h6>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                        :
                                                        <div className="pb-2">
                                                            <h5 className='e-font-14 my-3 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                                                Your redemption funds
                                                            </h5>
                                                            <div className="row gx-1 padding-18px-bottom">
                                                                <div className="col-9">
                                                                    <h6 className="e-font-12 color-eerie-black e-montserrat-semi-bold e-line-height-16 mb-0">Fund Name</h6>
                                                                </div>
                                                                <div className="col-3 text-end">
                                                                    <h6 className="e-font-12 color-eerie-black e-montserrat-semi-bold e-line-height-16 mb-0">Amount</h6>
                                                                </div>
                                                            </div>
                                                            {
                                                                mfList.map((item, index) => {
                                                                    return (
                                                                        <Fragment>
                                                                            <div className={index > 0 ? "row gx-1 align-items-center pt-2 cursor-pointer mb-3" : "row gx-1 align-items-center cursor-pointer mb-3 "}
                                                                                onClick={() => _handleCustomRedeem(item, index)}>
                                                                                <div className="col-9">
                                                                                    <div className="d-flex align-items-center gap-8px">
                                                                                        <span>
                                                                                            <Icon icon={selectedMfs.some((row) => row.mf_id === item.mf_id) ? "checked" : "unchecked"}
                                                                                                size={20} />
                                                                                        </span>
                                                                                        <img src={item.image}
                                                                                            alt="goal"
                                                                                            width={32}
                                                                                            height={32}
                                                                                            className="box-shadow-black border-radius-6px" />
                                                                                        <span className="e-montserrat-semi-bold e-font-12 e-line-height-18 color-eerie-black mb-0 truncate-second-line">{item.name}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-3 text-end">
                                                                                    <h6 className="e-montserrat-semi-bold e-font-12 e-line-height-18 color-eerie-black mb-0">{item.total_amount_text}</h6>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                selectedMfs.some((row) => row.mf_id === item.mf_id) &&
                                                                                <Fragment>
                                                                                    <CustomAmountInput value={item.amount}
                                                                                        label="Enter redeem amount"
                                                                                        postfix="*"
                                                                                        className="mt-3"
                                                                                        placeholder="50,000"
                                                                                        error={item.error}
                                                                                        disabled={item.is_redeem_all === true ? true : false}
                                                                                        handleChange={(input_value) => _handleCustomAmount(item, index, input_value)} />
                                                                                    <div className="d-flex align-items-start gap-8px mt-4 pt-1 e-montserrat-medium e-font-14 e-line-height-20 letter-spacing-007em cursor-pointer color-eerie-black mb-2 w-max-content"
                                                                                        onClick={() => _handleCustomRedeemAll(item, index, item.is_redeem_all)}>
                                                                                        <Icon icon={item.is_redeem_all === true ? "checked" : "unchecked"}
                                                                                            size={20} />
                                                                                        Redeem all
                                                                                    </div>
                                                                                </Fragment>
                                                                            }

                                                                        </Fragment>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                }

                                            </div>


                                        </div>
                                        {
                                            redemptionType === 1 ?
                                                redeemStep === 1 ?
                                                    <div className="row justify-content-center">
                                                        <div className="col-6">
                                                            <PrimaryButton label="Calculate" className="w-100 mt-4 mx-auto"
                                                                disabled={stepLoader}
                                                                onPress={() => _goalRedeemOverview(redeemAll, amount, 2)} />
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="row justify-content-center">
                                                        <div className="col-6">
                                                            <PrimaryButton label="Redeem" className="mt-4 mx-auto w-100"
                                                                disabled={stepLoader}
                                                                onPress={_goalRedeem} />
                                                        </div>
                                                    </div>
                                                :
                                                <div className="row justify-content-center">
                                                    <div className="col-6">
                                                        <PrimaryButton label="Redeem" className="mt-4 mx-auto w-100"
                                                            disabled={stepLoader}
                                                            onPress={_validateCustom} />
                                                    </div>
                                                </div>

                                        }
                                    </Fragment>
                                    :
                                    // screen number 2 otp screen
                                    screenNumber === 2 ?
                                        <div className="px-sm-4 px-3 padding-14px-top">
                                            <p className="e-font-14 mb-2 color-eerie-black e-montserrat-regular e-line-height-24">
                                                An OTP has been sent to your PAN linked mobile
                                                number.
                                            </p>
                                            <h6 className="e-font-16 mb-3 color-eerie-black e-montserrat-semi-bold e-line-height-24">{MASKED_NUMBER}</h6>
                                            <CustomPINInput id="otp"
                                                autoFocus={true}
                                                pinString={otp}
                                                error={otpError}
                                                postfix="*"
                                                label="Enter OTP"
                                                onSubmit={_submitOTP}
                                                handleChange={_handleOTP} />
                                            <p className="color-charcoal-blue e-montserrat-medium e-font-14 e-line-height-20 mt-4">Didn’t get OTP? <span className={`no-decoration color-rebecca-purple e-link 
                                ${seconds === 0 ?
                                                    'cursor-pointer'
                                                    : 'disabled'}`}
                                                onClick={_handleResend}>
                                                {seconds === 0 ?
                                                    'Resend OTP'
                                                    : `Resend OTP in ${seconds < 10 ? `0${seconds}` : seconds}s`}
                                            </span>
                                            </p>
                                            <div className=" mt-4">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <SecondaryButton label="Cancel"
                                                            cancel="modal"
                                                            className="padding-12px-tb w-100 e-bg-magnolia" />
                                                    </div>
                                                    <div className="col-6">
                                                        <PrimaryButton label="Verify"
                                                            className="padding-12px-tb  w-100"
                                                            disabled={stepLoader}
                                                            onPress={_submitOTP} />
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        :
                                        // screen number 3 status screen
                                        <div className="text-center px-sm-4 px-3">
                                            <img src={modalData.image}
                                                alt={modalData.title}
                                                draggable={false}
                                                width={144}
                                                height={144}
                                                className="mx-auto" />
                                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-eerie-black mb-0">
                                                {modalData.title}
                                            </h5>

                                            <p className="e-montserrat-medium e-font-16 e-line-height-28 color-charcoal-gray mt-2 mb-0 ">
                                                {modalData.description} {modalData.status === 1 && <LinkButton label="View TAT"
                                                    className="e-font-14 d-inline-block"
                                                    onPress={() => window.open(`${window.location.origin}/view-tat`, "_blank")} />}
                                            </p>

                                            <div className="row justify-content-center">
                                                {modalData.status === 1 && <div className="col-lg-6">
                                                    <SecondaryButton label="View Order"
                                                        className="w-100 padding-12px-tb mt-4"
                                                        cancel="modal"
                                                        onPress={_handleOrderNavigate} />
                                                </div>}

                                                <div className="col-lg-6">
                                                    <PrimaryButton label={modalData.status === 1 ? "Portfolio" : "View Portfolio"}
                                                        className="w-100 padding-12px-tb mt-4"
                                                        cancel="modal"
                                                        onPress={props.redeemSuccess} />
                                                </div>
                                            </div>
                                        </div>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

GoalRedemptionModal.defaultProps = {
    amount: "",
}

export default GoalRedemptionModal