/*
*   File : fund-filter.js
*   Author : https://evoqins.com
*   Description : Filter modal for explore page
*   Version : 1.0.0
*/

import { Fragment } from "react"
import { PrimaryButton, TertiaryButton } from "../Buttons"
import { Icon } from "../Icon";
import { CheckBoxGroup } from "../FormElements";
import Color from "../../Styles/color.module.scss"

const SearchComponent = (props) => {

    function _onFocus() {
        if (props.onFocus) {
            props.onFocus();
        }
    }

    return (
        <div className="position-relative">
            <Icon icon="Search"
                size={props.size}
                className="position-absolute e-search-icon" />

            <input value={props.inputState}
                placeholder={props.placeholder}
                type='text'
                className="color-black e-montserrat-medium e-font-14 e-line-height-20 w-100 no-outline padding-12px-all e-search-fund border-radius-12px padding-40px-left border-0"
                onChange={props.onChange}
                onFocus={_onFocus}
                onBlur={props.onBlur ? props.onBlur : null} />

            {
                props.close === true &&
                <Icon icon="close-btn"
                    size={20}
                    color={Color.black}
                    className="position-absolute  top-0 right-8px bottom-0 my-auto cursor-pointer"
                    onClick={props.clearSearch} />
            }

        </div>
    );
}

const FundFilter = ({ selectedAmc,
    selectedCategory,
    _clearAll,
    showAmc,
    _showAmc,
    searchAmc,
    _clearSearch,
    filteredAmcList,
    _handleCheckBoxGroup,
    _showCategory,
    showCategory,
    _searchAmc,
    categoryList,
    _applyCombinedFilter,
    showFilterModal }) => {

    return (
        <Fragment>
            <div className="e-filter border-radius-16px d-lg-block d-none">
                <div className="d-flex justify-content-between align-items-center px-3 padding-12px-tb border-bottom-1px border-platinum">
                    <span className="e-montserrat-medium e-font-16 e-line-height-24 color-eerie-black">Filter</span>


                    <span className={`e-montserrat-semi-bold e-font-14 e-line-height-24 color-rebecca-purple py-1 px-3 e-bg-magnolia border-radius-32px cursor-pointer ${(selectedAmc.length === 0 && selectedCategory.length === 0) && "disabled"}`}
                        onClick={_clearAll}>Clear all</span>

                </div>
                <div className="py-3 mx-3 border-bottom-1px border-platinum">
                    <div className={`d-flex justify-content-between align-items-center  cursor-pointer ${showAmc === true && "mb-3"}`}
                        onClick={_showAmc}>
                        <span className="e-montserrat-semi-bold e-font-14 e-line-height-24">AMC</span>
                        <Icon icon="header-drop-down"
                            size={20}
                            className={`e-explore-filter ${showAmc === true && "e-explore-filter-left-arrow"}`} />
                    </div>
                    {
                        showAmc === true &&
                        <Fragment>
                            <SearchComponent placeholder="Search AMC name"
                                size={16}
                                close={searchAmc.length !== 0 && true}
                                inputState={searchAmc}
                                clearSearch={_clearSearch}
                                onChange={_searchAmc} />
                            <div className="e-amc-scroll mt-3">
                                {
                                    filteredAmcList.length === 0 ?
                                        <p className="e-montserrat-regular e-font-14 e-line-height-24 mb-2 ">No data found</p>
                                        :
                                        <CheckBoxGroup data={filteredAmcList}
                                            selectedList={selectedAmc}
                                            type={1}
                                            className="flex-column"
                                            checkBoxItemClassName="my-2"
                                            checkBoxHandler={(value) => _handleCheckBoxGroup(1, value)} />
                                }
                            </div>

                        </Fragment>

                    }
                </div>
                <div className="py-3 mx-3">
                    <div className={`d-flex justify-content-between align-items-center  cursor-pointer`}
                        onClick={_showCategory}>
                        <span className="e-montserrat-semi-bold e-font-14 e-line-height-24">Category</span>
                        <Icon icon="header-drop-down"
                            size={20}
                            className={`e-explore-filter ${showCategory === true && "e-explore-filter-left-arrow"}`} />
                    </div>
                    {
                        showCategory === true &&
                        <Fragment>
                            <div className="e-amc-scroll mt-3">
                                <CheckBoxGroup data={categoryList}
                                    selectedList={selectedCategory}
                                    type={1}
                                    className="flex-column "
                                    checkBoxItemClassName="my-2"
                                    checkBoxHandler={(value) => _handleCheckBoxGroup(2, value)} />
                            </div>


                        </Fragment>

                    }
                </div>
                <div className="row  py-2 px-3">
                    <div className="col-6">
                        <TertiaryButton className="padding-10px-tb border-radius-12px w-100"
                            label="Cancel"
                            disabled={selectedCategory.length === 0 && selectedAmc.length === 0 && true}
                            showLoader={selectedCategory.length === 0 && selectedAmc.length === 0 && false}
                            onPress={_clearAll} />
                    </div>
                    <div className="col-6">
                        <PrimaryButton className="padding-10px-tb border-radius-12px w-100"
                            label="Apply"
                            disabled={selectedCategory.length === 0 && selectedAmc.length === 0 && true}
                            showLoader={selectedCategory.length === 0 && selectedAmc.length === 0 && false}
                            onPress={_applyCombinedFilter} />

                    </div>
                </div>
            </div>


            {/* ---------- filter for mobile--------- */}
            {
                showFilterModal === true &&
                <Fragment>
                    <div className="position-fixed bg-black opacity-5 w-100 h-100 z-index-4 top-0 start-0">
                    </div>
                    <div className="position-fixed z-index-5 e-explore-filter-modal">
                        <div className="row ">
                            <div className="col-12">
                                <div className="e-filter border-radius-16px bg-white">
                                    <div className="d-flex justify-content-between align-items-center px-3 padding-12px-tb border-bottom-1px border-platinum">
                                        <span className="e-montserrat-medium e-font-16 e-line-height-24 color-eerie-black">Filter</span>


                                        <span className={`e-montserrat-semi-bold e-font-14 e-line-height-24 color-rebecca-purple py-1 px-3 e-bg-magnolia border-radius-32px cursor-pointer
                                                    ${(selectedAmc.length === 0 && selectedCategory.length === 0) && "disabled"}`}
                                            onClick={_clearAll}>Clear all</span>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 pe-md-0">
                                            <div className="py-3 me-md-0 mx-3 pe-md-3 e-border-amc ">
                                                <div className={`d-flex justify-content-between align-items-center  cursor-pointer ${showAmc === true && "mb-3"}`}>
                                                    <span className="e-montserrat-semi-bold e-font-14 e-line-height-24">AMC</span>

                                                </div>
                                                <Fragment>
                                                    <SearchComponent placeholder="Search AMC name"
                                                        size={16}
                                                        close={searchAmc.length !== 0 && true}
                                                        inputState={searchAmc}
                                                        clearSearch={_clearSearch}
                                                        onChange={_searchAmc} />
                                                    <div className="e-amc-scroll mt-3">
                                                        {
                                                            filteredAmcList.length === 0 ?
                                                                <p className="e-montserrat-regular e-font-14 e-line-height-24 mb-2 ">No data found</p>
                                                                :
                                                                <CheckBoxGroup data={filteredAmcList}
                                                                    selectedList={selectedAmc}
                                                                    type={1}
                                                                    className="flex-column"
                                                                    checkBoxItemClassName="my-2"
                                                                    checkBoxHandler={(value) => _handleCheckBoxGroup(1, value)} />
                                                        }
                                                    </div>

                                                </Fragment>
                                            </div>
                                        </div>
                                        <div className="col-md-6">

                                            <div className="py-3 ms-md-0 mx-3">
                                                <div className={`d-flex justify-content-between align-items-center  cursor-pointer`}>
                                                    <span className="e-montserrat-semi-bold e-font-14 e-line-height-24">Category</span>

                                                </div>
                                                <Fragment>
                                                    <div className=" e-category-scroll mt-3">
                                                        <CheckBoxGroup data={categoryList}
                                                            selectedList={selectedCategory}
                                                            type={1}
                                                            className="flex-column "
                                                            checkBoxItemClassName="my-2"
                                                            checkBoxHandler={(value) => _handleCheckBoxGroup(2, value)} />
                                                    </div>


                                                </Fragment>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row gx-0 py-2 px-3 border-top-1px border-platinum">
                                        <div className="col-6 pe-2">
                                            <TertiaryButton className="padding-10px-tb border-radius-12px w-100"
                                                label="Cancel"
                                                showLoader={selectedCategory.length === 0 && selectedAmc.length === 0 && false}
                                                onPress={_clearAll} />
                                        </div>
                                        <div className="col-6 ps-2">
                                            <PrimaryButton className="padding-10px-tb border-radius-12px w-100"
                                                label="Apply"
                                                disabled={selectedCategory.length === 0 && selectedAmc.length === 0 && true}
                                                showLoader={selectedCategory.length === 0 && selectedAmc.length === 0 && false}
                                                onPress={_applyCombinedFilter} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            }
        </Fragment>
    )
}

export default FundFilter