/*
 *   File : select-career.js
 *   Author : https://evoqins.com
 *   Description : Modal component to set customer's current role. Depending on this we will change the Goals and investment types.
 *   Integrations : NA
 *   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Icon } from "../Icon";

// import components
import { PrimaryButton } from "../Buttons";
import APIService from "../../Services/api-service";

// import styles
import style from "../../Styles/Components/select-career.module.scss"

const SelectCareerModal = (props) => {

    const [selectedValue, setSelectedValue] = useState(null);
    const [step, setStep] = useState(props.step ? props.step : 1);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        if (props.data.length !== 0) {
            setSelectedValue(props.data[0].id)
        }
    }, [props.data]);

    const _handleClick = (item) => {
        setSelectedValue(item.id);
    }

    // API - set investor type
    const _saveInvestorType = () => {
        setLoader(true);
        const url = "/home/set-investor-type"
        const request = {
            "investor_type_id": selectedValue,
        }

        APIService(true, url, request, props.token).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                setStep(2);
            } else {
                toast.error(response.message, {
                    type: "error",
                });
            }
        })
        setLoader(false);
    }

    return (
        <div className={`modal fade ${style.e_select_career_modal}`}
            id="select-career"
            tabIndex="-1"
            aria-labelledby="select-career"
            aria-hidden="true"
            data-bs-backdrop="static"
            data-bs-keyboard="false">

            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content ${style.e_content} `}>
                    {
                        step === 1 ?
                            <div className="px-md-4 px-3 pb-md-4 pb-3 ">
                                <div className="d-flex justify-content-center">
                                    <img src={require("../../Assets/Images/Home/select-type.png")}
                                        alt="Select-type"
                                        width={120}
                                        draggable={false} />
                                </div>
                                <p className="e-montserrat-semi-bold e-font-16 e-line-height-28 color-eerie-black mb-2 text-center">Select who you are?</p>
                                <p className="e-montserrat-medium e-font-14 e-line-height-20 color-quartz margin-20px-bottom text-center">Tailored solutions that fits your profile.</p>
                                <div className={`d-flex flex-column gap-12px ${style.e_list} margin-14px-bottom`}>
                                    {
                                        props.data.map((item, key) => {
                                            let is_selected = selectedValue === item.id;
                                            return (
                                                <div key={key}
                                                    className={`padding-10px-tb padding-12px-lr border-1px  cursor-pointer border-radius-12px 
                                                    ${is_selected ?
                                                            "border-medium-slate-blue e-bg-light-lavender"
                                                            : "border-bright-gray"
                                                        }`}
                                                    onClick={() => _handleClick(item)}>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="d-flex gap-8px align-items-center">
                                                            <img src={item.icon}
                                                                alt={item.name}
                                                                draggable={false}
                                                                width={24}
                                                                height={24} />
                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black">{item.name}</p>
                                                        </div>
                                                        <Icon icon={
                                                            is_selected ?
                                                                'radio-checked' :
                                                                'radio-unchecked'
                                                        }
                                                            size={20} />
                                                    </div>
                                                    {
                                                        is_selected &&
                                                        <p className={`e-montserrat-regular e-font-12 e-line-height-20 color-dark-charcoal mb-0 mt-2 ${style.e_description}`}>
                                                            {item.description}
                                                        </p>
                                                    }
                                                </div>
                                            )

                                        })
                                    }
                                </div>
                                <PrimaryButton className="padding-12px-tb w-100 border-radius-12px"
                                    disabled={loader}
                                    label="Continue"
                                    onPress={_saveInvestorType} />
                            </div>
                            :
                            <Fragment>
                                <div className="px-md-4 px-3 position-relative">
                                    <div className="d-flex justify-content-center">
                                        <img src={require("../../Assets/Images/Home/start-today.png")}
                                            alt="Select-type"
                                            width={114}
                                            draggable={false} />
                                    </div>
                                    <p className="e-montserrat-medium e-font-14 e-line-height-20 color-quartz margin-20px-bottom text-center">Lets begin with your Risk Profiling</p>
                                    <p className="e-montserrat-semi-bold e-font-18 e-line-height-28 color-eerie-black mb-2 text-center">Begin Today!</p>
                                    <ul className="margin-14px-bottom d-flex flex-column gap-20px ps-md-4 ps-3">
                                        <li className="e-montserrat-regular e-font-14 e-line-height-22 color-black-olive"><span className="e-montserrat-bold dark-charcoal">Goal Alignment:</span> Aligning investments with financial goals.</li>
                                        <li className="e-montserrat-regular e-font-14 e-line-height-22 color-black-olive"><span className="e-montserrat-bold dark-charcoal">Long-Term Planning:</span>  Enhances planning, shaping strategy for achieving financial goals.</li>
                                    </ul>
                                    {/* <Icon icon="close"
                                        data-bs-dismiss="modal"
                                        size={24}
                                        className="position-absolute right-24px top-24px cursor-pointer"
                                        onClick={props.confirm} /> */}
                                </div>
                                <div className="e-bg-lavender-web pe-4 mb-4 padding-10px-tb d-flex align-items-center gap-4px">
                                    <img src={require("../../Assets/Images/Home/flower_gif.gif")}
                                        alt="Flower gif"
                                        width={64}
                                        height={64}
                                        draggable={false} />
                                    <p className="e-montserrat-medium e-font-14 e-line-height-22 mb-0 color-regalia">Risk profiling helps in determining one’s attitude towards investment.</p>
                                </div>
                                <div className="d-flex gap-12px mx-md-4 mx-3 mb-md-4 mb-3">
                                    {/* <TertiaryButton className="padding-12px-tb padding-52px-lr border-radius-12px"
                                        label="Skip"
                                        cancel="modal"
                                        onPress={props.confirm} /> */}
                                    <PrimaryButton className="padding-12px-tb w-100 border-radius-12px"
                                        label="Take risk profiling"
                                        cancel="modal"
                                        onPress={props.navigate} />
                                </div>
                            </Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

export default SelectCareerModal