/*
*   File : systematic-plan.js
*   Author : https://evoqins.com
*   Description : 
*   Version : 1.0.0
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Icon } from "../../Components/Icon";
import { SIP } from "../SIP";
import { Header } from "../../Components/Header";
import { Breadcrumb } from "../../Components/Other";
import { SWP } from "../SWP";
import { STP } from "../STP";

const SYSTEMATIC_TRANSACTION = [{
    id: 1,
    image: "sip",
    label: "SIP",
},
{
    id: 2,
    image: "swp",
    label: "SWP",
},
{
    id: 3,
    image: "stp",
    label: "STP",
}
]

const Listing = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(SYSTEMATIC_TRANSACTION[0].id);

    function _handleNavigate() {
        navigate("/");
    }

    useEffect(() => {
        if (location.state !== null) {
            setActiveTab(location.state.tab_index)
        } else {
            setActiveTab(1);
        }
        // eslint-disable-next-line
    }, [location.state]);


    const _handleTab = (id) => {
        setActiveTab(id);
        navigate("/systematic-transactions", {
            state: {
                tab_index: id,
                filter: 1,
                filter_label: "Milestones"
            }
        })
    }

    return (
        <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
            {/* Header */}
            <Header type={2} />
            <div className="row ">
                <div className="col-lg-11 pe-lg-0 padding-144px-bottom">

                    <div className="px-4">
                        <Breadcrumb mainPage="Home"
                            currentPage="Systematic Transactions"
                            handleNavigate={_handleNavigate}
                            type={1} />

                        <h5 className="margin-30px-tb e-font-18 e-line-height-38 e-montserrat-semi-bold color-dark-jungle-green">Systematic Transactions </h5>
                        <div className="d-lg-none d-flex gap-16px mb-2">
                            {
                                SYSTEMATIC_TRANSACTION.map((item, key) =>
                                    <div className={`${activeTab === item.id ? "e-bg-light-lavender border-purple color-rebecca-purple border" : "color-eerie-black border cursor-pointer"} border-radius-24px px-3 py-2 color-eerie-black e-montserrat-semi-bold e-font-14 e-line-height-16`}
                                        key={key}
                                        onClick={() => {
                                            if (item.id !== activeTab) {
                                                _handleTab(item.id)
                                            }
                                        }}>
                                        {item.label}
                                    </div>

                                )
                            }
                        </div>
                        <div className="row gx-3">
                            <div className="col-3 d-lg-block d-none">
                                <div className="e-profile-border border-radius-24px">
                                    {
                                        SYSTEMATIC_TRANSACTION.map((item, key) =>
                                            <div className={`${activeTab === item.id ? "e-bg-light-lavender" : "cursor-pointer"} 
                                                        ${key === 0 && "border-radius-tlr-24px"} 
                                                        ${key !== SYSTEMATIC_TRANSACTION.length - 1 && "border-bottom-1px border-chinese-white"}
                                                        ${key === SYSTEMATIC_TRANSACTION.length - 1 && "border-radius-blr-24px"}
                                                        padding-18px-tb padding-12px-lr padding-22px-bottom `}
                                                key={key}
                                                onClick={() => {
                                                    if (item.id !== activeTab) {
                                                        _handleTab(item.id)
                                                    }
                                                }}>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="d-flex align-items-center gap-8px">
                                                        <Icon icon={item.image}
                                                            size={20} />
                                                        <p className="e-montserrat-semi-bold e-font-14 e-line-height-24 color-eerie-black mb-0">{item.label}</p>
                                                    </div>
                                                    <Icon icon="arrow-right"
                                                        size={20} />
                                                </div>

                                            </div>
                                        )
                                    }

                                </div>

                            </div>
                            <div className="col-lg-9">
                                {
                                    activeTab === 1 ?
                                        <SIP />
                                        :
                                        activeTab === 2 ?
                                            <SWP />
                                            :
                                            <STP />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Listing