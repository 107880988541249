
/*
 *   File : cancel-sip.js
 *   Author URI : https://evoqins.com
 *   Description : Modal to cancel sip
 *   Integrations : null
 *   Version : v1.1
 */


// Packages
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";

// Components
import { CustomPINInput, CustomSelectBox } from "../FormElements";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { Icon } from "../Icon";

// Styles
import style from "../../Styles/Components/cancel-sip.module.scss";

// Services
import APIService from "../../Services/api-service";
import { useSelector } from "react-redux";
import { _generate2Fa } from "../../Helper/api";
import { useCountdown } from "../../Helper/helper";

const CancelSIPModal = (props) => {
    const PROFILE_DETAILS = useSelector(state => state.Reducer.PROFILE_DETAILS);
    const MASKED_NUMBER = PROFILE_DETAILS.phone.masked_number;

    const [selectedReason, setSelectedReason] = useState(null);
    const [selectedReasonError, setSelectedReasonError] = useState("");
    const [cancelLoader, setCancelLoader] = useState(false);
    const [screenNumber, setScreenNumber] = useState(1);
    const { seconds, resetCountdown } = useCountdown(30);
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");
    const [modalLoader, setModalLoader] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("cancel-sip");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);

    // API - consent call
    const _save = () => {
        if (selectedReason === null) {
            setSelectedReasonError("Please select reason for cancellation");
        } else {
            _generate2Fa("Cancel SIP", props.fund.amount)
                .then((response) => {
                    setScreenNumber(2);
                    resetCountdown();

                }).catch((error) => {
                    toast.dismiss();
                    toast.error(error, {
                        type: "error",
                    });
                });
        }
    }


    // handle OTP
    function _handleOTP(input_value) {
        if (input_value.length <= 4) {
            setOtp(input_value);
        }
        setOtpError("");
    }

    const _handleSelectBox = (value) => {
        setSelectedReason(value);
        setSelectedReasonError("");
    }

    // resend OTP
    function _handleResend() {
        setOtp("");
        setOtpError("");
        _generate2FaApi();
    }

    // API - resend otp
    const _generate2FaApi = () => {
        _generate2Fa("Cancel SIP", props.fund.amount)
            .then((response) => {
                resetCountdown();
            }).catch((error) => {
                toast.dismiss();
                toast.error(error, {
                    type: "error",
                });
            });
    };

    const _handleBack = () => {
        setScreenNumber(1);
        setOtp("");
        setOtpError("");
    }

    // handle submit otp validation
    function _submitOTP() {
        let valid = true;
        if (otp === "") {
            setOtpError("OTP is required");
            valid = false;
        }
        if (otp !== "" && otp.length !== 4) {
            setOtpError("Invalid OTP");
            valid = false;
        }
        if (valid) {
            setModalLoader(true);
            _cancelSIP();
        }
    }

    // API - Cancel sip
    const _cancelSIP = () => {

        setCancelLoader(true);

        const url = "/sip/cancel";

        const request = {
            "sip_id": props.id,
            "cancellation_reason_id": selectedReason.value,
            "otp": otp
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
                toast.success("SIP Cancelled", {
                    type: 'success'
                });
                props.success();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                });
                setOtp("");
            }
            setCancelLoader(false);
        });

    }

    return (
        <Fragment>
            <div className={`modal fade ${style.e_cancel_sip}`}
                id="cancel-sip"
                tabIndex="-1"
                aria-labelledby="cancel-sip"
                aria-hidden="true"
                data-bs-keyboard="false" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content pb-4 px-4 ${style.e_content} `}>
                        <span id="close-modal"
                            className="d-none"
                            data-bs-dismiss="modal" ></span>

                        {
                            screenNumber === 1 ?
                                <Fragment>
                                    <img src={require("../../Assets/Images/Modal/delete.png")}
                                        alt="success"
                                        draggable={false}
                                        width={144}
                                        height={144}
                                        className={`mx-auto ${style.e_cancel}`} />
                                    <h5 className="e-font-18 e-line-height-24 e-montserrat-semi-bold color-eerie-black mb-2 text-center">
                                        Do you want to Cancel this SIP?
                                    </h5>
                                    <p className="e-montserrat-medium e-font-16 e-line-height-28 color-quartz text-center mb-4">
                                        Please confirm the cancellation of the SIP by selecting the reason and confirming.
                                    </p>


                                    <CustomSelectBox placeholder={selectedReason === null ? "Select cancellation reason" : ""}
                                        label="Why do you want to cancel this SIP?"
                                        postFix={true}
                                        className="w-100"
                                        value={selectedReason}
                                        error={selectedReasonError}
                                        options={props.options}
                                        onSelectChange={_handleSelectBox} />
                                    <div className="row mt-4 pt-2">
                                        <div className="col-6">
                                            <SecondaryButton label="Back"
                                                cancel="modal"
                                                className="padding-10px-tb w-100 e-bg-lavender-frost" />
                                        </div>
                                        <div className="col-6">
                                            <PrimaryButton label="Proceed"
                                                className="padding-10px-tb  w-100"
                                                disabled={cancelLoader}
                                                onPress={_save} />
                                        </div>
                                    </div>
                                </Fragment>
                                :

                                <div className="pt-4">
                                    <p className="e-montserrat-semi-bold e-font-16 e-line-height-24 color-eerie-black mb-2">
                                        <Icon icon="Arrow-Right"
                                            size={22}
                                            className="e-transform-left-arrow cursor-pointer me-2"
                                            onClick={_handleBack} />
                                    </p>
                                    <p className="e-font-14 mb-2 color-eerie-black e-montserrat-regular e-line-height-24">
                                        An OTP has been sent to your PAN linked mobile
                                        number.
                                    </p>
                                    <h6 className="e-font-16 mb-3 color-eerie-black e-montserrat-semi-bold e-line-height-24">{MASKED_NUMBER}</h6>
                                    <CustomPINInput id="otp"
                                        autoFocus={true}
                                        pinString={otp}
                                        error={otpError}
                                        postfix="*"
                                        label="Enter OTP"
                                        onSubmit={_submitOTP}
                                        handleChange={_handleOTP} />
                                    <p className="color-charcoal-blue e-montserrat-medium e-font-14 e-line-height-20 mt-4">Didn’t get OTP? <span className={`no-decoration color-rebecca-purple e-link 
                                                            ${seconds === 0 ?
                                            'cursor-pointer'
                                            : 'disabled'}`}
                                        onClick={_handleResend}>
                                        {seconds === 0 ?
                                            'Resend SMS'
                                            : `Resend SMS in ${seconds < 10 ? `0${seconds}` : seconds}s`}
                                    </span>
                                    </p>
                                    <div className="row margin-32px-top">
                                        <div className="col-6">
                                            <SecondaryButton label="Cancel"
                                                className="padding-12px-tb w-100 e-bg-magnolia"
                                                cancel="modal" />
                                        </div>
                                        <div className="col-6">
                                            <PrimaryButton label="Verify"
                                                className="padding-12px-tb  w-100"
                                                disabled={modalLoader}
                                                onPress={_submitOTP} />
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CancelSIPModal