
/*
 *   File : create-esign.js
 *   Author URI : https://evoqins.com
 *   Description : Modal to create E-SIGN
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import React, { Fragment, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// Custom components

// Custom styles
import style from "../../Styles/Components/fund-allocation.module.scss";

// services
import APIService from "../../Services/api-service";
import { _getSignupProgress } from "../../Helper/api";


const EsignCreateModal = React.memo((props) => {
    const navigate = useNavigate("/")

    useEffect(() => {
        var my_modal = document.getElementById("create-esign");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        var my_modal = document.getElementById("create-esign");
        my_modal.addEventListener("shown.bs.modal", () => {
            _initiateEsign();
        });
        //eslint-disable-next-line
    }, []);

    // API - create esign
    const _initiateEsign = () => {
        const url = "/kyc/create-esign";

        APIService(false, url).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                let e_sign_window = window.open(response.data.redirect_url, '_blank');

                let timer = setInterval(function () {
                    if (e_sign_window.closed) {
                        clearInterval(timer);
                        _getSignupProgress().then((response) => {
                            if (response.kyc_data.status === "KYC_ESIGN_REQUIRED") {
                                document.getElementById("close-modal").dispatchEvent(new Event("click"));
                                props.reSubmit();
                                navigate("/")
                                toast.dismiss();
                                toast.error("Please complete your E-sign verification", {
                                    type: "error"
                                })                               
                            } else {
                                document.getElementById("close-modal").dispatchEvent(new Event("click"));
                                props.submitInvestment();
                            }
                        })
                    }
                }, 1000);
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
                setTimeout(() => {
                    document.getElementById("close-modal").dispatchEvent(new Event("click"));
                }, 2000);
            }
        });
    }


    return (
        <Fragment>
            <div className={`modal fade ${style.e_esign_modal}`}
                id="create-esign"
                tabIndex="-1"
                aria-labelledby="create-esign"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content text-center padding-12px-lr ${style.e_content} `}>
                        <span id="close-modal"
                            className="d-none"
                            data-bs-dismiss="modal" ></span>
                        <Fragment>
                            <img src={require("../../Assets/Images/KYC/esign.gif")}
                                alt="success"
                                draggable={false}
                                width={300}
                                className="mx-auto object-fit-contain" />
                        </Fragment>
                    </div>
                </div>
            </div>
        </Fragment>
    )
})

EsignCreateModal.defaultProps = {
    closeModal: () => { },
    handleNavigate: () => { }
}

export default EsignCreateModal