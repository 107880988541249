/*
*   File : explore.js
*   Author : https://evoqins.com
*   Description : Explore screen
*   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";

// import components
import { Breadcrumb, EmptyScreen, Loader, SectionTitle } from "../../Components/Other"
import { Header } from "../../Components/Header";
import { Icon } from "../../Components/Icon";
import { CustomSelectBox } from "../../Components/FormElements";
import { DataTableContainer } from "../../Components/Table";
import { _forceNavigate, useClickOutside } from "../../Helper/helper";
import store from "../../Store";
import { FundFilter } from "../../Components/Filter";

// import services
import APIService from "../../Services/api-service";

// import styles
import Color from "../../Styles/color.module.scss";
import { Toggle } from "../Others";


const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
            backgroundColor: 'transparent'
        },
    },
    head: {
        style: {
            height: "0px"
        }
    },
    headRow: {
        style: {
            borderBottomColor: 'transparent',

        }
    },
    rows: {
        style: {
            cursor: "pointer",
            // '&:not(:last-child)': {
            // },
            borderBottom: `1px solid ${Color.gainsboro}`,
            '&:hover': {
                '& .e-row-title': {
                    color: Color.rebecca_purple
                },
                boxShadow: "5px 3px 15px 0px #0000001A, 0px -3px 15px 0px #0000001A",
            },
            padding: "24px",
            backgroundColor: 'transparent',
        },
    },
    cells: {
        style: {
            paddingLeft: '0px !important'
        }
    }

}

const NextIcon = () => {
    return (
        <Icon icon='Arrow-Right'
            width="24px"
            height="24px"
            color={Color.rebecca_purple} />
    )
}

const PrevIcon = () => {
    return (
        <Icon icon='Arrow-Right'
            width="24px"
            height="24px"
            color={Color.rebecca_purple}
            className="e-transform-left-arrow" />
    )
}

const BreakLabel = () => {
    return (
        <span>...</span>
    )
}

const SearchComponent = (props) => {

    function _onFocus() {
        if (props.onFocus) {
            props.onFocus();
        }
    }

    return (
        <div className="position-relative">
            <Icon icon="Search"
                size={props.size}
                className="position-absolute e-search-icon" />

            <input value={props.inputState}
                placeholder={props.placeholder}
                type='text'
                className="color-black e-montserrat-medium e-font-14 e-line-height-20 w-100 no-outline padding-12px-tb e-search-fund border-radius-12px padding-40px-left padding-38px-right border-0"
                onChange={props.onChange}
                onFocus={_onFocus}
                onBlur={props.onBlur ? props.onBlur : null} />

            {
                props.close === true &&
                <Icon icon="close-btn"
                    size={20}
                    color={Color.black}
                    className="position-absolute  top-0 right-8px bottom-0 my-auto cursor-pointer"
                    onClick={props.clearSearch} />
            }

        </div>
    );
}

const Explore = () => {
    const GENERAL_DATA = store.getState().Reducer.GENERAL_DATA;

    const location = useLocation();
    const navigate = useNavigate();
    const searchRef = useRef(null);

    const [fundName, setFundName] = useState("");
    const [sortByOptions, setSortByOptions] = useState([]);
    const [sortBy, setSortBy] = useState({});
    const [showAmc, setShowAmc] = useState(true);
    const [searchAmc, setSearchAmc] = useState("");
    const [selectedAmc, setSelectedAmc] = useState([]);
    const [amcList, setAmcList] = useState([]);
    const [filteredAmcList, setFilteredAmcList] = useState([]);
    const [showCategory, setShowCategory] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [pageCount, setPageCount] = useState(0); //total number of pages
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const [chipList, setChipList] = useState([]);
    const [screenLoader, setScreenLoader] = useState(true);
    const [fundLoader, setFundLoader] = useState(true);
    const [fundList, setFundList] = useState([]);
    const [initialLoad, setInitialLoad] = useState(true);
    const [recentSearches, setRecentSearches] = useState([]);
    const [showRecentSearch, setShowRecentSearch] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [recommended, setRecommended] = useState(GENERAL_DATA.recommended_fund_status);
    const [fundChange, setFundChange] = useState(false);

    const TABLE_COLUMNS = [
        {
            cell: row => <div className="d-flex gap-8px"
                onClick={() => _handleRowClick(row)}>
                <img src={row.image}
                    width={48}
                    height={48}
                    alt={row.scheme_name}
                    draggable={false}
                    className="border-radius-12px e-fund-detail-chart object-fit-contain" />
                <div className="d-flex gap-8px flex-column">
                    <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{row.scheme_name}</p>
                    <p className="e-montserrat-medium e-font-14 e-line-height-20 mb-0 color-outer-space"><span className="e-type position-relative me-3">{row.asset_class}</span>{row.scheme_primary_category}</p>
                </div>
            </div>,
            sortable: false,
            width: "450px"
        },
        {
            cell: row => <div onClick={() => _handleRowClick(row)}>
                <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">{row.returns_label} returns</p>
                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 ${row.returns < 0 ? "color-deep-carmine-pink" : row.returns > 0 ? "color-ufo-green" : "color-outer-space"}`}>{row.returns}%</p>
            </div>,
            sortable: false,
        },
        {
            cell: row => <div onClick={() => _handleRowClick(row)}
                className="pe-5 me-5">
                <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Risk</p>
                <img src={row.risk_icon}
                    alt={row.risk}
                    height={22}
                    width={row.risk === "Low" ? 86 : row.risk === "Moderate" ? 116 : row.risk === "High" ? 90 : 112}
                    className="object-fit-contain"
                    draggable={false} />
            </div>,
            sortable: false,
        },
        {
            cell: row => <div onClick={() => _handleRowClick(row)}>
                <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Fund Size</p>
                <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black mb-0">{row.fund_size}</p>
            </div>,
            sortable: false,
        }
    ]

    // close recent search list view
    useClickOutside(searchRef, () => {
        setShowRecentSearch(false);
    });

    // to get recent search history
    useEffect(() => {
        _getFilterData();
        // Retrieve items from localStorage
        const recent_searches = localStorage.getItem('recent_search');
        if (recent_searches != null) {
            // Parse JSON string to array of objects
            setRecentSearches(JSON.parse(recent_searches));
        }
    }, []);

    // to enable/disable body scroll when modal is open/close respectively
    useEffect(() => {
        if (showFilterModal) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        // Cleanup function to remove the class when the component unmounts
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [showFilterModal]);

    _forceNavigate(() => {
        //eslint-disable-next-line
        if (location.pathname == "/explore") {
            navigate(-2)
        }
    });

    // scroll to top of the page whenever filter or page number changes
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [chipList, currentPage]);

    // to show/hide recent searches 
    useEffect(() => {
        if (showRecentSearch === true) {
            if (fundName.length > 2) {
                setShowRecentSearch(false);
            } else if (recentSearches.length !== 0) {
                setShowRecentSearch(true);
            }
        }
    }, [fundName, recentSearches, showRecentSearch]);

    useEffect(() => {
        if (!initialLoad) {
            setFundLoader(true);
            _getFundList();
        }
        // eslint-disable-next-line
    }, [currentPage, chipList, sortBy, initialLoad, recommended]);

    useEffect(() => {
        if (Object.keys(GENERAL_DATA).length > 0) {
            setRecommended(GENERAL_DATA.recommended_fund_status);
        }
    }, [GENERAL_DATA])

    // updating chip list on change of amc and category
    useEffect(() => {
        if (screenLoader === false) {
            if (location.state !== null) {
                setFundName(location.state.query);
                if (location.state.recommended !== undefined) {
                    setRecommended(location.state.recommended)
                }
                if (!location.state.hasOwnProperty('filter_id')) {
                    if (location.state.chipList !== undefined) {
                        setChipList(location.state.chipList);
                        setCurrentPage(location.state.pageNumber);
                        // filter out amc and category list
                        const updated_amc_list = location.state.chipList.filter(chip => chip.type === 1);
                        const updated_category_list = location.state.chipList.filter(chip => chip.type === 2);


                        const selected_amc_id = [];
                        const selected_category_id = [];
                        updated_amc_list.forEach(chip => {
                            selected_amc_id.push(chip.value);
                        });
                        setSelectedAmc(selected_amc_id);
                        if (selected_amc_id.length !== 0) {
                            setShowAmc(true);
                        }

                        updated_category_list.forEach(chip => {
                            selected_category_id.push(chip.value);
                        });
                        setSelectedCategory(selected_category_id);
                        if (selected_category_id.length !== 0) {
                            setShowCategory(true);
                        }
                    }
                    setSortBy(location.state.sort);
                }
                else {
                    const foundItem = categoryList.find(item => item.id === location.state.filter_id);
                    if (foundItem) {
                        const chip = { type: 2, value: foundItem.id, label: foundItem.label };
                        setChipList([chip]);
                    }
                    if (location.state.sort) {
                        setSortBy(location.state.sort);
                    }

                    setSelectedCategory([location.state.filter_id])
                }

                // updating table with page number
            }
            setInitialLoad(false);
        }
        // eslint-disable-next-line
    }, [location, screenLoader]);

    // filter amc based on search text
    useEffect(() => {
        const _filterAmcList = () => {
            const filtered = amcList.filter((item) =>
                item.label.toLowerCase().includes(searchAmc.toLocaleLowerCase())
            );
            setFilteredAmcList(filtered);
        };
        _filterAmcList();
        // eslint-disable-next-line
    }, [searchAmc]);

    // call fundlisting api only after user has stopped typing
    useEffect(() => {
        if (fundChange === true) {
            const handleDebounce = () => {
                setFundLoader(true);
                _getFundList();
            };

            const debounce_timeout = setTimeout(handleDebounce, 500);

            return () => {
                clearTimeout(debounce_timeout);
            };
        }
        // eslint-disable-next-line
    }, [fundName, fundChange]);


    // Handle page change
    const _handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber + 1);
    };

    // handle search fund
    const _handleFundChange = (event) => {
        setFundChange(true)
        setFundName(event.target.value);
        if (GENERAL_DATA.recommended_fund_status === true) {
            // if (event.target.value === "") {
            //     setRecommended(GENERAL_DATA.recommended_fund_status);
            // } else {
            setRecommended(false);
            // }
        }

        // Hide recent searches if input length exceeds 2
        if (event.target.value.length > 2) {
            setShowRecentSearch(false);
        } else if (event.target.value === "") {
            setShowRecentSearch(false);
        }
        // else if (recentSearches.length !== 0) {
        //     setShowRecentSearch(true);
        //     setRecommended(GENERAL_DATA.recommended_fund_status);
        // }
        // if (location.state !== null) {
        //     _clearAll();
        // }
    }

    // Show recent searches if there are any and input length is <= 2
    const _handleFocus = () => {
        setCurrentPage(1)
        if (recentSearches.length !== 0 && fundName.length <= 2) {
            setShowRecentSearch(true);
        } else {
            setShowRecentSearch(false);
        }
    };
    // eslint-disable-next-line
    // }, [fundName])

    // handle sort
    const _handleSortBy = (value) => {
        setSortBy(value);
        // if (location.state !== null) {
        //     _clearAll();
        // }
    }

    // show amc
    const _showAmc = () => {
        setShowAmc(!showAmc);
    }

    // show category
    const _showCategory = () => {
        setShowCategory(!showCategory);
    }

    // search amc
    const _searchAmc = (event) => {
        setSearchAmc(event.target.value)
    }

    // handle checkbox
    const _handleCheckBoxGroup = (type, value) => {
        let updated_value = value
        // if (location.state !== null && location.state.filter_id) {
        //     updated_value = updated_value.filter((item) => item !== location.state.filter_id)
        // }
        switch (type) {
            case 1:
                setSelectedAmc(updated_value);
                break;
            case 2:
                setSelectedCategory(updated_value);
                break;

            default:
                break;
        }
    }

    // handle apply button
    const _applyFilter = (filterType, selectedValues) => {
        const is_amc_filter = filterType === 'amc';

        const updated_items = (is_amc_filter ? amcList : categoryList)
            .filter(item => selectedValues.includes(item.id))
            .map(item => ({ type: is_amc_filter ? 1 : 2, value: item.id, label: item.label }));

        const combined_list = [...updated_items];

        if (combined_list.length === 0) {
            combined_list.push(...updated_items);
        }

        return combined_list;
    };

    // combine both filters
    const _applyCombinedFilter = () => {
        if (location.state !== null) {
            setSortBy(sortByOptions[0]);
            setSearchAmc("");
        }
        const amc_filtered_list = _applyFilter('amc', selectedAmc);
        const category_filtered_list = _applyFilter('category', selectedCategory);

        const combined_list = [...amc_filtered_list, ...category_filtered_list];
        setChipList(combined_list);
        setShowFilterModal(false);
    };


    // const clear fund search

    const _clearFundSearch = () => {
        setFundLoader(true);
        setFundChange(true);
        setFundName("");
        // setRecommended(GENERAL_DATA.recommended_fund_status);
    }
    // clear search
    const _clearSearch = () => {
        setSearchAmc("");
    }

    // clear recent searches
    const _clearRecentSearches = () => {
        setRecentSearches([]);
        setShowRecentSearch(false);
        localStorage.removeItem('recent_search');
    }

    // clear all
    const _clearAll = () => {
        setSearchAmc("");
        setSelectedAmc([]);
        setSelectedCategory([]);
        setChipList([]);
        setShowFilterModal(false);
        navigate("/explore");
        // setRecommended(GENERAL_DATA.recommended_fund_status);
    }

    // add search history to local storage
    function _addItemToLocal(newItem) {
        // Retrieve existing items from localStorage
        const existing_items_string = localStorage.getItem('recent_search');
        let existing_items = [];

        if (existing_items_string) {
            // Parse existing items JSON string
            existing_items = JSON.parse(existing_items_string);
        }

        // Check if the new item's mf_id already exists in the array
        const item_index = existing_items.findIndex(item => item.mf_id === newItem.mf_id);

        if (item_index !== -1) {
            // If item already exists, move it to the front of the array
            const item = existing_items.splice(item_index, 1)[0];
            existing_items.unshift(item);
        } else {
            // Add new item to the beginning of the array
            existing_items.unshift(newItem);

            // Keep only the latest 5 items
            if (existing_items.length > 5) {
                existing_items = existing_items.slice(0, 5);
            }
        }

        // Stringify updated array and store in localStorage
        localStorage.setItem('recent_search', JSON.stringify(existing_items));
    }

    // handle row click
    const _handleRowClick = (row) => {
        _handleNavigate(row);
        _addItemToLocal(row);
    }

    // navigate to detail page
    const _handleNavigate = (item) => {
        navigate("/explore", {
            state: {
                query: fundName,
                data: item,
                chipList: chipList,
                pageNumber: currentPage,
                sort: sortBy,
                recommended: recommended
            }
        }, { replace: true })
        setTimeout(() => {
            navigate("detail", {
                state: {
                    query: fundName,
                    data: item,
                    chipList: chipList,
                    pageNumber: currentPage,
                    sort: sortBy,
                    recommended: recommended
                }
            }, { replace: true })
        }, 0);
    }

    // navigate to previous page
    function _navigatePreviousPage() {
        navigate("/");
    }

    // API - fund list
    const _getFundList = () => {
        const url = "/mf-data/list";
        const request = {
            page_num: currentPage,
            category_ids: chipList.filter(item => item.type === 2 && item.value !== null).map(item => item.value),
            amc_ids: chipList.filter(item => item.type === 1 && item.value !== null).map(item => item.value),
            sort: sortBy.value,
            scheme_name: fundName,
            is_recommended: recommended
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setFundList(response.data.data);
                setPageCount(response.data.total_page);
            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
            setFundLoader(false);
        })
    }

    // API - get filter data
    const _getFilterData = () => {
        const url = "/mf-data/filter-data";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setSortByOptions(response.data.sort_by);
                setSortBy(response.data.sort_by[0]);
                setFilteredAmcList(response.data.amcs);
                setAmcList(response.data.amcs);
                setSelectedAmc([]);
                setCategoryList(response.data.category);
                setSelectedCategory([])
                setScreenLoader(false);
            }
        });
    }

    // show filter modal in mobile screen
    const _openFilterModal = () => {
        setShowFilterModal(true);
    }

    return (
        <Fragment>
            {
                screenLoader === true ?
                    <Loader height="h-80vh" />
                    :
                    <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                        <Header type={2} />
                        <div className="row ">
                            <div className="col-lg-11 padding-144px-bottom e-explore-list-height">

                                {/* Header */}

                                {/* Goal list */}
                                <section className="px-4 ">
                                    <Breadcrumb mainPage="Home"
                                        currentPage="Explore"
                                        type={1}
                                        handleNavigate={_navigatePreviousPage} />

                                    <div className="row mt-3">
                                        <div className="col-xl-3 col-lg-4 d-flex justify-content-between align-items-center">
                                            <SectionTitle label="Explore Funds on your own"
                                                className="e-line-height-38" />
                                            <div className="d-sm-none d-flex justify-content-end position-relative border-1px border-silver-sand border-radius-8px p-1">
                                                <img src={require("../../Assets/Images/Explore/filter.svg").default} alt="Filter"
                                                    width={24}
                                                    height={24}
                                                    onClick={_openFilterModal}
                                                    className="cursor-pointer" />
                                                {
                                                    chipList.length !== 0 &&
                                                    <span className="position-absolute top--4px end-0 rounded-pill e-bg-crimpson-red e-font-10 e-line-height-12 color-white e-montserrat-bold w-10px h-10px padding-1px-top d-flex align-items-center justify-content-center">
                                                    </span>
                                                }

                                            </div>
                                        </div>
                                        <div className="col-xl-9 col-lg-8 d-lg-block d-none">
                                            <div className="row justify-content-md-between">
                                                <div className="col-xl-5 col-md-6 pe-md-4"
                                                    ref={searchRef}>
                                                    <SearchComponent placeholder="Search Funds"
                                                        size={20}
                                                        icon={true}
                                                        inputState={fundName}
                                                        onChange={_handleFundChange}
                                                        onFocus={_handleFocus}
                                                        close={fundName.length !== 0 && true}
                                                        clearSearch={_clearFundSearch}
                                                        onBlur={() => setFundChange(false)} />
                                                </div>
                                                <div className="col-xl-5 col-md-6 pt-md-0 pt-3">
                                                    <CustomSelectBox placeholder="Sort By : "
                                                        value={sortBy}
                                                        options={sortByOptions}
                                                        onSelectChange={_handleSortBy} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="row margin-12px-top">
                                        {/* Desktop view for filter */}
                                        <div className="col-xl-3 col-lg-4 d-lg-block d-none">
                                            <FundFilter selectedAmc={selectedAmc}
                                                selectedCategory={selectedCategory}
                                                _clearAll={_clearAll}
                                                showAmc={showAmc}
                                                _showAmc={_showAmc}
                                                searchAmc={searchAmc}
                                                _clearSearch={_clearSearch}
                                                filteredAmcList={filteredAmcList}
                                                _handleCheckBoxGroup={_handleCheckBoxGroup}
                                                _showCategory={_showCategory}
                                                showCategory={showCategory}
                                                _searchAmc={_searchAmc}
                                                categoryList={categoryList}
                                                _applyCombinedFilter={_applyCombinedFilter}
                                                showFilterModal={showFilterModal} />

                                        </div>

                                        {/* tab and mobile view*/}
                                        <div className="col-12 d-lg-none d-block ">
                                            <div className="row gx-2 row-gap-16px justify-content-md-between align-items-center">
                                                <div className="col-sm-5 col-12 ">
                                                    <SearchComponent placeholder="Search with fund name"
                                                        size={20}
                                                        icon={true}
                                                        inputState={fundName}
                                                        onChange={_handleFundChange}
                                                        clearSearch={_clearFundSearch}
                                                        onFocus={() => {
                                                            if (recentSearches.length !== 0) {
                                                                setShowRecentSearch(true);
                                                            }
                                                            setCurrentPage(1);
                                                        }}
                                                        close={fundName.length !== 0 && true}
                                                        onBlur={() => setFundChange(false)} />
                                                </div>
                                                <div className="col-sm-5 col-12 ">
                                                    <CustomSelectBox placeholder="Sort By : "
                                                        value={sortBy}
                                                        options={sortByOptions}
                                                        onSelectChange={_handleSortBy} />
                                                </div>
                                                <div className="col-sm-2 d-sm-block d-none ">
                                                    <div className="d-flex justify-content-end">
                                                        <img src={require("../../Assets/Images/Explore/filter.svg").default} alt="Filter"
                                                            width={24}
                                                            height={24}
                                                            onClick={_openFilterModal}
                                                            className="cursor-pointer" />

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="col-xl-9 col-lg-8 pt-lg-0 pt-3">

                                            {
                                                showRecentSearch === true ?
                                                    <div className="d-flex flex-column gap-24px"
                                                        ref={searchRef}>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="e-montserrat-medium e-font-14 e-line-height-24 color-davys-grey mb-0">Recent Searches</p>
                                                            <span className="e-montserrat-semi-bold e-font-14 e-line-height-24 color-rebecca-purple py-1 px-3 e-bg-magnolia border-radius-32px cursor-pointer"
                                                                onClick={_clearRecentSearches}>Clear all</span>
                                                        </div>
                                                        {
                                                            recentSearches.map((item, key) =>
                                                                <div className="d-flex gap-8px align-items-center cursor-pointer"
                                                                    key={key}
                                                                    onClick={() => _handleNavigate(item)}>
                                                                    <img src={require("../../Assets/Images/Explore/recent-searches.png")}
                                                                        alt="Recent searches"
                                                                        width={24}
                                                                        height={24}
                                                                        draggable={false} />

                                                                    <p className="e-montserrat-regular e-font-16 e-line-height-24 color-eerie-black mb-0">{item.scheme_name}</p>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    :
                                                    <Fragment>

                                                        {
                                                            GENERAL_DATA.recommended_fund_status === true ?

                                                                <div className="d-flex align-items-center gap-8px mt-3">
                                                                    <span className={`e-montserrat-semi-bold e-font-14 e-line-height-24 w-max-content ${recommended === false ? "color-rebecca-purple" : "color-eerie-black"}`}>All</span>
                                                                    <Toggle check={recommended}
                                                                        onChange={(status) => setRecommended(!status)} />
                                                                    <span className={`e-montserrat-semi-bold e-font-14 e-line-height-24 w-max-content ${recommended === true ? "color-rebecca-purple" : "color-eerie-black"}`}>Top Picks</span>
                                                                </div>
                                                                :
                                                                null

                                                        }


                                                        <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                                                            {
                                                                fundLoader === true ?
                                                                    <Loader height="min-h-500px" />
                                                                    :
                                                                    fundList.length === 0 ?
                                                                        <EmptyScreen className="my-5 mx-4"
                                                                            title="No funds found"
                                                                            type={1}
                                                                            description="" />
                                                                        :
                                                                        <Fragment>
                                                                            <div className="mb-4 border-radius-tlr-16px overflow-hidden">
                                                                                <DataTableContainer columns={TABLE_COLUMNS}
                                                                                    data={fundList}
                                                                                    customStyles={TABLE_STYLE}
                                                                                    rowClick={(row) => _handleRowClick(row)} />
                                                                            </div>

                                                                            <ReactPaginate previousLabel={<PrevIcon />}
                                                                                nextLabel={<NextIcon />}
                                                                                breakLabel={<BreakLabel />}
                                                                                pageCount={pageCount}
                                                                                pageRangeDisplayed={2}
                                                                                marginPagesDisplayed={1}
                                                                                onPageChange={(value) => _handlePageChange(value.selected)}
                                                                                renderOnZeroPageCount={null}
                                                                                forcePage={currentPage - 1}
                                                                                containerClassName="e-pagination ps-0"
                                                                                activeClassName="e-pagination-active" />
                                                                        </Fragment>
                                                            }



                                                        </div>

                                                    </Fragment>
                                            }
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
            }

            {/* Mobile filter */}
            {
                showFilterModal === true &&
                <FundFilter selectedAmc={selectedAmc}
                    selectedCategory={selectedCategory}
                    _clearAll={_clearAll}
                    showAmc={showAmc}
                    _showAmc={_showAmc}
                    searchAmc={searchAmc}
                    _clearSearch={_clearSearch}
                    filteredAmcList={filteredAmcList}
                    _handleCheckBoxGroup={_handleCheckBoxGroup}
                    _showCategory={_showCategory}
                    showCategory={showCategory}
                    _searchAmc={_searchAmc}
                    categoryList={categoryList}
                    _applyCombinedFilter={_applyCombinedFilter}
                    showFilterModal={showFilterModal} />
            }
        </Fragment>
    )
}

export default Explore