/*
 *   File : transaction-detail.js
 *   Author URI : https://evoqins.com
 *   Description :Modal to show transaction details of external portfolio
 *   Integrations : null
 *   Version : v1.1
 */
// packages
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

// components
import { Icon } from '../Icon';
import { Loader } from '../Other';

// styles
import Color from '../../Styles/color.module.scss';
import style from '../../Styles/Components/transaction.module.scss';

const NextIcon = () => {
    return (
        <Icon icon='Arrow-Right'
            width="24px"
            height="24px"
            color={Color.rebecca_purple} />
    )
}

const PrevIcon = () => {
    return (
        <Icon icon='Arrow-Right'
            width="24px"
            height="24px"
            color={Color.rebecca_purple}
            className="e-transform-left-arrow" />
    )
}

const BreakLabel = () => {
    return (
        <span>...</span>
    )
}

const TransactionDetail = (props) => {

    // const [pageCount, setPageCount] = useState(1); //total number of pages
    const [currentPage, setCurrentPage] = useState(1); // Current page


    useEffect(() => {
        var my_modal = document.getElementById("transaction-detail");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
            document.body.removeAttribute('style')
        });
        //eslint-disable-next-line
    }, []);

    // Handle page change
    const _handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber + 1);
        props.getTransactions(pageNumber + 1);
    };

    return (
        <div className={`modal fade ${style.e_transaction}`}
            id="transaction-detail"
            tabIndex="-1"
            aria-labelledby="transaction-detail"
            aria-hidden="true"
            data-bs-backdrop="static"
            data-bs-keyboard="false">
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content pb-2 ${style.e_content} `}>
                    <div className="border-bottom-1px border-ash-gray px-sm-4 px-3">
                        <div className="d-flex justify-content-between align-items-center pt-4 pb-3">

                            <h5 className='e-font-16 mb-0 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                Transactions
                            </h5>


                            <Icon icon="close-btn"
                                size={24}
                                color={Color.eerie_black}
                                id="close-fund"
                                className="cursor-pointer"
                                data-bs-dismiss="modal" />
                        </div>

                    </div>
                    <div className={`${style.e_height} px-sm-4 px-3 pt-sm-4 pt-3`}>
                        {
                            props.laoder === true ?
                                <Loader height="h-60vh" />
                                :
                                props.data.map((item) => (
                                    <div className='box-shadow-black border-radius-16px mb-3'>
                                        <div className='border-bottom-1px border-ash-gray px-3 pt-3 pb-2 d-flex justify-content-between align-items-center'>
                                            <h6 className='e-font-14 color-eerie-black e-montserrat-regular e-line-height-24 mb-0'>Date: <span className='e-montserrat-semi-bold'>{item.date}</span></h6>

                                            <span className="e-montserrat-medium e-font-14 e-line-height-20 padding-2px-tb padding-10px-lr border-radius-16px"
                                                style={item.type === "Lumpsum" || item.type === "Switch"  ? { backgroundColor: "#f3eafd", color: "#662D91" } : { backgroundColor: "#F0F9FF", color: "#026aa2" }}>{item.type}</span>
                                        </div>
                                        <div className='px-3 py-3'>
                                            <div className='row'>
                                                <div className='col-4'>
                                                    <p className="color-outer-space e-font-14 e-line-height-16 e-montserrat-regular mb-1">Units</p>
                                                    <h6 className="color-eerie-black e-font-16 e-line-height-20 e-montserrat-semi-bold mb-0">{item.units}</h6>
                                                </div>
                                                <div className='col-4'>
                                                    <p className="color-outer-space e-font-14 e-line-height-16 e-montserrat-regular mb-1">Amount</p>
                                                    <h6 className="color-eerie-black e-font-16 e-line-height-20 e-montserrat-semi-bold mb-0">{item.amount_text}</h6>
                                                </div>
                                                <div className='col-4'>
                                                    <p className="color-outer-space e-font-14 e-line-height-16 e-montserrat-regular mb-1">NAV</p>
                                                    <h6 className="color-eerie-black e-font-16 e-line-height-20 e-montserrat-semi-bold mb-0">{item.nav}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                        }
                    </div>
                    <ReactPaginate previousLabel={<PrevIcon />}
                        nextLabel={<NextIcon />}
                        breakLabel={<BreakLabel />}
                        pageCount={props.pageCount}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={1}
                        onPageChange={(value) => _handlePageChange(value.selected)}
                        renderOnZeroPageCount={null}
                        forcePage={currentPage - 1}
                        containerClassName="e-pagination ps-0 pt-3"
                        activeClassName="e-pagination-active" />

                </div>
            </div>
        </div >
    )
}

export default TransactionDetail