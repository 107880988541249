/*
 *   File : financial-freedom.js
 *   Author : https://evoqins.com
 *   Description : Container file to show the investment portfolio of selected customer
 *   Integrations : NA
 *   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Modal } from "bootstrap";

// import components
import { Header } from "../../Components/Header"
import { Breadcrumb, Loader } from "../../Components/Other"
import { Icon } from "../../Components/Icon";
import { PrimaryButton } from "../../Components/Buttons";
import { PyramidInformation, UpdateDobModal, VideoPreviewModal } from "../../Components/Modal";

// import style
import Color from "../../Styles/color.module.scss";

// import services
import { _getFinancialStatus } from "../../Helper/api";
import APIService from "../../Services/api-service";

const CHART_DATA = [{
    color: Color.pastel_magenta,
    title: "Speculation",
    image: require("../../Assets/Images/financial-freedom/Speculation.png"),
    description: "This layer is for risk-takers who enjoy the thrill. Approach with caution, only invest what you can afford to lose. This layer includes derivatives, art, cryptocurrency, betting and more."
},
{
    color: Color.sandy_brown,
    title: "Growth",
    image: require("../../Assets/Images/financial-freedom/Growth.png"),
    description: "Fuels your financial goals with a mix of higher-growth investments like mutual funds, real estate, and stock market investments."
},
{
    color: Color.medium_aquamarine,
    title: "Savings",
    image: require("../../Assets/Images/financial-freedom/Savings.png"),
    description: "Provides a cushion of stability with low-risk investments for consistent returns."
},
{
    color: Color.maya_blue,
    title: "Protection",
    image: require("../../Assets/Images/financial-freedom/Protection.png"),
    description: "Prioritize financial security with comprehensive insurance, an emergency fund for unexpected events, and focus on protecting your health."
}]

const FinancialFreedom = () => {

    const navigate = useNavigate();

    const PROFILE_DATA = useSelector((state) => state.Reducer.PROFILE_DETAILS)

    const [screenLoader, setScreenLoader] = useState(true);
    const [financialSummary, setFinancialSummary] = useState({});
    const [familyStructure, setFamilyStructure] = useState([]);

    const [showDob, setShowDob] = useState(false);
    const [viewInfo, setViewInfo] = useState(false);

    const [familyData, setFamilyData] = useState({});
    const [showPreview, setShowPreview] = useState(false);


    useEffect(() => {
        _getFinancialSummary();
        _getHomeDetails();
    }, []);

    useEffect(() => {
        if (showDob === true) {
            _getFamilyStructure();
        }
    }, [showDob]);

    useEffect(() => {
        if (familyStructure.length !== 0 && showDob === true) {
            const modal = new Modal(document.getElementById("update-dob"));
            modal.show();
        }
    }, [familyStructure, showDob]);

    useEffect(() => {
        if (showPreview === true) {
            const modal = new Modal(document.getElementById("video-preview"));
            modal.show();
        }
    }, [showPreview]);


    useEffect(() => {
        if (viewInfo === true) {
            const modal = new Modal(document.getElementById("pyramid-information"));
            modal.show();
        }
    }, [viewInfo]);

    function _handleNavigate() {
        navigate("/");
    }

    function _showPreview(status) {
        setShowPreview(status)
    }

    // navigate to financial-summary 
    function _handleFinancialStatus() {
        // eslint-disable-next-line
        if (PROFILE_DATA.dob === null || familyData.get_annual_income == true || familyData.get_monthly_expense == true || familyData.get_family_structure == true) {
            setShowDob(true);
        } else {
            navigate("/financial-summary", {
                state: {
                    summary: financialSummary
                }
            });
        }
    }

    // function _handleViewInfo() {
    //     setViewInfo(true);
    // }

    // API - get financial status
    const _getFinancialSummary = () => {
        _getFinancialStatus().then((response) => {
            setFinancialSummary(response.data);
            setScreenLoader(false);
        });
    }

    // API - get family structure
    const _getFamilyStructure = () => {
        const url = "/home/get-family-structure";
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                const transformed_data = response.data.map((item) => ({
                    id: item.value,
                    name: item.label
                }));

                setFamilyStructure(transformed_data);

            }
        });
    }

    // API - get home data
    const _getHomeDetails = () => {
        const url = "/home/get"

        const request = {
            platform: 'web'
        }
        APIService(true, url, request).then((response) => {
            setShowDob(false);
            setFamilyStructure([]);
            if (response.status_code === 200) {
                setFamilyData(response.data);
            }
        })
    }

    return (
        <Fragment>
            {
                screenLoader === true ?
                    <Loader height="h-80vh" />
                    :
                    <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                        <Header type={2} />
                        <div className="row ">
                            <div className="col-lg-11 padding-144px-bottom">

                                {/* Header */}

                                {/* Goal list */}
                                <section className="px-4 ">
                                    <Breadcrumb mainPage="Home"
                                        currentPage="Financial pyramid"
                                        type={1}
                                        handleNavigate={_handleNavigate} />
                                    <Icon icon="Arrow-Right"
                                        size={32}
                                        className="mb-3 margin-32px-top e-transform-left-arrow cursor-pointer"
                                        onClick={_handleNavigate} />

                                    <div className="row">
                                        <div className="col-xl-8 col-lg-7">
                                            <h5 className="e-font-20 e-line-height-32 e-montserrat-semi-bold e-line-height-32 color-eerie-black mb-2">
                                                ‘Financial Pyramid’ - A Holistic Approach to Investing.
                                            </h5>
                                            <p className="e-montserrat-regular e-font-14 e-line-height-24 color-eerie-black ">
                                                Confused about where to start with investing? This approach simplifies it with ease. Prioritizing protection and stability first, helps an individual to explore growth potential when they're ready. It is only possible when investments are built in a right structured manner. This will help to build a long-term financial well-being, also maintaining a balance between security and growth.
                                            </p>
                                            <img src={require("../../Assets/Images/financial-freedom/financial-freedom.png")}
                                                alt="Financial freedom"
                                                draggable={false}
                                                className="w-100 margin-32px-bottom d-sm-block d-none" />
                                            <img src={require("../../Assets/Images/financial-freedom/financial_pyramid.png")}
                                                alt="Financial freedom"
                                                draggable={false}
                                                className="w-100 margin-32px-bottom d-sm-none d-block" />
                                            {
                                                Object.entries(financialSummary.data).map((entry, index) => ({ ...entry, index })) // Add the original index to each entry
                                                    .sort((a, b) => b.index - a.index) // Sort in descending order based on the original index
                                                    .map(({ 0: key, 1: summary, index }) =>
                                                        <div className="d-flex align-items-start gap-8px mb-2"
                                                            key={key}>

                                                            <div className="min-w-10px min-h-10px rounded-circle mt-2" style={{ backgroundColor: summary.color_two, }}></div>
                                                            <div>
                                                                <span className="e-montserrat-semi-bold e-font-14 e-line-height-24 color-eerie-black mb-0">{key}: </span>
                                                                <span className="e-montserrat-regular e-font-14 e-line-height-24 color-eerie-black">{CHART_DATA[index].description}</span>
                                                            </div>
                                                        </div>
                                                    )
                                            }
                                            <div className="e-bg-lavender-mist border-radius-24px e-strength-shadow position-relative">

                                                <div className="d-flex justify-content-between align-items-center px-sm-4 px-3 pt-sm-4 pt-3  pb-sm-3 pb-2  border-bottom-1px border-platinum">
                                                    <div className=" d-flex align-items-center gap-8px">
                                                        <img src={require("../../Assets/Images/financial-freedom/strength.png")}
                                                            alt="Strength"
                                                            width={36}
                                                            height={36}
                                                            draggable={false} />
                                                        <p className="e-montserrat-regular e-font-14 e-line-height-20 color-eerie-black mb-0">Your Financial Strength: <span className="e-montserrat-medium color-eerie-black">{financialSummary.score}</span></p>
                                                    </div>
                                                    {/* <Icon icon="info-circle"
                                                        size={24}
                                                        className="cursor-pointer z-index-3"
                                                        onClick={_handleViewInfo} /> */}
                                                </div>
                                                <div className="mt-3 px-sm-4 px-3 pb-sm-4 pb-3">
                                                    <p className="e-montserrat-semi-bold e-font-14 e-line-height-24 color-eerie-black">Current value</p>
                                                    {
                                                        Object.entries(financialSummary.data).map(([key, summary], index) =>
                                                            <div className={`py-sm-3 py-2 d-flex align-items-center justify-content-between ${index > 0 && "border-top-1px border-platinum"}`}
                                                                key={key}>
                                                                <div className="d-flex align-items-center gap-8px">
                                                                    <img src={summary.image}
                                                                        alt={summary.title}
                                                                        draggable={false}
                                                                        width={28}
                                                                        height={28} />
                                                                    <p className="e-montserrat-medium e-font-16 e-line-height-24 color-eerie-black mb-0">{key}</p>
                                                                </div>
                                                                <span className="e-montserrat-semi-bold color-eerie-black e-font-16 e-line-height-24" style={{ color: summary.score_color }}>{summary.score}</span>
                                                            </div>
                                                        )
                                                    }

                                                    <div className="text-center">
                                                        <PrimaryButton label={financialSummary.is_pyramid_updated ? "Rebuild Your Financial Pyramid" : "Build Your Financial Pyramid"}
                                                            className="padding-12px-tb padding-40px-lr position-relative z-index-1"
                                                            onPress={_handleFinancialStatus} />
                                                    </div>
                                                    <p className="e-montserrat-medium e-font-14 e-line-height-24 color-eerie-black margin-12px-bottom text-center mt-3 mb-0">To Understand Your Financial Strength Better.</p>

                                                    <a href="https://calendly.com/connect-mintit"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="border-bottom-1px border-color-purple text-decoration-none w-max-content mx-auto pt-1 mb-0 color-rebecca-purple e-font-16 e-line-height-24 e-montserrat-semi-bold d-flex justify-content-center">
                                                        Book A Call With MINTIT Team
                                                    </a>

                                                </div>
                                                <img src={require("../../Assets/Images/financial-freedom/financial-strength-pattern.png")}
                                                    alt="Pattern"
                                                    draggable={false}
                                                    className="position-absolute end-0 top-0 img-fluid" />
                                            </div>
                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-24 color-eerie-black my-4">More to Know</p>
                                            <div className="row">
                                                <div className="col-xl-6 col-lg-12 col-md-6 col-sm-6 ">
                                                    <div className="e-banner">
                                                        <div className="position-relative" onClick={() => _showPreview(true)}>

                                                            <img src={require("../../Assets/Images/financial-freedom/pyramid-banner.png")}
                                                                alt="More to know"
                                                                draggable={false}
                                                                className="img-fluid e-banner-img"
                                                            />
                                                            <img src={require("../../Assets/Images/financial-freedom/play.gif")}
                                                                alt="play"
                                                                width={84}
                                                                className="position-absolute start-0 end-0 top-0 bottom-0 m-auto z-index-2 cursor-pointer"
                                                            // onClick={() => _showPreview(true)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <p className="e-montserrat-semi-bold e-font-16 e-line-height-28 color-eerie-black margin-12px-bottom mt-3">
                                                        Constructing Financial Stability via the pyramid method.
                                                    </p>
                                                    {/* <p className="e-montserrat-regular e-font-14 e-line-height-28 color-jet mb-0">
                                                        Confused about which one to choose? Watch our exclusive video on difference between Active & Passive funds to decide which style suits you the best. Remember, investment experience is an outcome of the choice you make to invest considering suitability for you since no size fits all! Happy Investing!!
                                                    </p> */}
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="col-xl-4 col-lg-5 col-md-6 col-sm-6 pt-lg-0 pt-3">
                                            <div className="e-max-potential p-4 d-flex justify-content-center text-center border-radius-24px flex-column">
                                                <div className="row d-flex justify-content-center">
                                                    <div className="col-7">
                                                        <img src={require("../../Assets/Images/financial-freedom/maximum-growth-potential.png")}
                                                            alt="Maximum growth potential"
                                                            draggable={false}
                                                            className="img-fluid" />
                                                    </div>
                                                </div>
                                                <p className="e-montserrat-semi-bold e-font-18 e-line-height-24 mb-2 mt-4 color-eerie-black">Maximize Growth Potential</p>
                                                <p className="e-montserrat-medium e-font-14 e-line-height-24 color-davy-gray margin-40px-bottom">
                                                    Solid financial foundation and leverage opportunities for significant growth
                                                </p>
                                                <PrimaryButton label="Invest Now"
                                                    className="padding-12px-tb w-100 "
                                                    onPress={() => navigate('/milestone')} />
                                            </div>
                                        </div> */}
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
            }

            {
                showDob === true && familyStructure.length !== 0 &&
                <UpdateDobModal familyStructure={familyStructure}
                    closeModal={() => {
                        _getHomeDetails();
                    }} />
            }

            {
                showPreview === true &&
                <VideoPreviewModal url='https://mintit-prod-static-assets.s3.ap-south-1.amazonaws.com/videos/FINANCIAL_PYRAMID.mp4'
                    type={1}
                    size={450}
                    closeModal={() => {
                        _showPreview(false);
                    }} />
            }
            {
                viewInfo === true &&
                <PyramidInformation
                    closeModal={() => {
                        setViewInfo(false);
                    }} />
            }
        </Fragment>
    )
}

export default FinancialFreedom