/*
 *   File : amount-input.js
 *   Author : https://evoqins.com
 *   Description : Amount input component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo, useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import styles from "../../Styles/Components/text-box.module.scss";

const CustomAmountInput = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value);
    const inputRef = useRef(null);

    useImperativeHandle(ref, () => ({
        focus: () => {
            inputRef.current.focus();
        }
    }));

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const _handleChange = (event) => {
        const input_value = event.target.value;

        // Removing space and comma
        const formatted_value = Number(input_value.replaceAll(",", ''));

        const maxValue = props.maxValue || 999999999;



        if (isNaN(formatted_value) === false && formatted_value > 0) {

            if (props.type === 2 && (formatted_value <= maxValue)) {
                setValue(formatted_value);
                props.handleChange(formatted_value);
            } else {
                if ((formatted_value <= maxValue)) {
                    setValue(formatted_value);
                    props.handleChange(formatted_value);
                }
            };
        } else {
            // eslint-disable-next-line
            if (input_value == 0 && input_value.length <= 1 && props.is_zero === true) {
                setValue(input_value);
                props.handleChange(input_value);
            } else {
                if (input_value.length <= 1) {
                    setValue('');
                    props.handleChange('');
                } else {
                    event.preventDefault();
                }

            }
        }
    };

    const _preventDefaults = (e) => {
        const key_code = e.keyCode || e.which;
        const is_ctrl_pressed = e.ctrlKey || e.metaKey;

        if (key_code === 9 || // Tab key
            (is_ctrl_pressed && (key_code === 65 || key_code === 67 || key_code === 88 || key_code === 86))
        ) {
            return; // Allow Ctrl+A, Ctrl+C, Ctrl+X, or Ctrl+V
        }

        if (
            (key_code >= 48 && key_code <= 57) || // 0-9
            (key_code >= 96 && key_code <= 105) || // Numpad 0-9
            key_code === 8 || // Backspace
            (key_code >= 37 && key_code <= 40) // Arrow keys
        ) {
            return; // Allow the keypress
        } else {
            e.preventDefault();
        }
    };


    return (
        <div className={`${props.className} ${styles.e_amount_input_wrapper} position-relative`} id={props.id}>
            <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text margin-6px-bottom bg-transparent`}
                htmlFor={props.label}>
                {props.label}<span className='color-deep-carmine-pink'>{props.postfix}</span>
            </p>

            <div className="position-relative">
                <p className={`${styles.e_prefix} position-absolute left-14px e-montserrat-medium e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0`}>
                    ₹
                </p>
                <input
                    ref={inputRef}
                    required
                    value={value && value.toLocaleString('en-IN')}
                    id={props.label}
                    type="text"
                    className={`${styles.e_input} color-charleston-green e-montserrat-medium e-font-14 e-line-height-20 w-100 no-outline`}
                    placeholder={props.placeholder}
                    disabled={props.disabled}
                    autoComplete="off"
                    onChange={_handleChange}

                    onKeyDown={_preventDefaults}
                />
            </div>

            {props.error && (
                <div className='position-absolute d-flex align-items-center margin-6px-top w-max-content'>
                    <p className='color-deep-carmine-pink e-montserrat-medium e-font-12 e-line-height-16 mb-0 '>
                        {props.error}
                    </p>
                </div>
            )}
        </div>
    );
});

export default memo(CustomAmountInput);
