import { useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";

import Color from '../Styles/color.module.scss';
import { googleLogout } from "@react-oauth/google";

const useCountdown = (initialSeconds, interval = 1000) => {
    const [seconds, setSeconds] = useState(initialSeconds);
    const countdownRef = useRef(null);

    useEffect(() => {
        if (seconds > 0) {
            countdownRef.current = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, interval);
        } else {
            clearInterval(countdownRef.current);
            // Handle expiration logic here (e.g., show a resend button)
        }

        return () => clearInterval(countdownRef.current);
    }, [seconds, interval]);


    const resetCountdown = () => {
        clearInterval(countdownRef.current);
        setSeconds(initialSeconds);
        countdownRef.current = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds - 1);
        }, interval);
    };

    return { seconds, resetCountdown };
};

function useClickOutside(ref, callback) {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, callback]);
}

const _logout = () => {
    sessionStorage.removeItem('mintit_access_token');
    Cookies.remove("mintit_refresh_token", { path: '/', domain: process.env.REACT_APP_DOMAIN });
    Cookies.remove("mintit_refresh_token");
    window.location.href = "/";
    googleLogout();
}

const _getFileName = (url) => {
    // Split the URL by '/'
    const parts = url.split('/');

    // Get the last part of the URL (including query parameters)
    const lastPart = parts.pop();

    // Split the last part by '?' to remove query parameters and get the file name
    const fileName = lastPart.split('?')[0];
    return fileName
}

// format date
const _formatDate = (date) => {
    if (date === "DD-MM-YYYY") {
        return "DD-MM-YYYY"
    } else {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };

        const start_date = new Date(date);
        const formatted_date = start_date.toLocaleDateString('en-GB', options).replace(/\//g, '-');
        return formatted_date
    }
}

const _reverseDateFormat = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
};

// format option
const _getOptions = (selected_data, type) => {
    const options = [];
    if (type === 1) {
        selected_data.map((item) => {
            return (
                options.push({
                    id: item.id,
                    name: item.display_name
                })
            )
        });
    } else {
        selected_data.map((item) => {
            return (
                options.push({
                    value: item.id,
                    label: item.display_name ? item.display_name : item.name
                })
            )
        });
    }
    return options;
}

const _formatSip = (selected_date) => {
    switch (selected_date) {
        case 1: return "1st";
        case 2: return "2nd";
        case 3: return "3rd";
        case 21: return "21st";
        case 22: return "22nd";
        case 23: return "23rd";
        default: return selected_date + "th";
    };
}

function _findNearestDate(apiDates, selectedDate) {
    // Convert the dates from strings to numbers
    const api_dates = apiDates.map(date => parseInt(date, 10)).sort((a, b) => a - b);
    // Check if the selected date is in the list
    if (api_dates.includes(selectedDate)) {

        return selectedDate;
    }

    // Find the nearest maximum date
    const nearestMaxDate = api_dates.find(date => {
        return date > selectedDate;
    });


    // If there's no nearest maximum date, return the first element in the sorted array
    return nearestMaxDate !== undefined ? nearestMaxDate : api_dates[0];
}

function _formatAmount(amount) {
    if (typeof amount !== 'number' || isNaN(amount)) {
        return;
    }

    const absAmount = Math.abs(amount);
    let formattedAmount = '';
    let suffix = '';

    if (absAmount >= 10000000) { // 1 crore = 10,000,000
        formattedAmount = (amount / 10000000).toFixed(2);
        suffix = 'Cr';
    } else if (absAmount >= 100000) { // 1 lakh = 100,000
        formattedAmount = (amount / 100000).toFixed(2);
        suffix = 'L';
    } else {
        formattedAmount = amount.toLocaleString('en-IN');
    }
    return formattedAmount + suffix;

}

function _getOrderStatus(status) {
    let icon = null;
    let color = ""

    if (status === "Successful") {
        icon = require("../Assets/Images/Orders/success.svg").default;
        color = "color-ufo-green"
    } else if (status === "Pending") {
        icon = require("../Assets/Images/Orders/pending.svg").default;
        color = "color-golden-orange"
    } else if (status === "Partially Success") {
        icon = require("../Assets/Images/Orders/pending.svg").default;
        color = "color-golden-orange"
    } else {
        icon = require("../Assets/Images/Orders/failed.svg").default;
        color = "color-deep-carmine-pink"
    }

    return {
        color: color,
        icon: icon
    }
}

const _getProgressStatus = (value, order_status) => {
    let icon = null;
    let color = "color-eerie-black"

    if (value !== null) {
        icon = require("../Assets/Images/Orders/success.svg").default;
    } else {
        if (order_status === "Failed") {
            icon = require("../Assets/Images/Orders/failed.svg").default;
            color = "color-deep-carmine-pink"
        } else {
            icon = require("../Assets/Images/Orders/pending.svg").default;
        }
    }

    return {
        icon: icon,
        color: color
    }
}


// Function get transaction status
function _getTransactionStatus(status, index) {
    let icon = require("../Assets/Images/Orders/pending.svg").default;;
    let color = "color-eerie-black";
    let bg_color = "#b9d6c0"
    if (status === 'SUCCESS') {
        color = "color-ufo-green";
        bg_color = "#33ca5a";
        icon = require("../Assets/Images/Orders/success.svg").default;
    } else if (status === 'PENDING') {
        color = "color-vivid-orange";
        bg_color = "#e88e24";
        icon = require("../Assets/Images/Orders/pending.svg").default;
    } else {
        color = "color-deep-carmine-pink";
        bg_color = "#d92d20";
        icon = require("../Assets/Images/Orders/failed.svg").default;
    }
    return {
        icon: icon,
        color: color,
        bg_color: bg_color
    }
}

const _getPathFromShortName = (shortName) => {
    const paths = {
        home: "home",
        education: "education",
        vehicle: "car",
        "second-innings": "retirement",
        business: "business",
        "crore-pati": "crore-pati",
        wedding: "wedding",
        vacation: "vacation",
        gift: "gift",
        other: "other",
        "settling-back": "nri",
        wealth: "wealth",
        "tax-savings": "tax-savings",
        "surplus-fund": "surplus-fund",
        "regular-income": "regular-income",
        "tax-efficient": "tax-efficient"
    };

    return paths[shortName] || ""; // Return empty string or a default value if shortName is not found
};

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        // Handler to call on window resize
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
}

// Function - investment kyc modal button logic
const _validatingKycStatus = (status) => {
    if (['LOGGED_IN', 'PAN_ADDED', 'OTP_VERIFIED', 'PERSONAL_DETAIL_ADDED', 'ADDRESS_PROOF', 'ADDRESS_ADDED', 'BANK_ACCOUNT_ADDED', 'KYC_REJECTED'].includes(status)) {
        return 1;
    } else if (status === "KYC_SUBMITTED") {
        return 2;
    } else if (status === "INVESTED" || status === "ACCOUNT_CREATED") { //if the user completed the kyc
        return 3;
    } else if (status === 'KYC_ESIGN_REQUIRED') {
        return 4;
    } else {
        return 3;
    }
};

function _forceNavigate(callback) {
    useEffect(() => {

        const handleBackButton = (event) => {
            event.preventDefault(); // Prevent the default browser back action
            // Navigate to a specific route
            callback(); // Replace '/your-route' with the desired route
        };

        window.onpopstate = handleBackButton;

        return () => {
            window.onpopstate = null; // Cleanup the event listener
        };
        //eslint-disable-next-line
    }, []);
}

const _getTomorrowDate = () => {
    const today = new Date();
    let tomorrow = today.getDate() + 1;

    // Check if tomorrow's date is greater than 28
    if (tomorrow > 28) {
        tomorrow = 1; // Set to 1st of next month
    }

    return tomorrow;
};

const WEEKLY_OPTIONS = [
    {
        "value": 1,
        "label": "Monday"
    },
    {
        "value": 2,
        "label": "Tuesday"
    },
    {
        "value": 3,
        "label": "Wednesday"
    },
    {
        "value": 4,
        "label": "Thursday"
    },
    {
        "value": 5,
        "label": "Friday"
    }
]

const _getTomorrowDay = () => {
    const today = new Date();
    const dayName = today.getDay();
    if (dayName >= 1 && dayName < 5) {
        return WEEKLY_OPTIONS[dayName]
    } else {
        return WEEKLY_OPTIONS[0]
    }
};

function _getMaskedNumber(phone_number) {
    const number = `${phone_number.slice(0, 2)}${phone_number.slice(2, -2).replace(/\d/g, 'X')}${phone_number.slice(-2)}`;
    return number
}


function _maskEmail(email) {
    // Split the email into the local part and the domain part
    const [localPart, domainPart] = email.split('@');

    // Mask the local part: keep the first 2 characters and the last 2 characters, replace the middle with 'X'
    const maskedLocal = localPart.slice(0, 2) + '*'.repeat(localPart.length - 4) + localPart.slice(-2);

    // Split the domain part into the domain name and extension
    const [domainName, domainExtension] = domainPart.split('.');

    // Mask the domain name: keep the first 2 characters and last 2 characters, replace the middle with '*'
    const maskedDomainName = domainName.slice(0, 2) + '*'.repeat(domainName.length - 3)

    // Combine the masked parts and add the domain extension
    return `${maskedLocal}@${maskedDomainName}**${domainExtension.slice(-2)}`;
}



const SEGMENT_COLORS = [Color.kelly_green, Color.citrus_green, Color.amber, Color.bright_tangerine, Color.cherry_red, Color.burgundy];
const RISK_SEGMENT_COLORS = ['#4DB24C', '#FFCC17', '#FFA619', '#FF625F'];

export {
    useCountdown,
    useClickOutside,
    _logout,
    _getFileName,
    _formatDate,
    _getOptions,
    SEGMENT_COLORS,
    RISK_SEGMENT_COLORS,
    _formatSip,
    _findNearestDate,
    _formatAmount,
    _getOrderStatus,
    _getProgressStatus,
    _getPathFromShortName,
    useWindowSize,
    _validatingKycStatus,
    _forceNavigate,
    _getTomorrowDate,
    _getTransactionStatus,
    _getMaskedNumber,
    _maskEmail,
    _reverseDateFormat,
    WEEKLY_OPTIONS,
    _getTomorrowDay
}