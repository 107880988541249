/*
*   File : report.js
*   Author : https://evoqins.com
*   Description : Container file to show the user's report summary .
*   Version : 1.0.0
*/

// import packages
import { useNavigate } from "react-router-dom/dist";
import { toast } from "react-toastify";
import { format, parse } from "date-fns";

// import components
import { Fragment, useState } from "react";
import { Header } from "../../Components/Header";
import { Breadcrumb } from "../../Components/Other";
import { Icon } from "../../Components/Icon";
import { CustomDatePicker } from "../../Components/FormElements";
import { PrimaryButton } from "../../Components/Buttons";

// service,helper
import APIService from "../../Services/api-service";
import { _formatDate } from "../../Helper/helper";
import { CustomTabBar } from "../../Components/Tab";
import { useSelector } from "react-redux";


const REPORT_TYPES_INTERNAL = [
    {
        label: "MINTIT",
        id: 1
    }
]

const REPORT_TYPES_EXTERNAL = [
    {
        label: "MINTIT",
        id: 1
    },
    {
        label: "External",
        id: 2
    }
]
const ReportSummary = () => {
    const navigate = useNavigate();
    const PROFILE_DATA = useSelector(state => state.Reducer.PROFILE_DETAILS);

    const today = new Date();
    const disabledDays = { after: today };

    const [reportType, setReportType] = useState(null);
    const [externalReportType, setExternalReportType] = useState(null);

    const [startDateError, setStartDateError] = useState("");
    const [startDate, setStartDate] = useState("DD-MM-YYYY");
    const [endDateError, setEndDateError] = useState("");
    const [endDate, setEndDate] = useState("DD-MM-YYYY");

    const [externalStartDateError, setExternalStartDateError] = useState("");
    const [externalStartDate, setExternalStartDate] = useState("DD-MM-YYYY");
    const [externalEndDateError, setExternalEndDateError] = useState("");
    const [externalEndDate, setExternalEndDate] = useState("DD-MM-YYYY");

    const [apiLoader, setApiLoader] = useState(false);
    const [externalApiLoader, setExternalApiLoader] = useState(false);

    const [tabIndex, setTabIndex] = useState(1);

    function _handleNavigate() {
        navigate("/");
    }

    function _handleReportType(type) {
        setReportType(type);
        setStartDate("DD-MM-YYYY");
        setStartDateError("");
        setEndDate("DD-MM-YYYY");
        setEndDateError("");
        setApiLoader(false);
    }

    function _handleExternalReportType(type) {
        setExternalReportType(type);
        setExternalStartDate("DD-MM-YYYY");
        setExternalStartDateError("");
        setExternalEndDate("DD-MM-YYYY");
        setExternalEndDateError("");
        setExternalApiLoader(false);
    }

    function _handleTabIndex(tab_index) {
        setTabIndex(tab_index);
    }

    function _handleStartDate(selected_date) {
        const formatted_start_date = _formatDate(selected_date);
        setStartDate(formatted_start_date);
        setStartDateError("");

        // Parse the formatted start date into a comparable Date object
        const formattedStartDateObj = new Date(
            formatted_start_date.split('-').reverse().join('-')
        );

        // Parse endDate only if it is not the default "DD-MM-YYYY"
        if (endDate !== "DD-MM-YYYY") {
            const endDateObj = new Date(endDate.split('-').reverse().join('-'));

            // Reset the end date if it's before the new start date
            if (formattedStartDateObj > endDateObj) {
                setEndDate("DD-MM-YYYY");
            }
        }
    }

    function _handleExternalStartDate(selected_date) {
        const formatted_start_date = _formatDate(selected_date);
        setExternalStartDate(formatted_start_date);
        setExternalStartDateError("");

        // Parse the formatted start date into a comparable Date object
        const formattedStartDateObj = new Date(
            formatted_start_date.split('-').reverse().join('-')
        );

        // Parse endDate only if it is not the default "DD-MM-YYYY"
        if (endDate !== "DD-MM-YYYY") {
            const endDateObj = new Date(endDate.split('-').reverse().join('-'));

            // Reset the end date if it's before the new start date
            if (formattedStartDateObj > endDateObj) {
                setExternalEndDate("DD-MM-YYYY");
            }
        }
    }


    function _handleEndDate(selected_date) {
        const formatted_start_date = _formatDate(selected_date)
        setEndDate(formatted_start_date);
        setEndDateError("");
    }

    function _handleExternalEndDate(selected_date) {
        const formatted_start_date = _formatDate(selected_date)
        setExternalEndDate(formatted_start_date);
        setExternalEndDateError("");
    }

    function _handleValidateDates() {
        let valid = true;

        if (startDate === "DD-MM-YYYY") {
            valid = false;
            setStartDateError("Start date cannot be empty")
        }
        if (endDate === "DD-MM-YYYY") {
            valid = false;
            setEndDateError("End date cannot be empty")
        }
        if (valid === true) {
            _downloadReports(true)
        }
    }

    function _handleExternalValidateDates() {
        let valid = true;

        if (externalStartDate === "DD-MM-YYYY") {
            valid = false;
            setExternalStartDateError("Start date cannot be empty")
        }
        if (externalEndDate === "DD-MM-YYYY") {
            valid = false;
            setExternalEndDateError("End date cannot be empty")
        }
        if (valid === true) {
            _downloadExternalReports(true)
        }
    }

    // API - Download all Reports
    const _downloadReports = (is_post) => {
        setApiLoader(true);
        let url = "";
        let request;
        let formatted_start_date = "";
        let formatted_end_date = "";
        if (reportType !== 3) {
            // Parse the date string in dd-mm-yyyy format
            const start_date = parse(startDate, 'dd-MM-yyyy', new Date());
            // Format the date to yyyy-mm-dd
            formatted_start_date = format(start_date, 'yyyy-MM-dd');

            // Parse the date string in dd-mm-yyyy format
            const end_date = parse(endDate, 'dd-MM-yyyy', new Date());
            // Format the date to yyyy-mm-dd
            formatted_end_date = format(end_date, 'yyyy-MM-dd');
        }

        if (reportType === 1) {

            url = "/profile/transaction-report"
            request = {
                from_date: formatted_start_date,
                till_date: formatted_end_date
            }
        } else if (reportType === 2) {
            url = "/profile/capitalgain-report";
            request = {
                from_date: formatted_start_date,
                till_date: formatted_end_date
            }
        } else {
            url = "/profile/holdings-report"
        }
        APIService(is_post, url, request).then((response) => {
            if (response.status_code === 200) {
                window.open(response.data.url, "_blank");
            } else {
                toast.dismiss()
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        });
    }

    // API - Download all Reports
    const _downloadExternalReports = (is_post) => {
        setExternalApiLoader(true);
        let url = "";
        let request;
        let formatted_start_date = "";
        let formatted_end_date = "";

        // Parse the date string in dd-mm-yyyy format
        const start_date = parse(externalStartDate, 'dd-MM-yyyy', new Date());
        // Format the date to yyyy-mm-dd
        formatted_start_date = format(start_date, 'yyyy-MM-dd');

        // Parse the date string in dd-mm-yyyy format
        const end_date = parse(externalEndDate, 'dd-MM-yyyy', new Date());
        // Format the date to yyyy-mm-dd
        formatted_end_date = format(end_date, 'yyyy-MM-dd');


        if (externalReportType === 1) {
            url = "/profile/external-transaction-report"
            request = {
                from_date: formatted_start_date,
                till_date: formatted_end_date
            }
        } else if (externalReportType === 2) {
            url = "/profile/external-capitalgain-report";
            request = {
                from_date: formatted_start_date,
                till_date: formatted_end_date
            }
        }
        APIService(is_post, url, request).then((response) => {
            if (response.status_code === 200) {
                window.open(response.data.url, "_blank");
            } else {
                toast.dismiss()
                toast.error(response.message, {
                    type: "error"
                });
            }
            setExternalApiLoader(false);
        });
    }

    function _parseDate(dateString) {
        const [day, month, year] = dateString.split("-");
        return new Date(`${year}-${month}-${day}`);
    }

    return (
        <Fragment>
            <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                {/* Header */}
                <Header type={2} />
                <div className="row ">
                    <div className="col-lg-11 pe-lg-0 padding-144px-bottom">
                        <div className="px-4">
                            <Breadcrumb mainPage="Home"
                                currentPage="Reports"
                                handleNavigate={_handleNavigate}
                                type={1} />
                            <h5 className="margin-30px-top mb-sm-4 mb-3 e-font-18 e-line-height-38 e-montserrat-semi-bold color-dark-jungle-green">
                                Reports
                            </h5>
                            <div className="border-bottom-1px border-chinese-white mt-2 mb-4">
                                <CustomTabBar data={PROFILE_DATA.external_portfolio_imported === true ? REPORT_TYPES_EXTERNAL : REPORT_TYPES_INTERNAL}
                                    selectedTab={tabIndex}
                                    onSelectTab={_handleTabIndex} />
                            </div>
                            <div className="row">
                                <div className="col-xl-8 col-lg-8 col-md-10">
                                    {
                                        tabIndex === 1 ?
                                            <div className="box-shadow-black border-radius-24px">
                                                <div className={`border-bottom-1px border-gainsboro px-4 py-4 border-radius-tr-24px border-radius-tl-24px  ${reportType === 1 ? "e-bg-pale-lavender" : ""} `}>
                                                    <div className="d-flex justify-content-between cursor-pointer" onClick={() => {
                                                        if (reportType === 1) {
                                                            _handleReportType(null)
                                                        } else {
                                                            _handleReportType(1)
                                                        }
                                                    }}>
                                                        <h5 className="e-font-14 e-line-height-20 color-eerie-black e-montserrat-medium mb-0">Transaction Statement</h5>
                                                        <Icon icon="header-drop-down"
                                                            width="16px"
                                                            height="16px"
                                                            color="#65308C"
                                                            className={reportType === 1 ? "rotate-up" : ""} />
                                                    </div>
                                                    {
                                                        reportType === 1 &&
                                                        <div className="row gx-2 mb-2 align-items-end mt-md-4 mt-sm-3 mt-2 row-gap-30px">
                                                            <div className="col-md-4 col-sm-6 ">
                                                                <CustomDatePicker
                                                                    className="e-date"
                                                                    error={startDateError}
                                                                    label="Start date"
                                                                    postfix={true}
                                                                    start={startDate}
                                                                    disabledDays={disabledDays}
                                                                    mode="single"
                                                                    popupClass="e-report-popup"
                                                                    selectDate={_handleStartDate}
                                                                />
                                                            </div>
                                                            <div className="col-md-4 col-sm-6 ">
                                                                <CustomDatePicker
                                                                    className="e-date"
                                                                    error={endDateError}
                                                                    label="End date"
                                                                    postfix={true}
                                                                    start={endDate}
                                                                    disabledDays={{
                                                                        ...disabledDays,
                                                                        before: startDate !== "DD-MM-YYYY" ? _parseDate(startDate) : undefined,
                                                                    }}
                                                                    mode="single"
                                                                    popupClass="e-report-popup"
                                                                    selectDate={_handleEndDate}
                                                                />
                                                            </div>
                                                            <div className="col-md-4 col-6 pt-md-0 pt-3">
                                                                <PrimaryButton label="Download"
                                                                    className="w-100"
                                                                    disabled={apiLoader}
                                                                    onPress={_handleValidateDates} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className={`border-bottom-1px border-gainsboro px-4 py-4 ${reportType === 2 ? "e-bg-pale-lavender" : ""} `}>
                                                    <div className="d-flex justify-content-between  cursor-pointer" onClick={() => {
                                                        if (reportType === 2) {
                                                            _handleReportType(null)
                                                        } else {
                                                            _handleReportType(2)
                                                        }
                                                    }}>
                                                        <h5 className="e-font-14 e-line-height-20 color-eerie-black e-montserrat-medium mb-0">Capital Gain Report</h5>
                                                        <Icon icon="header-drop-down"
                                                            width="16px"
                                                            height="16px"
                                                            color="#65308C"
                                                            className={reportType === 2 ? "rotate-up" : ""} />
                                                    </div>
                                                    {
                                                        reportType === 2 &&
                                                        <div className="row gx-2 pt-4 mb-2 align-items-end row-gap-30px">
                                                            <div className="col-md-4 col-sm-6 ">
                                                                <CustomDatePicker error={startDateError}
                                                                    className="e-date"
                                                                    label="Start date"
                                                                    postfix={true}
                                                                    start={startDate}
                                                                    disabledDays={disabledDays}
                                                                    mode="single"
                                                                    popupClass="e-report-popup"
                                                                    selectDate={_handleStartDate} />
                                                            </div>
                                                            <div className="col-md-4 col-sm-6 ">
                                                                <CustomDatePicker error={endDateError}
                                                                    className="e-date"
                                                                    label="End date"
                                                                    postfix={true}
                                                                    start={endDate}
                                                                    disabledDays={{
                                                                        ...disabledDays,
                                                                        before: startDate !== "DD-MM-YYYY" ? _parseDate(startDate) : undefined,
                                                                    }}
                                                                    mode="single"
                                                                    popupClass="e-report-popup"
                                                                    selectDate={_handleEndDate} />
                                                            </div>
                                                            <div className="col-sm-4 col-6 pt-md-0 pt-4">
                                                                <PrimaryButton label="Download"
                                                                    className="w-100"
                                                                    disabled={apiLoader}
                                                                    onPress={_handleValidateDates} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className={`border-radius-br-24px border-radius-bl-24px px-4 py-4 ${reportType === 3 ? "e-bg-pale-lavender" : ""} `}>
                                                    <div className="d-flex justify-content-between cursor-pointer" onClick={() => {
                                                        if (reportType === 3) {
                                                            _handleReportType(null)
                                                        } else {
                                                            _handleReportType(3)
                                                        }
                                                    }}>
                                                        <h5 className="e-font-14 e-line-height-20 color-eerie-black e-montserrat-medium mb-0">Portfolio Report</h5>
                                                        <Icon icon="header-drop-down"
                                                            width="16px"
                                                            height="16px"
                                                            color="#65308C"
                                                            className={reportType === 3 ? "rotate-up" : ""} />
                                                    </div>
                                                    {
                                                        reportType === 3 &&
                                                        <div className="row  pt-4 align-items-end">

                                                            <div className="col-md-4 col-sm-6 col-6">
                                                                <PrimaryButton label="Download"
                                                                    className="w-100"
                                                                    disabled={apiLoader}
                                                                    onPress={() => _downloadReports(false)} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <div className="box-shadow-black border-radius-24px">
                                                <div className={`px-4 py-4 border-bottom-1px border-gainsboro border-radius-tr-24px  ${externalReportType === 1 ? "e-bg-pale-lavender" : ""} `}>
                                                    <div className="d-flex justify-content-between cursor-pointer" onClick={() => {
                                                        if (externalReportType === 1) {
                                                            _handleExternalReportType(null)
                                                        } else {
                                                            _handleExternalReportType(1)
                                                        }
                                                    }}>
                                                        <h5 className="e-font-14 e-line-height-20 color-eerie-black e-montserrat-medium mb-0">Transaction Statement</h5>
                                                        <Icon icon="header-drop-down"
                                                            width="16px"
                                                            height="16px"
                                                            color="#65308C"
                                                            className={externalReportType === 1 ? "rotate-up" : ""} />
                                                    </div>
                                                    {
                                                        externalReportType === 1 &&
                                                        <div className="row gx-2 mb-2 align-items-end mt-md-4 mt-sm-3 mt-2 row-gap-30px">
                                                            <div className="col-md-4 col-sm-6 ">
                                                                <CustomDatePicker
                                                                    className="e-date"
                                                                    error={externalStartDateError}
                                                                    label="Start date"
                                                                    postfix={true}
                                                                    start={externalStartDate}
                                                                    disabledDays={disabledDays}
                                                                    mode="single"
                                                                    popupClass="e-report-popup"
                                                                    selectDate={_handleExternalStartDate}
                                                                />
                                                            </div>
                                                            <div className="col-md-4 col-sm-6">
                                                                <CustomDatePicker
                                                                    className="e-date"
                                                                    error={externalEndDateError}
                                                                    label="End date"
                                                                    popupClass="e-report-popup"
                                                                    postfix={true}
                                                                    start={externalEndDate}
                                                                    disabledDays={{
                                                                        ...disabledDays,
                                                                        before: externalStartDate !== "DD-MM-YYYY" ? _parseDate(externalStartDate) : undefined,
                                                                    }}
                                                                    mode="single"
                                                                    selectDate={_handleExternalEndDate}
                                                                />
                                                            </div>
                                                            <div className="col-md-4 col-6 pt-md-0 pt-3">
                                                                <PrimaryButton label="Download"
                                                                    className="w-100"
                                                                    disabled={externalApiLoader}
                                                                    onPress={_handleExternalValidateDates} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className={`border-radius-br-24px border-radius-bl-24px px-4 py-4 ${externalReportType === 2 ? "e-bg-pale-lavender" : ""} `}>
                                                    <div className="d-flex justify-content-between  cursor-pointer" onClick={() => {
                                                        if (externalReportType === 2) {
                                                            _handleExternalReportType(null)
                                                        } else {
                                                            _handleExternalReportType(2)
                                                        }
                                                    }}>
                                                        <h5 className="e-font-14 e-line-height-20 color-eerie-black e-montserrat-medium mb-0">Capital Gain Report</h5>
                                                        <Icon icon="header-drop-down"
                                                            width="16px"
                                                            height="16px"
                                                            color="#65308C"
                                                            className={externalReportType === 2 ? "rotate-up" : ""} />
                                                    </div>
                                                    {
                                                        externalReportType === 2 &&
                                                        <div className="row gx-2 pt-4 mb-2 align-items-end row-gap-30px">
                                                            <div className="col-md-4 col-sm-6">
                                                                <CustomDatePicker
                                                                    className="e-date"
                                                                    error={externalStartDateError}
                                                                    label="Start date"
                                                                    postfix={true}
                                                                    start={externalStartDate}
                                                                    disabledDays={disabledDays}
                                                                    mode="single"
                                                                    popupClass="e-report-popup"
                                                                    selectDate={_handleExternalStartDate}
                                                                />
                                                            </div>
                                                            <div className="col-md-4 col-sm-6">
                                                                <CustomDatePicker
                                                                    className="e-date"
                                                                    error={externalEndDateError}
                                                                    label="End date"
                                                                    postfix={true}
                                                                    start={externalEndDate}
                                                                    popupClass="e-report-popup"
                                                                    disabledDays={{
                                                                        ...disabledDays,
                                                                        before: externalStartDate !== "DD-MM-YYYY" ? _parseDate(externalStartDate) : undefined,
                                                                    }}
                                                                    mode="single"
                                                                    selectDate={_handleExternalEndDate}
                                                                />
                                                            </div>
                                                            <div className="col-sm-4 col-6 pt-md-0 pt-4">
                                                                <PrimaryButton label="Download"
                                                                    className="w-100"
                                                                    disabled={externalApiLoader}
                                                                    onPress={_handleExternalValidateDates} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ReportSummary