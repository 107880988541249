/*
 *   File : external-funds.js
 *   Author URI : https://evoqins.com
 *   Description : Import external funds modal 
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

// Custom components
import { CustomPINInput, CustomTextInput } from "../FormElements";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { Icon } from "../Icon";

// Custom styles
import style from "../../Styles/Components/funds.module.scss";
import Color from '../../Styles/color.module.scss';


// Custom hooks
import { useCountdown } from "../../Helper/helper";
import APIService from "../../Services/api-service";

const ImportExternalFundsModal = (props) => {
    const { seconds, resetCountdown } = useCountdown(30);
    const PROFILE_DETAILS = useSelector(state => state.Reducer.PROFILE_DETAILS);
    const PROGRESS_DATA = useSelector(state => state.Reducer.SIGNUP_PROGRESS);
    // const MASKED_NUMBER = PROFILE_DETAILS.phone.masked_number;

    const [verifyStep, setVerifyStep] = useState(1);

    const [pan, setPan] = useState(PROFILE_DETAILS.pan !== null ? PROFILE_DETAILS.pan : "");
    const [panError, setPanError] = useState("");


    // const [phone, setPhone] = useState(PROFILE_DETAILS.phone.masked_number);
    // const [phoneError, setPhoneError] = useState("");

    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");


    const [otpMf, setOtpMf] = useState("");
    const [otpMfError, setOtpMfError] = useState("");

    const [initiateLoader, setInitiateLoader] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("import-funds");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (props.showFunds === true) {
            setVerifyStep(2);
        }
    }, [props.showFunds])

    useEffect(() => {
        if (verifyStep === 5) {
            setTimeout(() => {
                setVerifyStep(6);
                resetCountdown();
            }, 6000)
        }
        //eslint-disable-next-line
    }, [verifyStep]);

    useEffect(() => {
        if (verifyStep === 2 || verifyStep === 4) {
            resetCountdown();
        }
        // eslint-disable-next-line
    }, [verifyStep]);

    function _handlePAN(input_value) {
        if (input_value.length <= 10) {
            setPan(input_value);
        }
        setPanError("");
    }


    // function _handlePhoneNumber(input_value) {
    //     setPhone(input_value);
    //     setPhoneError("");
    // }


    // handle OTP
    function _handleOTP(input_value) {
        if (input_value.length <= 6) {
            setOtp(input_value);
        }
        setOtpError("");
    }

    function _handleOTPMf(input_value) {
        if (input_value.length <= 6) {
            setOtpMf(input_value);
        }
        setOtpMfError("");
    }


    // resend OTP
    function _handleResend() {
        resetCountdown();
        setOtp("");
        setOtpError("");
        setVerifyStep(2);
        _initiateImport("RESEND");
        // props.confirm(email, "RESEND");
    }

    function _handleResendMf() {
        resetCountdown();
        setOtpMf("");
        setOtpMfError("");
        _verifyOTP("RESEND");
        // props.confirm(email, "RESEND");
    }


    function _handleSubmit() {
        let valid = true;
        let validate_pan = /^[A-Z]{3}P[A-Z]{1}\d{4}[A-Z]{1}$/; // validation of PAN
        const pan_value = pan.toLocaleUpperCase();
        if (pan === "") {
            setPanError("PAN is required");
            valid = false;
        }
        if (pan !== "" && !validate_pan.test(pan_value)) {
            setPanError("Invalid PAN number");
            valid = false;
        }
        // if (phone === "") {
        //     setPhoneError("Phone number is required");
        //     valid = false;
        // }
        // if (phone !== "" && phone.length !== 10) {
        //     setPhoneError("Invalid phone number");
        //     valid = false;
        // }
        if (valid === true) {
            _initiateImport(null);
        }
    }

    // handle submit otp validation
    function _submitOTP() {
        let valid = true;
        if (otp === "") {
            setOtpError("OTP is required");
            valid = false;
        }
        if (otp !== "" && otp.length !== 6) {
            setOtpError("Invalid OTP");
            valid = false;
        }
        if (valid === true) {
            setVerifyStep(3);
            if (props.showFunds === true) {
                _verifyOTP(null);
            } else {
                _verifyOTP(null);
            }
        }
    }

    // handle submit otp validation
    function _submitOTPMf() {
        let valid = true;
        if (otpMf === "") {
            setOtpMfError("OTP is required");
            valid = false;
        }
        if (otpMf !== "" && otpMf.length !== 6) {
            setOtpMfError("Invalid OTP");
            valid = false;
        }
        if (valid === true) {
            if (props.showFunds === true) {
                _verifyOTPMf(null);
            } else {
                _verifyOTPMf(null);
            }
        }
    }


    // API - initiate import 
    const _initiateImport = (type = null) => {
        if (type === null) {
            setInitiateLoader(true);
        }
        const url = "/mf-services/initiate";

        const request = {
            pan: pan.toLocaleUpperCase(),
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                if (type !== null) {
                    toast.success("OTP Resent successfully", {
                        type: "success"
                    })
                } else {
                    setVerifyStep(2);
                }
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setInitiateLoader(false);
        });
    }


    // API - update import 
    const _verifyOTP = (type = null) => {
        if (type === null) {
            setInitiateLoader(true);
        }
        const url = "/mf-services/update";

        const request = {
            otp: otp,
            type: type
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                if (type !== null) {
                    toast.success("OTP Resent successfully", {
                        type: "success"
                    })
                } else {
                    setVerifyStep(4);
                }
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                });
                setVerifyStep(2);
            }
            setInitiateLoader(false);

        });
    }

    // API - update import 
    const _verifyOTPMf = () => {
        setInitiateLoader(true);
        const url = "/mf-services/update-snapshot";

        const request = {
            otp: otpMf,
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setVerifyStep(5);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setInitiateLoader(false);
        });
    }
    return (
        <div className={`modal fade ${style.e_funds}`}
            id="import-funds"
            tabIndex="-1"
            aria-labelledby="import-funds"
            aria-hidden="true"
            data-bs-backdrop="static"
            data-bs-keyboard="false">

            {
                verifyStep === 1 ?
                    <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                        <div className={`modal-content pb-4 ${style.e_content} h-490px d-flex flex-column`}>
                            <div className="border-bottom-1px border-ash-gray px-4">
                                <div className="d-flex justify-content-between align-items-center pt-4 pb-3">
                                    <h6 className='e-font-16 mb-0 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                        Import External Portfolio
                                    </h6>
                                    <Icon icon="close-btn"
                                        size={24}
                                        color={Color.eerie_black}
                                        id="close-modal"
                                        className="cursor-pointer"
                                        data-bs-dismiss="modal" />
                                </div>
                            </div>
                            <div className="px-4 pt-3">
                                <div className="d-flex e-bg-magnolia align-items-center gap-16px p-3 border-radius-16px">
                                    <img src={require("../../Assets/Images/Portfolio/pan.png")}
                                        alt="pan"
                                        width={72}
                                        draggable={false} />

                                    <div className="d-block">
                                        <h6 className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-medium mb-1">Enter your PAN</h6>
                                        <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-regular mb-0">
                                            Required for your account verification
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <CustomTextInput label="Enter Your PAN"
                                        postfix="*"
                                        placeholder="Eg:AGH7898G"
                                        value={pan}
                                        error={panError}
                                        isDisabled={PROGRESS_DATA.kyc_data.is_kyc_compliant === true ? true : false}
                                        inputClass="text-transform-upper"
                                        handleChange={_handlePAN}
                                        onSubmit={_handleSubmit} />

                                    {/* <CustomPhoneInput label="Phone number"
                                        postfix="*"
                                        placeholder="Enter phone number"
                                        value={phone}
                                        maxLength={10}
                                        className='mt-4'
                                        error={phoneError}
                                        readOnly={true}
                                        onSubmit={_handleSubmit}
                                        handleChange={_handlePhoneNumber} /> */}
                                </div>
                            </div>
                            <div className="row mt-auto px-4">
                                <div className="col-6">
                                    <SecondaryButton label="Cancel"
                                        cancel="modal"
                                        disabled={initiateLoader}
                                        className="padding-12px-tb w-100 e-bg-magnolia" />
                                </div>
                                <div className="col-6">
                                    <PrimaryButton label="Verify"
                                        className="padding-12px-tb  w-100"
                                        disabled={initiateLoader}
                                        onPress={_handleSubmit} />
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    verifyStep === 2 ?
                        <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                            <div className={`modal-content pb-4 ${style.e_content} h-490px `}>
                                <div className="border-bottom-1px border-ash-gray px-4">
                                    <div className="d-flex justify-content-between align-items-center pt-4 pb-3">
                                        <h6 className='e-font-16 mb-0 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                            Enter OTP received from MF Central
                                        </h6>
                                        <Icon icon="close-btn"
                                            size={24}
                                            color={Color.eerie_black}
                                            id="close-modal"
                                            className="cursor-pointer"
                                            data-bs-dismiss="modal" />
                                    </div>
                                </div>
                                <div className="mx-4 d-flex align-items-start padding-12px-all mt-3 e-bg-light-blue border-radius-12px mt-3 margin-12px-bottom color-ocean-blue e-font-14 e-line-height-20 gap-4px e-montserrat-medium">
                                    <span>
                                        <Icon icon="info-circle"
                                            size={16} />
                                    </span>
                                    This OTP is needed to retrieve your  detailed holding statement and generate transaction or capital gain statements.
                                </div>
                                <div className="px-4 padding-14px-top">
                                    <p className="e-font-14 mb-2 color-eerie-black e-montserrat-regular e-line-height-24">
                                        By entering the OTP, you consent to MINTIT retrieving and storing your mutual fund details linked to your PAN {PROFILE_DETAILS.masked_pan} for easy viewing, tracking or analysis.
                                    </p>
                                    {/* <h6 className="e-font-14 mb-3 color-eerie-black e-montserrat-semi-bold e-line-height-24">{MASKED_NUMBER}</h6> */}
                                    <CustomPINInput id="otp-input"
                                        autoFocus={true}
                                        pinString={otp}
                                        error={otpError}
                                        type={2}
                                        postfix="*"
                                        title="Enter OTP"
                                        onSubmit={_submitOTP}
                                        handleChange={_handleOTP} />
                                    <p className="color-charcoal-blue e-montserrat-medium e-font-14 e-line-height-20 mt-4">Didn’t get OTP? <span className={`no-decoration color-rebecca-purple e-link ${seconds === 0 ? 'cursor-pointer' : 'disabled'}`} onClick={_handleResend}>{seconds === 0 ? 'Resend OTP' : ` Resend OTP in ${seconds < 10 ? `0${seconds}` : seconds}s`}</span></p>
                                </div>
                                <div className="row mt-auto px-4">
                                    <div className="col-6">
                                        <SecondaryButton label="Cancel"
                                            cancel="modal"
                                            className="padding-12px-tb w-100 e-bg-magnolia" />
                                    </div>
                                    <div className="col-6">
                                        <PrimaryButton label="Verify"
                                            className="padding-12px-tb  w-100"
                                            disabled={initiateLoader}
                                            onPress={_submitOTP} />
                                    </div>
                                </div>

                            </div>
                        </div>
                        :
                        verifyStep === 3 ?
                            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_loader}`}>
                                <div className={`modal-content text-center p-2 ${style.e_content} h-490px`}>
                                    <span id="close"
                                        className="d-none"
                                        data-bs-dismiss="modal" ></span>
                                    <div className="my-auto">
                                        <img src={require("../../Assets/Images/Loader/page-loader.gif")}
                                            alt="loader"
                                            width={80}
                                            height={80}
                                            className="mx-auto" />
                                        <h6 className='e-font-16 mb-0 color-eerie-black e-montserrat-medium e-line-height-30 mt-3'>
                                            Validating OTP Please wait
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            :
                            verifyStep === 4 ?
                                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                                    <div className={`modal-content pb-4 ${style.e_content} h-490px d-flex flex-column`}>
                                        <div className="border-bottom-1px border-ash-gray px-4">
                                            <div className="d-flex justify-content-between align-items-center pt-4 pb-3">
                                                <h6 className='e-font-16 mb-0 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                                    Enter Transactional OTP
                                                </h6>
                                                <Icon icon="close-btn"
                                                    size={24}
                                                    color={Color.eerie_black}
                                                    id="close-modal"
                                                    className="cursor-pointer"
                                                    data-bs-dismiss="modal" />
                                            </div>
                                        </div>
                                        <div className="mx-4 d-flex align-items-start padding-12px-all mt-3 e-bg-light-blue border-radius-12px mt-3 margin-12px-bottom color-ocean-blue e-font-14 e-line-height-20 gap-4px e-montserrat-medium">
                                            <span>
                                                <Icon icon="info-circle"
                                                    size={16} />
                                            </span>
                                            This OTP is needed to import your holding summary and enable transactions in these folios.
                                        </div>
                                        <div className="px-4 padding-14px-top">
                                            <p className="e-font-14 mb-2 color-eerie-black e-montserrat-regular e-line-height-24">
                                                An OTP  has been sent to the mobile number linked to your PAN by MF Central.
                                            </p>
                                            {/* <h6 className="e-font-14 mb-3 color-eerie-black e-montserrat-semi-bold e-line-height-24">{MASKED_NUMBER}</h6> */}
                                            <CustomPINInput id="otp-input"
                                                autoFocus={true}
                                                pinString={otpMf}
                                                error={otpMfError}
                                                postfix="*"
                                                type={2}
                                                title="Enter OTP"
                                                onSubmit={_submitOTPMf}
                                                handleChange={_handleOTPMf} />
                                            <p className="color-charcoal-blue e-montserrat-medium e-font-14 e-line-height-20 mt-4">Didn’t get OTP? <span className={`no-decoration color-rebecca-purple e-link ${seconds === 0 ? 'cursor-pointer' : 'disabled'}`} onClick={_handleResendMf}>{seconds === 0 ? 'Resend OTP' : ` Resend OTP in ${seconds < 10 ? `0${seconds}` : seconds}s`}</span></p>
                                        </div>
                                        <div className="row mt-auto px-4">
                                            <div className="col-6">
                                                <SecondaryButton label="Cancel"
                                                    cancel="modal"
                                                    className="padding-12px-tb w-100 e-bg-magnolia" />
                                            </div>
                                            <div className="col-6">
                                                <PrimaryButton label="Verify"
                                                    className="padding-12px-tb  w-100"
                                                    disabled={initiateLoader}
                                                    onPress={_submitOTPMf} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                :

                                verifyStep === 5 ?
                                    <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_loader}`}>
                                        <div className={`modal-content text-center p-2 ${style.e_content} h-490px`}>
                                            <span id="close"
                                                className="d-none"
                                                data-bs-dismiss="modal" ></span>
                                            <div className="my-auto">
                                                <img src={require("../../Assets/Images/Loader/page-loader.gif")}
                                                    alt="loader"
                                                    width={80}
                                                    height={80}
                                                    className="mx-auto" />
                                                <h6 className='e-font-16 mb-0 color-eerie-black e-montserrat-medium e-line-height-30 mt-3'>
                                                    Fetching your external investment
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>

                                        <div className={`modal-content padding-36px-bottom  text-center px-4 ${style.e_content}`}>
                                            <div className="my-auto">
                                                <img src={require("../../Assets/Images/External/success.png")}
                                                    alt="success"
                                                    draggable={false}
                                                    width={108}
                                                    className="mx-auto margin-40px-top" />
                                                <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-eerie-black mb-2 text-start mt-3">
                                                    {props.title}
                                                </h5>

                                                <p className="e-montserrat-medium e-font-16 e-line-height-28 color-charcoal-gray mb-0 text-start">
                                                    {props.content}
                                                </p>


                                                <p className="color-eerie-black e-font-16 e-line-height-24 e-montserrat-medium mb-0 mt-3 text-start">
                                                    <a href="https://calendly.com/connect-mintit"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="border-bottom-1px border-color-purple text-decoration-none w-max-content pt-1 mb-0 color-rebecca-purple e-font-16 e-line-height-24 e-montserrat-semi-bold">
                                                        Book An Appointment
                                                    </a> for free portfolio review
                                                </p>
                                                {
                                                    props.showButton === true &&
                                                    <div className="row d-flex justify-content-center">
                                                        <div className="col-12">
                                                            <PrimaryButton label={props.label ? props.label : "Continue"}
                                                                className="w-100 padding-12px-tb mt-4"
                                                                cancel="modal"
                                                                onPress={props.onSuccessfulImport}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    </div>
            }

        </div>
    )

}

export default ImportExternalFundsModal
