/*
 *   File : calender.js
 *   Author : https://evoqins.com
 *   Description : Calender component
 *   Integrations : react-day-picker
 *   Version : 1.0.0
*/

import React, { Fragment, useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select'
import { DayPicker } from 'react-day-picker';

import Colors from '../../Styles/color.module.scss'

// import components
import { Icon } from '../Icon';
import { useClickOutside } from '../../Helper/helper';


const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <Icon icon="header-drop-down"
                width="16px"
                height="16px"
                color="#65308C" />
        </components.DropdownIndicator>
    );
};
// const pastMonth = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

const CustomDatePicker = React.memo((props) => {
    // state when single date has to be selected
    const [selected, setSelected] = useState(props.start === "DD-MM-YYYY" ? null : new Date(parseDate(props.start)));
    // state when date is a range
    const [range, setRange] = useState(props.start === "DD-MM-YYYY" ?
        { from: null, to: null }
        :
        {
            from: new Date(parseDate(props.start)),
            to: new Date(parseDate(props.end))
        });
    // drop down open
    const [show, setShow] = useState(false);
    const calendarRef = useRef(null);

    useEffect(() => {
        if (props.mode === "single") {
            setSelected(props.start === "DD-MM-YYYY" ? null : new Date(parseDate(props.start)));
        }
        // eslint-disable-next-line
    }, [props.start])

    useEffect(() => {
        if (props.mobile === true) {
            if (show === true) {

                document.body.style.pointerEvents = "none"
            } else {
                document.body.style.pointerEvents = ""
            }
        }
    }, [props.mobile, show])


    useClickOutside(calendarRef, () => {
        if (props.mobile !== true) {
            setShow(false);
        }
    });

    useEffect(() => {
        props.menuOpen(show);

        //eslint-disable-next-line
    }, [show])

    useEffect(() => {
        if (props.mode !== "single") {
            if (range && range.from && isNaN(range.from.getTime())) {
                props.selectDate({
                    from: "DD-MM-YYYY",
                    to: "DD-MM-YYYY"
                });
                setRange({
                    from: null,
                    to: null
                })
            }
        }
        // eslint-disable-next-line
    }, [range, props.mode]);


    useEffect(() => {
        if (props.mode === 'single' && selected) {
            setShow(false);
            props.selectDate(selected); // Passing the selected single date directly
        } else if (range && range.from && range.to) {
            setShow(false);
            props.selectDate(range);
        }
        //eslint-disable-next-line
    }, [selected, range]);

    useEffect(() => {
        if (props.start === "DD-MM-YYYY" && props.mode !== "single") {
            setRange({
                from: null,
                to: null
            })
        }
        // eslint-disable-next-line
    }, [props.start, props.mode]);

    useEffect(() => {
        if (props.mode !== "single") {
            if (range && range.from && isNaN(range.from.getTime())) {
                props.selectDate({
                    from: "DD-MM-YYYY",
                    to: "DD-MM-YYYY"
                });
                setRange({
                    from: null,
                    to: null
                })
            }
        }
        // eslint-disable-next-line
    }, [range, props.mode]);

    function handleCalendar() {
        setShow(true);
    }


    function _clearDate(event) {
        event.stopPropagation()

        if (props.mode !== "single") {
            setRange({ from: null, to: null });
            props.selectDate({
                from: "DD-MM-YYYY",
                to: "DD-MM-YYYY"
            });
        } else {
            setSelected(null);
            props.selectDate("DD-MM-YYYY")
        }
    }

    // default date function when date is not passed
    function parseDate(dateString) {
        const parts = dateString.split('-');
        return new Date(parts[2], parts[1] - 1, parts[0]);
    }

    const customStyles = {
        container: (defaultStyles, state) => ({
            ...defaultStyles,
        }),
        placeholder: (defaultStyles) => ({
            ...defaultStyles,
            margin: "0",
            color: Colors.medium_gray,
            fontSize: "12px",
            lineHeight: "18px",
            paddingLeft: "2px",
            fontFamily: "Montserrat-Regular"
        }),
        input: (defaultStyles) => ({
            ...defaultStyles,
            margin: "0",
            padding: "0"
        }),
        control: (defaultStyles, state) => ({
            ...defaultStyles,
            borderRadius: "8px",
            border: state.isFocused ? `1px solid ${Colors.rebecca_purple}` : `1px solid ${Colors.silver_sand}`,
            background: "transparent",
            padding: "6px 8px",
            boxShadow: "none",
            "&:hover": {
                border: state.isFocused ? `1px solid ${Colors.rebecca_purple}` : `1px solid ${Colors.silver_sand}`,
                cursor: 'pointer',
            },
            "&[aria-disabled = true]": {
                background: Colors.light_lavender
            }
        }),
        valueContainer: (defaultStyles) => ({
            ...defaultStyles,
            padding: "0px",
        }),


        menuList: (defaultStyles, state) => ({
            ...defaultStyles,
            position: 'relative', // Ensure relative positioning for the scrollbar to appear correctly
            '&::-webkit-scrollbar': {
                width: '8px',
                height: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: Colors.rebecca_purple,
            },
        }),

        dropdownIndicator: (defaultStyles, state) => ({
            ...defaultStyles,
            padding: "0",
            marginLeft: "4px",
            transition: "transform 0.2s",
            transform: state.selectProps.menuIsOpen === true ? "rotate(-180deg)" : ""
        }),
        menu: (defaultStyles, state) => ({
            ...defaultStyles,
            overflow: "hidden",
            padding: "0px",
            borderRadius: "16px",
            width: "150px",
            border: `1px solid ${Colors.athens_gray}`,
            boxShadow: "5px 3px 10px 0px #0000001A"
        }),
        option: (defaultStyles, state) => ({
            ...defaultStyles,
            padding: "8px 12px",
            fontFamily: "Montserrat-Medium",
            fontSize: "12px",
            fontStyle: " normal",
            fontWeight: "500",
            lineHeight: "18px",
            letterSpacing: "0.07em",
            backgroundColor: Colors.white,
            color: state.isSelected ? `${Colors.rebecca_purple} !important` : `${Colors.eerie_black} !important`,

            // borderRadius: "12px",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: Colors.light_lavender,
            },
            "&:not(:last-child)": {
                borderBottom: `solid 1px ${Colors.bright_gray}`,
            },

        }),

        singleValue: (defaultStyles) => ({
            ...defaultStyles,
            fontSize: "12px",
            lineHeight: "20px",
            marginLeft: "0",
            fontFamily: "Montserrat-Medium",
            color: Colors.charleston_green
        }),
    };

    return (
        <Fragment>
            {props.label && <p className="e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text margin-6px-bottom bg-transparent">
                {props.label}
                {props.postfix && <span className="color-deep-carmine-pink">*</span>}
            </p>}
            <div className='position-relative' ref={calendarRef} style={{ pointerEvents: props.disabled === true ? "none" : "auto" }}>
                {
                    props.mode === "single" ?
                        <div className={`${props.className} ${props.start !== "DD-MM-YYYY" ? "color-charleston-green e-montserrat-medium e-font-14" : "color-medium-gray e-font-14 e-montserrat-regular"} d-flex align-items-center justify-content-between e-line-height-20 bg-white border-1px border-silver-sand padding-12px-tb px-3 border-radius-12px cursor-pointer mb-0`}
                            onClick={handleCalendar}>
                            {props.start}
                            <Icon icon="dob" size={16} />
                        </div>
                        :
                        range && range.from === null ?
                            <div className={`${props.className} d-flex align-items-center justify-content-between e-line-height-24 bg-white border-1px border-silver-sand p-2 border-radius-8px cursor-pointer color-gray e-font-14 e-montserrat-regular mb-0`}
                                onClick={handleCalendar}>
                                DD-MM-YYYY - DD-MM-YYYY
                                <Icon icon="dob" size={16} />
                            </div>
                            :
                            <div className={`${props.className} d-flex align-items-center justify-content-between e-line-height-24 bg-white border-1px border-silver-sand p-2 border-radius-8px cursor-pointer color-gray e-font-14 e-montserrat-regular mb-0`}
                                onClick={handleCalendar}>
                                {props.start} - {props.end}
                                <Icon icon="close" size={16} onClick={_clearDate} />
                            </div>
                }


                {show && (
                    <div className={`position-absolute z-index-99 top-40px ${props.popupClass}`}>
                        <Icon icon="close-btn"
                            id="close-btn"
                            size={20}
                            color="#000"
                            className="position-absolute right-10px top-34px z-index-5 cursor-pointer"
                            onClick={() => setShow(false)} />
                        <DayPicker
                            id="test"
                            captionLayout="dropdown"
                            disabled={props.disabledDays}
                            mode={props.mode}
                            defaultMonth={props.defaultMonth}
                            fromYear={1947}
                            toYear={props.toYear}
                            components={{
                                Dropdown: ({ caption, value, children, ...rest }) => {
                                    const options = rest.name === "years" ? children.sort((a, b) => Number(b.key) - Number(a.key)).map((item) => ({
                                        label: item.props.children,
                                        value: item.key
                                    })) : children.map((item) => ({
                                        label: item.props.children,
                                        value: item.key
                                    }));

                                    // Safeguard against undefined values
                                    const selectedValue = { label: caption, value };
                                    return (
                                        <Select
                                            options={options}
                                            value={selectedValue}
                                            className='me-2'
                                            styles={customStyles}
                                            isSearchable={false}
                                            // menuIsOpen={true}
                                            components={{
                                                DropdownIndicator,
                                                IndicatorSeparator: null,
                                            }}
                                            onChange={(selectedOption) => {
                                                // Ensure selectedOption exists and pass the correct value to rest.onChange
                                                if (selectedOption) {
                                                    // Calling the rest.onChange provided by DayPicker to update the value
                                                    rest.onChange({
                                                        target: { value: selectedOption.value }
                                                    });
                                                }
                                            }}
                                        />
                                    );
                                }
                            }}


                            selected={props.mode === 'single' ? selected : range}
                            onSelect={props.mode === 'single' ? setSelected : setRange}
                        />
                    </div>
                )}

                {
                    props.error &&
                    <p className='position-absolute color-deep-carmine-pink  e-montserrat-medium e-font-12  e-line-height-16 mb-0  margin-6px-top'>
                        {props.error}
                    </p>
                }
            </div>
        </Fragment>
    );
});

CustomDatePicker.defaultProps = {
    start: "DD-MM-YYYY",
    end: "DD-MM-YYYY",
    menuOpen: () => { },
    toYear: new Date().getFullYear()
};

export default CustomDatePicker;
