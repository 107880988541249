/*
*   File : support.js
*   Author : https://evoqins.com
*   Description : Screen for support list
*   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useState } from "react";
import ReactPaginate from "react-paginate"
import { toast } from "react-toastify"

// Components
import { CustomTabBar } from "../../Components/Tab"
import { DataTableContainer } from "../../Components/Table"
import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "../../Components/Header";
import { Breadcrumb, EmptyScreen, Loader } from "../../Components/Other";
import { PrimaryButton } from "../../Components/Buttons";
import { Icon } from "../../Components/Icon";
import { CustomDatePicker, CustomFileInput, CustomSelectBox, CustomTextArea, CustomTextInput } from "../../Components/FormElements";

// Styles
import Color from "../../Styles/color.module.scss";

// Services
import { _getFilePath } from "../../Helper/api";
import { _uploadDocumentToS3 } from "../../Services/aws-service";
import APIService from "../../Services/api-service"
import { _formatDate } from "../../Helper/helper";

const TAB_DATA = [
    {
        id: 1,
        label: "Raise A Ticket"
    },
    {
        id: 2,
        label: "My Tickets"
    }
]

const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
            backgroundColor: 'transparent',
        },
    },
    head: {
        style: {
            // height: "0px"
        }
    },
    headRow: {
        style: {
            borderRadius: "16px 16px 0 0",
            // borderBottomColor: 'transparent',
            fontSize: "12px",
            lineHeight: "18px",
            fontWeight: "600",
            fontFamily: "Montserrat-SemiBold",
            color: Color.charleston_green,
            padding: "12px 8px"

        }
    },
    rows: {
        style: {
            cursor: "pointer",
            '&:not(:last-child)': {
                borderBottom: `1px solid ${Color.bright_gray}`,
            },
            padding: "16px 8px",
            backgroundColor: 'transparent',
        },
    },
    cells: {
        style: {
            // paddingLeft: '0px !important'
        }
    }

}


const NextIcon = () => {
    return (
        <Icon icon='Arrow-Right'
            width="24px"
            height="24px"
            color={Color.rebecca_purple} />
    )
}

const PrevIcon = () => {
    return (
        <Icon icon='Arrow-Right'
            width="24px"
            height="24px"
            color={Color.rebecca_purple}
            className="e-transform-left-arrow" />
    )
}

const BreakLabel = () => {
    return (
        <span>...</span>
    )
}

const SupportList = () => {
    const today = new Date();
    const disabledDays = { after: today };
    const navigate = useNavigate();
    const location = useLocation();

    const [apiLoader, setApiLoader] = useState(true);
    const [selectedTab, setSelectedTab] = useState(TAB_DATA[0].id);
    const [pageCount, setPageCount] = useState(0); //total number of pages
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const [supportList, setSupportList] = useState([]);

    const [selectedSubject, setSelectedSubject] = useState({});
    const [selectedSubjectError, setSelectedSubjectError] = useState("");
    const [subjectOptions, setSubjectOptions] = useState([]);
    const [filterLoader, setFilterLoader] = useState(true);
    const [subject, setSubject] = useState("");
    const [subjectError, setSubjectError] = useState("");
    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState("");

    const [fileName, setFileName] = useState("");
    const [fileUrl, setFileUrl] = useState("");
    const [fileLoader, setFileLoader] = useState(false);
    const [fileNameError, setFileNameError] = useState("");

    const [submitLoader, setSubmitLoader] = useState(false);

    const [startDate, setStartDate] = useState("DD-MM-YYYY");
    const [endDate, setEndDate] = useState("DD-MM-YYYY");

    useEffect(() => {
        if (location.state !== null) {
            setCurrentPage(location.state.current_page);
            setSelectedTab(location.state.tab_index);
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
        _getFilterData();
        // eslint-disable-next-line
    }, [location]);

    useEffect(() => {
        _getSupportList();
        // eslint-disable-next-line
    }, [currentPage, selectedTab, startDate, endDate]);

    function _handleNavigate() {
        navigate("/");
    }

    // handle Returns
    function _handleTabChange(value) {
        setSelectedTab(value);
        navigate('/support', { state: { tab_index: value, current_page: 1, } }, { replace: true })
    }

    const TABLE_COLUMNS = [
        {
            name: "Subject",
            selector: row => row.subject,
            cell: row => <div onClick={() => _handleRowClick(row)}>
                <p className="e-montserrat-medium e-font-14 e-line-height-20 color-eerie-black mb-2 text-truncate">{row.subject}</p>
                <p className="e-montserrat-regular e-font-14 e-line-height-20 color-eerie-black mb-0 text-truncate">{row.description}</p>
            </div>,
            sortable: false,
            width: "550px",
            style: {
                display: "inline-block"
            }
        },
        {
            name: "ID",
            cell: row => <div onClick={() => _handleRowClick(row)}>
                <p className="e-montserrat-medium e-font-14 e-line-height-20 color-eerie-black">{row.ticket_no}</p>
            </div>,
            width: "100px",
            sortable: false,
        },
        {
            name: "Photo or screenshot",
            selector: row => row.image === null ? "-" :
                <button className="e-customize-btn  e-font-14 e-line-height-18 e-montserrat-semi-bold py-1 px-2 d-flex gap-4px align-items-center me-5 my-2 ms-2 text-truncate w-200px" onClick={() => window.open(row.image, "_blank")}>
                    <img src={require("../../Assets/Images/Support/support-image.png")}
                        alt={"Screen shot"}
                        width={24}
                        height={24}
                        draggable={false} />
                    <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space text-truncate">{row.image_name}</p>
                </button>,
            width: "260px",
            // selector: row => row.image_name,
            sortable: false,
            style: {
                marginRight: "20px",
                padding: "0px"
            }
        },
        {
            name: "Status",
            width: "200px",
            cell: row => <div className={`d-flex align-items-center gap-4px border-radius-16px py-1 px-2 ${row.status_id === 1 ?
                "e-bg-persian-blue" :
                row.status_id === 2 ?
                    "e-bg-dark-tangerine" :
                    row.status_id === 3 ?
                        "e-bg-green-NCS" :
                        row.status_id === 4 ?
                            "e-bg-flirt" :
                            "e-bg-bright-gray"}`}
                onClick={() => _handleRowClick(row)}>
                <div className={`w-6px h-6px rounded-circle bg-white `}></div>
                <p className={`e-montserrat-medium e-font-12 e-line-height-18 mb-0 color-white`}>{row.status}</p>
            </div>,
            sortable: false,
        },
        {
            name: '',
            sortable: false,
            left: true,
            width: "48px",
            cell: row => <span>
                <Icon icon="arrow-right"
                    size={24}
                    onClick={() => _handleRowClick(row)} />
            </span>

        }
    ]

    const _handleRowClick = (row) => {
        navigate("detail", {
            state: {
                data: row,
                current_page: currentPage,
                tab_index: selectedTab
            }
        })
    }

    // Handle page change
    const _handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber + 1);
        setApiLoader(true);
    };

    function _handleSubjectOptions(select_value) {
        setSelectedSubject(select_value);
        setSelectedSubjectError("");
        setSubject("");
        setSubjectError("");
    }

    const _handleSubject = (value) => {
        setSubject(value);
        setSubjectError("");
    }

    const _handleDescription = (value) => {
        setDescription(value);
        setDescriptionError("");
    }

    // handle start date
    function _handleRange(date_range) {
        const formatted_start_date = _formatDate(date_range.from);
        const formatted_end_date = _formatDate(date_range.to);
        setStartDate(formatted_start_date);
        setEndDate(formatted_end_date);
    }

    // function handle file
    function _handleFileUpload(file) {
        const acceptedExtensions = ['.png', '.jpg', '.jpeg'];
        const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();

        if (!acceptedExtensions.includes(fileExtension)) {
            setFileNameError("Invalid file type. Please upload a PNG or JPG file.");
            return; // Exit the function if the file is not in the accepted list
        }

        if (file.size > 5242880) { // 5MB in bytes
            setFileNameError("Upload file below 5MB");
        } else {
            _uploadFile(file);
            setFileNameError("");
        }
    }


    // API - upload PAN
    const _uploadFile = async (file) => {
        setFileLoader(true);
        const request = {
            type: "image",
            file: file.name,
            is_public: false
        }

        await _getFilePath(request).then((response) => {
            toast.dismiss();
            _uploadDocumentToS3(response.data, file).then((result) => {
                setFileName(file.name);
                setFileUrl(response.data.view_info);
                setFileLoader(false);
            }).catch((error) => {
                setFileLoader(false);
                toast.error(error, {
                    type: "error"
                });
            });
        });
    }

    // API - support list
    const _getSupportList = () => {
        const url = "/support/list";
        const request = {
            page_num: currentPage,
            page_size: 12,
            "date_from": startDate === "DD-MM-YYYY" ? null : startDate,
            "date_to": endDate === "DD-MM-YYYY" ? null : endDate
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setSupportList(response.data.data);
                setPageCount(response.data.total_page);
            }
            else {
                setSupportList([]);
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
            setApiLoader(false);
        })
    }

    // API - support options
    const _getFilterData = () => {
        const url = "/support/filter-data";

        APIService(false, url).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                setSubjectOptions(response.data)
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setFilterLoader(false);
        });
    }


    const _validate = () => {
        let valid = true;
        if (Object.keys(selectedSubject).length === 0) {
            setSelectedSubjectError("Please select subject");
            valid = false;
        }
        if (Object.keys(selectedSubject).length !== 0 && selectedSubject.value === null) {
            if (subject.length === 0) {
                setSubjectError("Please select subject");
                valid = false;
            }
        }
        if (description.length === 0) {
            setDescriptionError("Please describe your concern");
            valid = false;
        }
        if (description.length !== 0 && description.length < 50) {
            setDescriptionError("minimum 50 characters needed");
            valid = false;
        }
        if (valid === true) {
            setSubmitLoader(true);
            _submit();
        }
    }

    // API - submit ticket
    const _submit = () => {
        const url = "/support/raise-ticket";
        const request = {
            subject_id: selectedSubject.value,
            subject: selectedSubject.value === null ? subject : selectedSubject.label,
            description: description,
            image: fileUrl
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success(response.message, {
                    type: "success",
                });
                navigate('/support', { state: { tab_index: 2, current_page: 1, } }, { replace: true })
            }
            else {
                toast.error(response.message, {
                    type: "error",
                });
            }
            setSelectedSubject({});
            setSelectedSubjectError("");
            setSubject("")
            setSubjectError("");
            setDescription("");
            setDescriptionError("");
            setSubmitLoader(false);
        })
    }

    return (
        apiLoader === true ?
            <Loader height="h-80vh" />
            :
            <Fragment>
                <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                    {/* Header */}
                    <Header type={2} />
                    <div className="row ">
                        <div className="col-lg-11 pe-lg-0 padding-144px-bottom">

                            {/* Support list */}
                            <div className="px-4">
                                <Breadcrumb mainPage="Home"
                                    currentPage="Support"
                                    handleNavigate={_handleNavigate}
                                    type={1} />
                                <h5 className="margin-30px-top e-font-18 e-line-height-38 e-montserrat-semi-bold color-dark-jungle-green mb-sm-3 mb-0">Support</h5>

                                <div className="border-bottom-1px border-bright-gray px-2 ">
                                    <CustomTabBar data={TAB_DATA}
                                        selectedTab={selectedTab}
                                        onSelectTab={_handleTabChange} />
                                </div>
                                {
                                    selectedTab === 1 ?
                                        filterLoader === true ?
                                            <Loader height="h-80vh" />
                                            :
                                            <Fragment>
                                                <div className="row mt-sm-4 mt-2 padding-32px-bottom border-bottom-1px border-bright-gray">
                                                    <div className="col-md-6">

                                                        <CustomSelectBox placeholder={Object.keys(selectedSubject).length === 0 ? "Select a subject" : ""}
                                                            error={selectedSubjectError}
                                                            label="Subject"
                                                            postFix={true}
                                                            value={selectedSubject}
                                                            options={subjectOptions}
                                                            onSelectChange={_handleSubjectOptions} />
                                                        {
                                                            Object.keys(selectedSubject).length !== 0 && selectedSubject.value === null &&
                                                            <CustomTextInput label="Subject"
                                                                postfix="*"
                                                                className="mt-4"
                                                                value={subject}
                                                                error={subjectError}
                                                                placeholder="Enter your query"
                                                                handleChange={_handleSubject} />
                                                        }
                                                        <CustomTextArea label="Description"
                                                            postfix="*"
                                                            className="my-4"
                                                            inputClass="h-200px"
                                                            value={description}
                                                            error={descriptionError}
                                                            placeholder="Describe your query in detail in minimum 50 characters., for better response, kindly include a screenshot or photograph"
                                                            handleChange={_handleDescription} />

                                                        <CustomFileInput
                                                            label="Attach a file"
                                                            file={fileName}
                                                            error={fileNameError}
                                                            id="e-ticket"
                                                            loader={fileLoader}
                                                            accept=".png, .jpg, .jpeg"
                                                            onFileChange={_handleFileUpload}
                                                            clear={() => setFileName("")} />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-md-3">
                                                        <PrimaryButton label="Submit"
                                                            className="padding-12px-tb  w-100"
                                                            disabled={submitLoader}
                                                            onPress={_validate} />
                                                    </div>
                                                </div>
                                            </Fragment>
                                        :
                                        <Fragment>

                                            <div className="e-bg-cultured border-radius-12px px-3 padding-22px-tb my-4 d-flex align-items-center justify-content-between">
                                                <p className="e-montserrat-semi-bold e-font-16 e-line-height-24 color-eerie-black mb-0">Recent Tickets</p>

                                                <div className="col-lg-3 col-md-4 col-sm-5 pe-0">
                                                    <CustomDatePicker start={startDate}
                                                        mode="range"
                                                        disabledDays={disabledDays}
                                                        toYear={new Date().getFullYear()}
                                                        className="e-date"
                                                        mobile={true}
                                                        end={endDate}
                                                        popupClass="e-date-popup"
                                                        selectDate={_handleRange} />
                                                    
                                                </div>
                                            </div>
                                            {
                                                supportList.length === 0 ?
                                                    <EmptyScreen className="my-5"
                                                        title="No Support Tickets"
                                                        type={4}
                                                        description="" />
                                                    :
                                                    <Fragment>
                                                        <div className="border-radius-16px border-1px border-bright-gray e-support-table overflow-hidden">
                                                            <DataTableContainer columns={TABLE_COLUMNS}
                                                                data={supportList}
                                                                customStyles={TABLE_STYLE}
                                                                rowClick={(row) => _handleRowClick(row)} />
                                                            <ReactPaginate previousLabel={<PrevIcon />}
                                                                nextLabel={<NextIcon />}
                                                                breakLabel={<BreakLabel />}
                                                                pageCount={pageCount}
                                                                pageRangeDisplayed={2}
                                                                marginPagesDisplayed={1}
                                                                onPageChange={(value) => _handlePageChange(value.selected)}
                                                                renderOnZeroPageCount={null}
                                                                forcePage={currentPage - 1}
                                                                containerClassName="e-pagination margin-38px-top margin-26px-bottom"
                                                                activeClassName="e-pagination-active" />
                                                        </div>

                                                    </Fragment>
                                            }
                                        </Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
    )
}

export default SupportList;