/*
 *   File : header.js
 *   Author : https://evoqins.com
 *   Description : Header component
 *   Version : 1.0.0
*/

// import packages
import { memo, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom/dist";

// import components
import { Icon } from "../Icon";


const CustomFooter = (props) => {
    const navigate = useNavigate();
    return (
        <Fragment>
            <div className={` padding-32px-tb text-center ${props.background} px-lg-0 px-3`}>
                <div className={`col-lg-11 ${props.containerClass}`}>
                    <img src={require("../../Assets/Images/Global/mintit-logo.svg").default}
                        alt="logo"
                        height={68}
                        width={90}
                        draggable={false}
                        className="e-logo mb-2 object-fit-contain"
                        onClick={() => {
                            navigate("/");
                            window.scrollTo(0, 0)
                        }} />

                    <div className="d-flex flex-wrap justify-content-center gap-32px">
                        {
                            props.type === 1 ?
                                null
                                :
                                <Link to="/support" className="no-decoration e-link e-font-14 e-line-height-24 e-montserrat-semi-bold color-blue-gray" >Support</Link>
                        }
                        <Link to="/faq" className="no-decoration e-link e-font-14 e-line-height-24 e-montserrat-semi-bold color-blue-gray" >FAQs</Link>
                        <a href={process.env.REACT_APP_TERMS} target="_blank" rel="noreferrer" className="no-decoration e-link e-font-14 e-line-height-24 e-montserrat-semi-bold color-blue-gray cursor-pointer" >Terms & Conditions</a>
                        <a href={process.env.REACT_APP_PRIVACY} target="_blank" rel="noreferrer" className="no-decoration e-link e-font-14 e-line-height-24 e-montserrat-semi-bold color-blue-gray cursor-pointer" >Privacy Policy</a>
                        <a href={process.env.REACT_APP_DISCLAIMER} target="_blank" rel="noreferrer" className="no-decoration e-link e-font-14 e-line-height-24 e-montserrat-semi-bold color-blue-gray cursor-pointer" >Disclaimer</a>
                        <a href={process.env.REACT_APP_DISCLOSURE} target="_blank" rel="noreferrer" className="no-decoration e-link e-font-14 e-line-height-24 e-montserrat-semi-bold color-blue-gray cursor-pointer" >Disclosure</a>
                    </div>
                    <div className="border-bottom-1px border-light-periwinkle margin-32px-top"></div>
                    <div className="d-flex flex-wrap gap-8px justify-content-sm-between justify-content-center align-items-center mt-3">
                        <p className="e-font-14 e-line-height-24 e-montserrat-regular color-steel-blue mb-0">© {new Date().getFullYear()} MINTIT. All rights reserved.</p>

                        <div className="d-flex justify-content-start gap-24px">

                            <Link to="https://www.linkedin.com/company/mintit-in/" target="_blank" rel="noreferrer">
                                <Icon icon="linkedin" size={20} className="e-social-icon" />
                            </Link>

                            <Link to="https://www.youtube.com/@mintitapp" target="_blank" rel="noreferrer">
                                <Icon icon="youtube" size={20} className="e-social-icon" />
                            </Link>

                            <Link to="https://www.facebook.com/mintit.in/" target="_blank" rel="noreferrer">
                                <Icon icon="facebook" size={20} className="e-social-icon" />
                            </Link>

                            <Link to="https://www.instagram.com/mintit.in/" target="_blank" rel="noreferrer">
                                <Icon icon="instagram" size={20} className="e-social-icon" />
                            </Link>

                        </div>

                        {/* <p className="e-font-14 e-line-height-24 e-montserrat-regular color-steel-blue mb-0">Powered by <a href="https://evoqins.com" className="no-decoration e-link color-steel-blue" target="_blank" rel="noreferrer">Evoqins</a></p> */}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default memo(CustomFooter)