/*
*   File : personal-detail.js
*   Author : https://evoqins.com
*   Description : Screen to fill the personal details of User
*   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

// import components
import { Icon } from "../../../Components/Icon";
import { PrimaryButton } from "../../../Components/Buttons";
import { StepProgress } from "../../../Components/Other";
import { KYCProgressCard } from "../../../Components/Card";
import { CustomDatePicker, CustomFileInput, CustomTextInput, RadioGroup } from "../../../Components/FormElements";
import { IncomeSourceDropdown, OccupationDropdown, CustomDropdown } from "../../../Components/Dropdown";
import { KYCErrorCard } from "../../../Components/Cards";

// import services
import APIService from "../../../Services/api-service";
import { _uploadDocumentToS3 } from "../../../Services/aws-service";
import { _getFilePath, _getSignupProgress } from "../../../Helper/api";
import { _formatDate, _getFileName, _getOptions } from "../../../Helper/helper";

const CITIZEN_OPTIONS = [
    {
        id: 1,
        name: "Yes"
    },
    {
        id: 2,
        name: "No"
    },
];
const PersonalDetails = (props) => {

    const GENERAL_DATA = useSelector(state => state.Reducer.GENERAL_DATA);
    const PROGRESS_DATA = useSelector(store => store.Reducer.SIGNUP_PROGRESS);

    const today = new Date();
    const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

    const disabledDays = { after: eighteenYearsAgo };

    const dobRef = useRef(null);
    const [dob, setDob] = useState("DD-MM-YYYY");
    const [dobError, setDobError] = useState("");

    const [genderId, setGenderId] = useState(null);
    const [genderError, setGenderError] = useState("");
    const [genderOptions, setGenderOptions] = useState([]);

    const [politicallyExposed, setPoliticallyExposed] = useState(null);
    const [politicallyExposedOptions, setPoliticallyExposedOptions] = useState([]);

    const [maritalId, setMaritalId] = useState(null);
    const [maritalStatusOptions, setMaritalStatusOptions] = useState([]);

    const [citizenType, setCitizenType] = useState(CITIZEN_OPTIONS[0].id);

    const citizenRef = useRef(null);
    const [citizen, setCitizen] = useState(null);
    const [citizenError, setCitizenError] = useState(null);
    const [citizenShipOptions, setCitizenShipOptions] = useState([]);

    const taxPayingRef = useRef(null);
    const [taxPaying, setTaxPaying] = useState(CITIZEN_OPTIONS[1].id);
    const [birthCountry, setBirthCountry] = useState({ label: "India", value: 251 });
    const [birthCountryError, setBirthCountryError] = useState("");

    const [identificationType, setIdentificationType] = useState(null);
    const [identificationTypeError, setIdentificationTypeError] = useState(null);
    const [identificationOptions, setIdentificationOptions] = useState([]);

    const [taxPayingCountry, setTaxPayingCountry] = useState(null);
    const [taxPayingCountryError, setTaxPayingCountryError] = useState(null);
    const [taxPayingCountryOptions, setTaxPayingCountryOptions] = useState([]);

    const [taxNumber, setTaxNumber] = useState("");
    const [taxNumberError, setTaxNumberError] = useState("");

    const [occupation, setOccupation] = useState(null);
    const [occupationError, setOccupationError] = useState(null);
    const [occupationOptions, setOccupationOptions] = useState([]);

    const [source, setSource] = useState(null);
    const [sourceError, setSourceError] = useState(null);
    const [sourceOptions, setSourceOptions] = useState([]);

    const [annualIncome, setAnnualIncome] = useState(null);
    const [annualIncomeError, setAnnualIncomeError] = useState(null);
    const [annualIncomeOptions, setAnnualIncomeOptions] = useState([]);

    const [fatherName, setFatherName] = useState("");
    const [fatherNameError, setFatherNameError] = useState("");

    const [motherName, setMotherName] = useState("");
    const [motherNameError, setMotherNameError] = useState("");

    const [panName, setPanName] = useState("");
    const [panUrl, setPanUrl] = useState("");
    const [panUploader, setPanUploader] = useState(false);
    const [panError, setPanError] = useState("");

    const [photo, setPhoto] = useState("");
    const [photoUrl, setPhotoUrl] = useState("");
    const [photoUploader, setPhotoUploader] = useState("");
    const [photoError, setPhotoError] = useState("");

    const [signature, setSignature] = useState("");
    const [signatureUrl, setSignatureUrl] = useState("");
    const [signatureUploader, setSignatureUploader] = useState("");
    const [signatureError, setSignatureError] = useState("");

    // const [aadhaar, setAadhaar] = useState("");
    // const [aadhaarUrl, setAadhaarUrl] = useState("");
    // const [aadhaarUploader, setaadhaarUploader] = useState("");
    // const [aadhaarError, setaadhaarError] = useState("");

    const [kycUser, setKycUser] = useState(false);
    const [apiLoader, setApiLoader] = useState(false);
    const [errors, setErrors] = useState([]);
    const [defaultMonth, setDefaultMonth] = useState(eighteenYearsAgo);

    useEffect(() => {
        _getCountries();
    }, []);

    useEffect(() => {
        if (Object.keys(GENERAL_DATA).length > 0) {
            // gender options
            const gender_options = _getOptions(GENERAL_DATA.genders, 1);
            // setGenderId(gender_options[0].id);
            setGenderOptions(gender_options);

            // marital status options
            const marital_options = _getOptions(GENERAL_DATA.marital_statuses, 1);
            setMaritalId(marital_options[0].id);
            setMaritalStatusOptions(marital_options);

            // FACTA identification types
            const identification_types = _getOptions(GENERAL_DATA.fatca_verification);
            setIdentificationOptions(identification_types);

            // occupation options
            const occupation_options = _getOptions(GENERAL_DATA.occupation);
            setOccupationOptions(occupation_options);

            // source of income options
            const income_source = _getOptions(GENERAL_DATA.source_of_wealths);
            setSourceOptions(income_source);

            // annual income options
            const annual_income = _getOptions(GENERAL_DATA.annual_income);
            setAnnualIncomeOptions(annual_income);

            const political_options = _getOptions(GENERAL_DATA.pep_details, 1);
            setPoliticallyExposed(political_options[political_options.length - 1].id);
            setPoliticallyExposedOptions(political_options);
        }
    }, [GENERAL_DATA]);

    useEffect(() => {
        const signup_progress = PROGRESS_DATA
        setKycUser(signup_progress.kyc_data.is_kyc_compliant);
        if (signup_progress.step_detail[2].error === true) {
            setErrors(signup_progress.step_detail[2].message);
        }
        if (signup_progress.kyc_data.dob !== null) {
            const [day, month, year] = signup_progress.kyc_data.dob.split('-');
            const start_date = new Date(`${year}-${month}-${day}`);
            _handleDate(start_date);
        }
        if (signup_progress.current_step > 2 || signup_progress.kyc_data.status === "PERSONAL_DETAIL_ADDED") {
            setTaxPaying(signup_progress.kyc_data.is_indian_tax_payer === true ? 2 : 1);
            setBirthCountry({
                label: signup_progress.kyc_data.birth_country, value: signup_progress.kyc_data.birth_country_id
            })
            if (signup_progress.kyc_data.is_kyc_compliant === false) {
                setFatherName(signup_progress.kyc_data.father_name === null ? "" : signup_progress.kyc_data.father_name);
                setMotherName(signup_progress.kyc_data.mother_name === null ? "" : signup_progress.kyc_data.mother_name);
            }
            if (signup_progress.kyc_data.identity_proof_url !== null) {
                setPanUrl(signup_progress.kyc_data.identity_proof_url);
                setPanName(_getFileName(signup_progress.kyc_data.identity_proof_url));
            }

            if (signup_progress.kyc_data.photo_proof_url !== null) {
                setPhotoUrl(signup_progress.kyc_data.photo_proof_url);
                setPhoto(_getFileName(signup_progress.kyc_data.photo_proof_url));
            }
            setCitizen({ label: signup_progress.kyc_data.country, value: signup_progress.kyc_data.country_code });
            if (signup_progress.kyc_data.signature_proof_url !== null) {
                setSignatureUrl(signup_progress.kyc_data.signature_proof_url);
                setSignature(_getFileName(signup_progress.kyc_data.signature_proof_url));
            }


            // if (signup_progress.kyc_data.aadhaar_card_url !== null) {
            //     setAadhaarUrl(signup_progress.kyc_data.aadhaar_card_url);
            //     setAadhaar(_getFileName(signup_progress.kyc_data.aadhaar_card_url));
            // }


            setGenderId(signup_progress.kyc_data.gender_id !== null ? signup_progress.kyc_data.gender_id : genderId);
            setMaritalId(signup_progress.kyc_data.marital_status_id !== null ? signup_progress.kyc_data.marital_status_id : maritalId);
            setCitizenType(signup_progress.kyc_data.is_indian_citizen !== null ? signup_progress.kyc_data.is_indian_citizen === true ? 1 : 2 : citizenType);

            if (signup_progress.kyc_data.is_indian_tax_payer === false) {
                setIdentificationType({ label: signup_progress.kyc_data.reference_proof, value: signup_progress.kyc_data.reference_proof_id });
                setTaxNumber(signup_progress.kyc_data.tax_reference_number !== null ? signup_progress.kyc_data.tax_reference_number : "");
                setTaxPayingCountry({ label: signup_progress.kyc_data.tax_country, value: signup_progress.kyc_data.tax_country_id });
            }

            if (signup_progress.kyc_data.occupation_id !== null) {
                setOccupation({ label: signup_progress.kyc_data.occupation, value: signup_progress.kyc_data.occupation_id });
            }

            if (signup_progress.kyc_data.income_source_id !== null) {
                setSource({ label: signup_progress.kyc_data.income_source, value: signup_progress.kyc_data.income_source_id });
            }
            if (signup_progress.kyc_data.annual_income_id !== null) {
                setAnnualIncome({ label: signup_progress.kyc_data.annual_income, value: signup_progress.kyc_data.annual_income_id });
            }

            setPoliticallyExposed(signup_progress.kyc_data.pep_type_id !== null ? signup_progress.kyc_data.pep_type_id : politicallyExposed);
        }
        //eslint-disable-next-line
    }, [PROGRESS_DATA]);




    // handle dob
    function _handleDate(selected_date) {
        const formatted_start_date = _formatDate(selected_date)
        setDob(formatted_start_date);
        setDefaultMonth(selected_date);
        setDobError("");
    }

    // handle gender
    function _handleGender(id) {
        setGenderId(id);
        setGenderError("");
    }

    // handle political
    function _handlePolitical(id) {
        setPoliticallyExposed(id);
    }

    // handle gender
    function _handleMaritalStatus(id) {
        setMaritalId(id);
    }

    // handle citizenship
    function _handleCitizenShip(id) {
        setCitizenType(id);
        setBirthCountryError("");
    }

    // handle citizenship
    function _handleCitizen(select_value) {
        setCitizen(select_value);
        setCitizenError("");
    }

    // handle birth country
    function _handleBirthCountry(select_value) {
        setBirthCountry(select_value);
        setBirthCountryError("");
    }

    // handle tax paying options
    function _handleTaxPaying(select_value) {
        setTaxPaying(select_value);
    }

    // handle identification
    function _handleIdentification(select_value) {
        setIdentificationType(select_value);
        setIdentificationTypeError("");
    }

    // handle tax number
    function _handleTaxNumber(select_value) {
        setTaxNumber(select_value);
        setTaxNumberError("");
    }

    // handle tax paying country
    function _handleTaxPayingCountry(input_value) {
        setTaxPayingCountry(input_value);
        setTaxPayingCountryError("");
    }

    // handle occupation
    function _handleOccupation(select_value) {
        setOccupation(select_value);
        setOccupationError("");
    }

    // handle occupation
    function _handleSource(select_value) {
        setSource(select_value);
        setSourceError("");
    }

    // handle annual income
    function _handleAnnualIncome(input_value) {
        setAnnualIncome(input_value);
        setAnnualIncomeError("");
    }

    // handle father name 
    function _handleFatherName(input_value) {
        setFatherName(input_value);
        setFatherNameError("");
    }

    // handle father name 
    function _handleMotherName(input_value) {
        setMotherName(input_value);
        setMotherNameError("");
    }

    // function handle PAN
    function _handlePAN(file) {
        if (file.size > 5242880) {
            setPanError("Upload PAN below 5MB");
        } else {
            _uploadPAN(file);
            setPanError("");
        }
    }

    // function handle aadhar
    // function _handleAadhar(file) {
    //     if (file.size > 5242880) {
    //         setaadhaarError("Upload PAN below 5MB");
    //     } else {
    //         _uploadAadhar(file);
    //         setaadhaarError("");
    //     }
    // }

    // function handle Photo
    function _handlePhoto(file) {
        if (file.size > 5242880) {
            setPhotoError("Upload Photo below 5MB");
        } else {
            _uploadPhoto(file);
            setPhotoError("");
        }
    }


    // function handle Signature
    function _handleSignature(file) {
        if (file.size > 5242880) {
            setSignatureError("Upload Signature below 5MB");
        } else {
            _uploadSignature(file);
            setSignatureError("");
        }
    }

    // handle form validation
    function _submitPersonalDetails() {
        let valid = true;

        if (taxPaying === 1 && taxPayingCountry === null) {
            taxPayingRef.current.scrollIntoView({ block: "start" });
            setTaxPayingCountryError("Please select your country of residence");
            valid = false;
        }
        if (taxPaying === 1 && taxNumber === "") {
            taxPayingRef.current.scrollIntoView({ block: "start" });
            setTaxNumberError("Please enter the tax reference number ");
            valid = false;
        }
        if (taxPaying === 1 && identificationType === null) {
            taxPayingRef.current.scrollIntoView({ block: "start" });
            setIdentificationTypeError("Please select identification type");
            valid = false;
        }
        if (birthCountry === null) {
            citizenRef.current.scrollIntoView({ block: "start" });
            setBirthCountryError("Please select your birth country");
            valid = false;
        }
        if (citizenType === 2 && citizen === null) {
            citizenRef.current.scrollIntoView({ block: "start" });
            setCitizenError("Please select your citizenship");
            valid = false;
        }
        if (genderId === null) {
            dobRef.current.scrollIntoView({ block: "end" });
            setGenderError("Please select your gender");
            valid = false;
        }
        if (dob === "DD-MM-YYYY") {
            dobRef.current.scrollIntoView({ block: "start" });
            setDobError("Please select your date of birth");
            valid = false;
        }
        if (occupation === null) {
            setOccupationError("Please select your occupation");
            valid = false;
        }
        if (source === null) {
            setSourceError("Please select your source of income");
            valid = false;
        }
        if (annualIncome === null) {
            setAnnualIncomeError("Please select your annual income");
            valid = false;
        }

        if (kycUser === false) {

            if (fatherName === "") {
                dobRef.current.scrollIntoView({ block: "start" });
                setFatherNameError("Please provide your father's name");
                valid = false;
            }

            if (motherName === "") {
                dobRef.current.scrollIntoView({ block: "start" });
                setMotherNameError("Please provide your mother's name");
                valid = false;
            }
            //  all non KYC specific validations
        }
        if (kycUser === false) {
            if (panUrl === "") {
                setPanError("Please upload your PAN card");
                valid = false;
            }

            if (photoUrl === "") {
                setPhotoError("Please upload your photo ");
                valid = false;
            }

            if (signatureUrl === "") {
                setSignatureError("Please upload your signature");
                valid = false;
            }

            // if (aadhaarUrl === "") {
            //     setaadhaarError("Please upload your aadhaar");
            //     valid = false;
            // }
        }

        if (valid === true) {
            _savePersonalDetails();
        }
    }

    // API - get countries
    const _getCountries = () => {
        const url = "/general/countries";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                const citizenship_options = [];
                const tax_paying_countries = [];

                response.data.countries.map((item) => {
                    return (
                        citizenship_options.push({
                            label: item.name,
                            value: item.id
                        })
                    );
                });
                response.data.countries_for_address.map((item) => {
                    return (
                        tax_paying_countries.push({
                            label: item.name,
                            value: item.id
                        })
                    );
                });
                setCitizenShipOptions(citizenship_options);
                setTaxPayingCountryOptions(tax_paying_countries);

            }
        });
    }

    // API - upload PAN
    const _uploadPAN = async (file) => {
        setPanUploader(true);
        const request = {
            type: "image",
            file: file.name,
            is_public: false
        }

        await _getFilePath(request).then((response) => {
            toast.dismiss();
            _uploadDocumentToS3(response.data, file).then((result) => {
                setPanName(file.name);
                setPanUrl(response.data.view_info);
                setPanUploader(false);
            }).catch((error) => {
                setPanUploader(false);
                toast.error(error, {
                    type: "error"
                });
            });
        });
    }

    // API - upload Aadhar
    // const _uploadAadhar = async (file) => {
    //     setaadhaarUploader(true);
    //     const request = {
    //         type: "image",
    //         file: file.name,
    //         is_public: false
    //     }

    //     await _getFilePath(request).then((response) => {
    //         toast.dismiss();
    //         _uploadDocumentToS3(response.data, file).then((result) => {
    //             setAadhaar(file.name);
    //             setAadhaarUrl(response.data.view_info);
    //             setaadhaarUploader(false);
    //         }).catch((error) => {
    //             setaadhaarUploader(false);
    //             toast.error(error, {
    //                 type: "error"
    //             });
    //         });
    //     });
    // }

    // API - upload Photo
    const _uploadPhoto = async (file) => {
        setPhotoUploader(true);
        const request = {
            type: "image",
            file: file.name,
            is_public: false
        }

        await _getFilePath(request).then((response) => {
            toast.dismiss();
            _uploadDocumentToS3(response.data, file).then((result) => {
                setPhoto(file.name);
                setPhotoUrl(response.data.view_info);
                setPhotoUploader(false);
            }).catch((error) => {
                setPhotoUploader(false);
                toast.error(error, {
                    type: "error"
                });
            });

        });
    }

    // API - upload Signature
    const _uploadSignature = async (file) => {
        setSignatureUploader(true);

        const request = {
            type: "image",
            file: file.name,
            is_public: false
        }

        await _getFilePath(request).then((response) => {
            toast.dismiss();
            _uploadDocumentToS3(response.data, file).then((result) => {
                setSignature(file.name);
                setSignatureUrl(response.data.view_info);
                setSignatureUploader(false);
            }).catch((error) => {
                setSignatureUploader(false);
                toast.error(error, {
                    type: "error"
                });
            });

        });
    }


    // API - save personal details
    const _savePersonalDetails = () => {
        setApiLoader(true);
        const url = "/kyc/save-personal-data";

        const request = {
            "dob": dob,
            "gender_id": genderId,
            "is_indian_citizen": citizenType === 1 ? true : false,
            "country_id": citizenType === 2 ? citizen.value : null,
            "is_indian_tax_payer": taxPaying === 1 ? false : true,
            "reference_proof_id": taxPaying === 1 ? identificationType.value : null,
            "tax_reference_number": taxPaying === 1 ? taxNumber : "",
            "tax_country_id": taxPaying === 1 ? taxPayingCountry.value : null,
            "occupation_id": occupation.value,
            "income_source_id": source.value,
            "annual_income_id": annualIncome.value,
            "marital_status_id": maritalId,
            "father_name": fatherName,
            "mother_name": motherName,
            "photo_proof_url": photoUrl,
            "signature_proof_url": signatureUrl,
            "identity_proof_url": panUrl,
            // "aadhaar_card_url": aadhaarUrl,
            "pep_type_id": politicallyExposed,
            "birth_country_id": birthCountry.value
        }

        APIService(true, url, request).then(async (response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                await _getSignupProgress().then((response) => {
                    if (response.kyc_data.is_kyc_compliant === true) {
                        props.handleNavigate(4)
                    } else {
                        if (response.kyc_data.is_identity_verified === true) {
                            props.handleNavigate(5, true)
                        } else {
                            props.handleNavigate(4, false)
                        }
                    }
                })

                toast.success("Personal details added successfully", {
                    type: "success"
                });
            } else {
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        });
    }

    return (
        <Fragment>
            <Icon icon="Arrow-Right"
                size={32}
                className="margin-12px-tb rotate-left cursor-pointer"
                onClick={props.navigateBack} />

            <div className="row pt-lg-3 pt-0">
                <div className="col-lg-7">

                    {/* title section */}
                    <Fragment>
                        <StepProgress type={2}
                            step={props.completed}
                            stepNumber={3}
                            maxStep={kycUser ? 6 : 8}
                        />
                        <h6 className="color-eerie-black e-font-16 e-line-height-32 e-montserrat-semi-bold mb-0 mt-4">Personal Details</h6>
                    </Fragment>

                    {
                        errors.length > 0 &&
                        <KYCErrorCard errors={errors} />
                    }

                    {/* DOB */}
                    <div className="row pt-2">
                        <div className="col-sm-5 pb-4" ref={dobRef}>
                            <CustomDatePicker error={dobError}
                                disabledDays={disabledDays}
                                label="Date of Birth"
                                postfix={true}
                                start={dob}
                                toYear={new Date().getFullYear() - 18}
                                mode="single"
                                defaultMonth={defaultMonth}
                                selectDate={_handleDate} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6 py-1">
                            <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text mb-0 bg-transparent`}>
                                Gender
                                <span className='color-deep-carmine-pink'>
                                    *
                                </span>
                            </p>
                            <RadioGroup data={genderOptions}
                                selected={genderId}
                                error={genderError}
                                handleSelect={_handleGender} />
                        </div>
                        <div className="col-sm-6 py-1">
                            <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text mb-0 bg-transparent`}>
                                Your marital status
                                <span className='color-deep-carmine-pink'>
                                    *
                                </span>
                            </p>
                            <RadioGroup data={maritalStatusOptions}
                                selected={maritalId}
                                handleSelect={_handleMaritalStatus} />
                        </div>
                    </div>
                    {
                        kycUser === false && <div className="row pt-3">
                            <div className="col-sm-6">
                                <CustomTextInput placeholder="James"
                                    error={fatherNameError}
                                    postfix="*"
                                    label="Father name"
                                    inputClass="e-kyc-input"
                                    value={fatherName}
                                    handleChange={_handleFatherName} />
                            </div>
                            <div className="col-sm-6 mt-sm-0 mt-4">
                                <CustomTextInput placeholder="Monica"
                                    error={motherNameError}
                                    postfix="*"
                                    label="Mother name"
                                    inputClass="e-kyc-input"
                                    value={motherName}
                                    handleChange={_handleMotherName} />
                            </div>
                        </div>
                    }
                    <div className="row mt-4">
                        <div className="col-12">
                            <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text mb-0 bg-transparent`}>
                                Are you a politically exposed person?
                                <span className='color-deep-carmine-pink'>
                                    *
                                </span>
                            </p>
                            <RadioGroup data={politicallyExposedOptions}
                                selected={politicallyExposed}
                                handleSelect={_handlePolitical} />
                        </div>
                    </div>
                    <div className="border-top-1px border-bright-gray my-3" ></div>

                    <h6 className="color-eerie-black e-font-16 e-line-height-32 e-montserrat-semi-bold mb-3">Nationality, Tax and Source</h6>

                    <div className="row">
                        <div className="col-sm-6 pb-4" ref={citizenRef}>
                            <CustomDropdown options={taxPayingCountryOptions}
                                placeholder="Select country"
                                error={birthCountryError}
                                postfix="*"
                                value={birthCountry}
                                label="Country of birth"
                                isSearchable={true}
                                message="No country found"
                                onSelectChange={_handleBirthCountry} />
                        </div>

                    </div>

                    <div className="row pb-3">
                        <div className="col-sm-6">
                            <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text mb-0 bg-transparent`}>
                                Are your an Indian citizen?
                                <span className='color-deep-carmine-pink'>
                                    *
                                </span>
                            </p>
                            <RadioGroup data={CITIZEN_OPTIONS}
                                selected={citizenType}
                                handleSelect={_handleCitizenShip} />
                        </div>
                    </div>
                    <div className="row">
                        {citizenType !== 1 && <div className="col-sm-6 pb-4" ref={citizenRef}>
                            <CustomDropdown options={citizenShipOptions}
                                placeholder="Select citizenship"
                                error={citizenError}
                                postfix="*"
                                value={citizen}
                                message="No country found"
                                isSearchable={true}
                                label="Country of citizenship"
                                onSelectChange={_handleCitizen} />
                        </div>
                        }
                    </div>

                    <div className="row">
                        <div className="col-sm-7" ref={taxPayingRef}>
                            <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text mb-0 bg-transparent`}>
                                Are you a tax-paying resident in any other country?
                                <span className='color-deep-carmine-pink'>
                                    *
                                </span>
                            </p>
                            <RadioGroup data={CITIZEN_OPTIONS}
                                selected={taxPaying}
                                handleSelect={_handleTaxPaying} />
                        </div>
                    </div>

                    {taxPaying === 1 && <Fragment>
                        <div className="row pb-4 pt-3">
                            <div className="col-sm-6 pb-sm-0 pb-4">
                                <CustomDropdown options={taxPayingCountryOptions}
                                    placeholder="Select country"
                                    error={taxPayingCountryError}
                                    value={taxPayingCountry}
                                    label="Tax paying country"
                                    postfix="*"
                                    message="No country found"
                                    isSearchable={true}
                                    onSelectChange={_handleTaxPayingCountry} />

                            </div>
                            <div className="col-sm-6 ">
                                <CustomDropdown options={identificationOptions}
                                    placeholder="Select identification"
                                    error={identificationTypeError}
                                    value={identificationType}
                                    label="Identification type"
                                    postfix="*"
                                    message="No data found"
                                    isSearchable={false}
                                    onSelectChange={_handleIdentification} />

                            </div>
                        </div>
                        <div className="row pb-1">
                            <div className="col-sm-6 ">
                                <CustomTextInput placeholder="Enter reference number"
                                    error={taxNumberError}
                                    postfix="*"
                                    value={taxNumber}
                                    label="Tax reference number"
                                    inputClass="e-kyc-input"
                                    handleChange={_handleTaxNumber} />
                            </div>
                        </div>
                    </Fragment>}
                    <div className="row pt-3">
                        <div className="col-sm-6 pb-sm-0 pb-4">
                            <OccupationDropdown options={occupationOptions}
                                placeholder="Select Occupation"
                                error={occupationError}
                                value={occupation}
                                label="Occupation"
                                postfix="*"
                                onSelectChange={_handleOccupation} />
                        </div>
                        <div className="col-sm-6 ">
                            <IncomeSourceDropdown options={sourceOptions}
                                placeholder="Select Source of income"
                                error={sourceError}
                                value={source}
                                postfix="*"
                                label="Source of income"
                                onSelectChange={_handleSource} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 pt-4">
                            <CustomDropdown label="Annual income"
                                postfix="*"
                                options={annualIncomeOptions}
                                value={annualIncome}
                                error={annualIncomeError}
                                placeholder="Select Annual income"
                                message="No income option found"
                                onSelectChange={_handleAnnualIncome} />
                        </div>

                        {
                            kycUser === false &&
                            <>
                                <div className="col-sm-6 pt-4">
                                    <CustomFileInput
                                        label="Upload PAN(Max. file size 5mb)"
                                        postfix={"*"}
                                        file={panName}
                                        error={panError}
                                        id="e-pan-upload"
                                        loader={panUploader}
                                        accept=".png, .jpg, .jpeg"
                                        onFileChange={_handlePAN}
                                        clear={() => {
                                            setPanName("");
                                            setPanUrl("");
                                        }} />
                                </div>

                                <div className="col-sm-6 pt-4">
                                    <CustomFileInput
                                        label="Upload your photo(Max. file size 5mb)"
                                        postfix={"*"}
                                        file={photo}
                                        error={photoError}
                                        loader={photoUploader}
                                        id="e-photo-upload"
                                        accept=".png, .jpg, .jpeg"
                                        onFileChange={_handlePhoto}
                                        clear={() => {
                                            setPhoto("");
                                            setPhotoUrl("");
                                        }} />
                                </div>

                                <div className="col-sm-6 pt-4">
                                    <CustomFileInput
                                        label="Upload Signature(Max. file size 5mb)"
                                        postfix={"*"}
                                        id="e-signature-upload"
                                        file={signature}
                                        error={signatureError}
                                        loader={signatureUploader}
                                        accept=".png, .jpg, .jpeg"
                                        onFileChange={_handleSignature}
                                        clear={() => {
                                            setSignature("");
                                            setSignatureUrl("");
                                        }} />
                                </div>
                            </>
                        }

                        {/* <div className="col-sm-6 pt-4">
                            <CustomFileInput
                                label="Upload Aadhaar(Max. file size 5mb)"
                                postfix={kycUser === false && "*"}
                                file={aadhaar}
                                error={aadhaarError}
                                id="e-aadhar-upload"
                                type={"PNG or JPG or PDF"}
                                loader={aadhaarUploader}
                                accept=".png, .jpg, .jpeg, .pdf"
                                onFileChange={_handleAadhar}
                                clear={() => {
                                    setAadhaar("")
                                    setAadhaarUrl("");
                                }} />
                        </div> */}
                    </div>



                    <div className="border-top-1px border-bright-gray my-4" />
                    <div className="row">
                        <div className="col-xl-3 col-lg-5 col-sm-6 d-lg-block d-none">
                            <PrimaryButton label="Save & Continue"
                                disabled={apiLoader}
                                className="padding-12px-tb w-100"
                                onPress={_submitPersonalDetails} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 ">
                    <div className="d-sm-block d-none">
                        <KYCProgressCard steps={props.investment_steps}
                            completedStep={props.completedStep} />
                    </div>
                    <div className="row justify-content-center">

                        <div className="col-md-6 col-sm-6 col-6 d-lg-none d-block  pt-3">
                            <PrimaryButton label="Save & Continue"
                                disabled={apiLoader}
                                className="padding-12px-tb w-100"
                                onPress={_submitPersonalDetails} />
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default PersonalDetails