// import packages
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useLocation } from 'react-router-dom/dist';
import OneSignal from 'react-onesignal';


// import components, containers
import { ConfigurePIN, ForgotPIN, Signup, Unlock } from './Containers/Auth';
import { CustomFooter, LeftNavigation } from './Components/Navigation';
import { ActiveVsPassive, FinancialFreedom, FinancialSummary, Home } from './Containers/Home';
import { Toast } from './Components/Message';
import { Business, Crorepati, EducationGoal, Gift, GoalListing, HomeGoal, NRI, Other, RegularIncome, RetirementGoal, SurplusFund, TaxEfficient, TaxSavings, Vacation, VehicleGoal, Wealth, Wedding } from './Containers/Goals';
import { AboutUs, Calculator, FAQ, ViewTAT } from './Containers/Others';
import { KYC } from './Containers/KYC';
import { Explore, FundDetail } from './Containers/Explore';

import './App.css';

// services
import APIService from './Services/api-service';
import { _getGeneralData, _getNotificationCount, _getProfile, _getSignupProgress } from './Helper/api';
import { _logout } from './Helper/helper';

import RiskProfiling from './Containers/RiskProfiling';
import { BasketOrders, FundOrders, GoalOrders, OrderList } from './Containers/Orders';
import { Portfolio, PortfolioDetail } from './Containers/Portfolio';
import { AccountSettings, AddNominee, KycStatus, ListBanks, ListNominees, Profile, ReferEarn } from './Containers/Profile';
import { NotificationList } from './Containers/Notification';
import { TicketDetails, SupportList } from './Containers/Support';
import { Listing } from './Containers/Plans';
import { ResourceDetail, ResourceList } from './Containers/Resources';
import { ReportSummary } from './Containers/Reports';
import store from './Store';
import { toast } from 'react-toastify';
import { EmptyScreen } from './Components/Other';
import AppsFlyerWebSDK from 'appsflyer-web-sdk';


import NoInternet from "./Assets/Images/Loader/no-internet-1.png";



function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        const scrollTimeout = setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 0); // Adjust timeout if needed

        return () => clearTimeout(scrollTimeout);
    }, [pathname]);

    return null;
}


function App() {
    const appsflyer = new AppsFlyerWebSDK()
    const IS_LOGGED_IN = useSelector(state => state.Reducer.IS_LOGGED_IN);
    const REFRESH_TOKEN = useSelector(state => state.Reducer.REFRESH_TOKEN);
    // eslint-disable-next-line
    const [browserZoom, setBrowserZoom] = useState(100);
    const [notificationActive, setNotificationActive] = useState(false);
    const [isInternetConnected, setIsInternetConnected] = useState(navigator.onLine);

    useEffect(() => {
        try {
            appsflyer.initSdk({
                devKey: 'HyHNLhqpWF6xfspuPW8cpG',
                platform: "WEB",
                isDebug: false,
                isSandbox: false,
            });
        } catch (error) {
            console.error("Init failed:", error);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const handleOnlineStatus = () => {
            setIsInternetConnected(navigator.onLine);
            store.dispatch({
                type: "LOGIN_STATUS",
                payload: false
            })
            sessionStorage.removeItem('mintit_access_token');
        };

        window.addEventListener('online', handleOnlineStatus);
        window.addEventListener('offline', handleOnlineStatus);

        return () => {
            window.removeEventListener('online', handleOnlineStatus);
            window.removeEventListener('offline', handleOnlineStatus);
        };
    }, []);

    useEffect(() => {
        OneSignal.init({ appId: process.env.REACT_APP_ONESIGNAL_ID }).then(() => {
            _getNotificationId()
            // do other stuff
        });
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        if (IS_LOGGED_IN === true) {
            if (Cookies.get("mintit_refresh_token")) {
                store.dispatch({
                    type: "REFRESH_TOKEN",
                    payload: Cookies.get("mintit_refresh_token")
                })
            }
        }
        //eslint-disable-next-line
    }, [Cookies.get("mintit_refresh_token"), isInternetConnected]);

    useEffect(() => {

        window.addEventListener('popstate', function () {


            // Removing no scroll form body 
            document.body.classList.remove("body-no-scroll");

            // Removing modal backdrop for all modals when browser back button clicked
            if (document.querySelector(".modal-backdrop")) {
                document.querySelector(".modal-backdrop").remove();
                document.body.style.overflow = 'unset';
            };

        });

    }, []);

    useEffect(() => {
        if (IS_LOGGED_IN === true) {
            _getNotificationCount()
        }
    }, [IS_LOGGED_IN])


    // Update browser zoom level
    useEffect(() => {


        function updateZoom() {
            // const new_zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
            // const scale = window.devicePixelRatio;
            // if (scale >= 1.5) {
            //     setBrowserZoom(Math.ceil(new_zoom));
            // }

            // const default_zoom = window.devicePixelRatio;

            // const initial_value = `${default_zoom * 100}%`;
            // if (initial_value >= "150%") {
            //     console.log(initial_value)
            //     document.getElementById("e-body").style.zoom = "100%";
            // } else {
            //     document.getElementById("e-body").style.zoom = "100%";
            // }

            // setBrowserZoom(initial_value);
            // setBrowserZoom(initial_value);


        }

        updateZoom();

        window.addEventListener("resize", updateZoom);

        return () => {
            window.removeEventListener("resize", updateZoom);
        };

    }, []);


    useEffect(() => {
        if (IS_LOGGED_IN === true && REFRESH_TOKEN !== null) {
            _validateRefreshToken();
            _getProfile();
            _getSignupProgress();
            _getGeneralData();
        }
        //eslint-disable-next-line
    }, [])

    const _getNotificationId = async () => {
        let permission = await OneSignal.Notifications.permission;
        if (permission === true) {
            let device_id = await OneSignal.User.PushSubscription.id;
            let opted_in = await OneSignal.User.PushSubscription.optedIn;
            setNotificationActive(opted_in);
            _saveNotificationId(device_id);
        };
    };


    // API - save notification Id
    const _saveNotificationId = async (device_id) => {
        let api = '/notification/save-id';
        let params = {
            player_id: device_id
        };
        try {
            let response = await APIService(true, api, params);
            if (response.status_code === 200) {
            }
        }
        catch (error) {
            toast.dismiss();
            toast.error(error, {
                type: "error"
            })
        }
    }

    // API - validate refresh token
    const _validateRefreshToken = (token) => {
        const url = "/auth/validate-refresh-token";

        APIService(false, url, null, Cookies.get("mintit_refresh_token")).then((response) => {
            if (response.status_code === 200) {

            } else {
                _logout();
            }
        })
    }

    return (
        <Router>
            <ScrollToTop />
            <div id="e-body" className={` ${browserZoom > 150 ? 'e-app-zoom-level-7' :
                browserZoom === 150 ? 'e-app-zoom-level-8' :
                    browserZoom === 110 ? 'e-app-zoom-level-9' : null
                }`}>
                {
                    isInternetConnected === true ?
                        IS_LOGGED_IN === true && REFRESH_TOKEN !== null ?
                            <Fragment>
                                <div className='d-lg-none d-block'>
                                    <LeftNavigation type="offcanvas" />
                                </div>
                                <div className='row'>

                                    <div className='col-lg-1 pe-0 d-lg-block d-none'>
                                        <LeftNavigation />
                                    </div>

                                    <div className="col-lg-11 ps-lg-0 ps-4 pt-3 overflow-x-hidden">

                                        <Routes>
                                            <Route path="/" >
                                                <Route index element={<Home />} />
                                                <Route path="calculators" element={<Calculator />} />
                                                <Route path="financial-freedom" element={<FinancialFreedom />} />
                                                <Route path="financial-summary" element={<FinancialSummary />} />
                                                <Route path="profile" element={<Profile />} />
                                                <Route path="settings" element={<AccountSettings active={notificationActive} />} />
                                                <Route path="faq" element={<FAQ type={2} />} />
                                                <Route path="systematic-transactions" element={<Listing />} />
                                                <Route path="manage-bank" element={<ListBanks />} />
                                                <Route path="active-vs-passive" element={<ActiveVsPassive />} />
                                                <Route path="about-us" element={<AboutUs />} />
                                                <Route path="manage-kyc" element={<KycStatus />} />
                                                <Route path="refer-earn" element={<ReferEarn />} />
                                                <Route path="view-tat" element={<ViewTAT />} />
                                            </Route>

                                            <Route path="/portfolio">
                                                <Route index element={<Portfolio />} />
                                                <Route path="detail" element={<PortfolioDetail />} />
                                            </Route>

                                            <Route path="/milestone">
                                                <Route index element={<GoalListing />} />
                                                <Route path="retirement" element={<RetirementGoal />} />
                                                <Route path="car" element={<VehicleGoal />} />
                                                <Route path="home" element={<HomeGoal />} />
                                                <Route path="education" element={<EducationGoal />} />
                                                <Route path="business" element={<Business />} />
                                                <Route path="crore-pati" element={<Crorepati />} />
                                                <Route path="wedding" element={<Wedding />} />
                                                <Route path="vacation" element={<Vacation />} />
                                                <Route path="gift" element={<Gift />} />
                                                <Route path="other" element={<Other />} />
                                                <Route path="nri" element={<NRI />} />
                                                <Route path="wealth" element={<Wealth />} />
                                                <Route path="tax-savings" element={<TaxSavings />} />
                                                <Route path="surplus-fund" element={<SurplusFund />} />
                                                <Route path="regular-income" element={<RegularIncome />} />
                                                <Route path="tax-efficient" element={<TaxEfficient />} />
                                            </Route>

                                            <Route path="/explore">
                                                <Route index element={<Explore />} />
                                                <Route path="detail" element={<FundDetail />} />
                                            </Route>

                                            <Route path="/kyc">
                                                <Route index element={<KYC />} />
                                            </Route>

                                            <Route path="/orders">
                                                <Route index element={<OrderList />} />
                                                <Route path="goal-order" element={<GoalOrders />} />
                                                <Route path="fund-order" element={<FundOrders />} />
                                                <Route path="basket-order" element={<BasketOrders />} />
                                            </Route>

                                            <Route path="/risk-profile">
                                                <Route index element={<RiskProfiling />} />
                                            </Route>
                                            <Route path="/nominee">
                                                <Route index element={<ListNominees />} />
                                                <Route path="add-nominee" element={<AddNominee />} />
                                            </Route>

                                            <Route path="/notifications">
                                                <Route index element={<NotificationList />} />
                                            </Route>

                                            <Route path="/support">
                                                <Route index element={<SupportList />} />
                                                <Route path="detail" element={<TicketDetails />} />
                                            </Route>

                                            <Route path="/resource">
                                                <Route index element={<ResourceList />} />
                                                <Route path="detail" element={<ResourceDetail />} />
                                            </Route>

                                            <Route path="/reports" element={<ReportSummary />}></Route>

                                            <Route path="*" element={<Navigate to="/" />} />
                                        </Routes>
                                        <CustomFooter containerClass="mx-0" />
                                    </div>
                                </div>
                            </Fragment>
                            :
                            REFRESH_TOKEN !== null ?
                                <Fragment>
                                    <Routes>
                                        <Route path="/unlock" element={<Unlock />} />
                                        <Route path="/" element={<Navigate to="/unlock" />} />
                                        <Route path="/forgot-pin" element={<ForgotPIN />} />
                                        <Route path="/configure-pin" element={<ConfigurePIN />} />
                                        <Route path="*" element={<Navigate to="/unlock" />} />
                                    </Routes>

                                    <CustomFooter background="bg-white"
                                        containerClass="container"
                                        type={1} />
                                </Fragment>
                                :
                                <Fragment>
                                    <Routes>
                                        <Route path="/" element={<Signup />} />
                                        <Route path="/unlock" element={<Unlock />} />
                                        <Route path="/forgot-pin" element={<ForgotPIN />} />
                                        <Route path="/configure-pin" element={<ConfigurePIN />} />
                                        <Route path="/faq" element={<FAQ type={1} />} />
                                        <Route path="*" element={<Signup />} />
                                    </Routes>

                                    <CustomFooter background="bg-white"
                                        containerClass="container"
                                        type={1} />
                                </Fragment>
                        :
                        <div className='row justify-content-center'>
                            <div className='col-7'>
                                <EmptyScreen image={NoInternet}
                                    title="No Internet"
                                    type={8}
                                    description=""
                                    width={100}
                                    className="h-80vh" />
                            </div>
                        </div>
                }
            </div>
            <Toast />

        </Router>

    );
}

export default App;

