/*
*   File : list.js
*   Author : https://evoqins.com
*   Description : Resource list
*   Version : 1.0.0
*/

// import packages
import React, { useState, useEffect, Fragment } from "react";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";

// import components
import { Breadcrumb, EmptyScreen, Loader, SectionTitle } from "../../Components/Other"
import { Header } from "../../Components/Header";
import { ResourceCard } from "../../Components/Card";
import { CustomTabBar } from "../../Components/Tab";
import { Icon } from "../../Components/Icon";

// import services
import APIService from "../../Services/api-service";

// import style
import Color from "../../Styles/color.module.scss";

const NextIcon = () => {
    return (
        <Icon icon='Arrow-Right'
            width="24px"
            height="24px"
            color={Color.rebecca_purple} />
    )
}

const PrevIcon = () => {
    return (
        <Icon icon='Arrow-Right'
            width="24px"
            height="24px"
            color={Color.rebecca_purple}
            className="e-transform-left-arrow" />
    )
}

const BreakLabel = () => {
    return (
        <span>...</span>
    )
}


const ResourceList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [screenLoader, setScreenLoader] = useState(false);
    const [pageLoader, setPageLoader] = useState(true);

    const [tabIndex, setTabIndex] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState("All");

    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(null);

    const [resourceFilter, setResourceFilter] = useState([]);
    const [resourceList, setResourceList] = useState([]);

    useEffect(() => {
        const _handleBackNavigation = (event) => {
            // This function will be called when the back button is pressed
            navigate("/")
            // You can perform additional actions here, such as showing a confirmation dialog
        };

        // Add the event listener
        window.addEventListener('popstate', _handleBackNavigation);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('popstate', _handleBackNavigation);
        };
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        _getResourceFilters();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (location.state !== null) {
            _getResources(location.state.tab_index, location.state.page)
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (location.state === null) {
            _getResources(tabIndex, currentPage);
        }
        //eslint-disable-next-line
    }, []);


    function _navigatePreviousPage() {
        navigate("/");
    }

    // handle tab
    function _handleTabIndex(tab_index) {
        _getResources(tab_index, 1)
        navigate("/resource", {
            state: {
                tab_index: tab_index,
                page: currentPage
            }
        }, { replace: true });
        const index = resourceFilter.findIndex((item) => item.id === tab_index);
        setSelectedCategory(resourceFilter[index].label);
    }

    // handle page 
    function _handlePageChange(pageNumber) {
        _getResources(tabIndex, pageNumber + 1)
        window.scrollTo(0, 0);
    };

    // Navigate to detail
    function _handleNavigate(id) {
        navigate("/resource", {
            state: {
                tab_index: tabIndex,
                page: currentPage,
            }
        })
        setTimeout(() => {
            navigate("detail", {
                state: {
                    id: id,
                    tab_index: tabIndex,
                    page: currentPage,
                }
            }, { replace: true });
        }, 0)
    }
    // API - get resource filters 
    const _getResourceFilters = () => {
        const url = "/resource/filter-data";

        APIService(false, url).then((response) => {
            const resource_filter = [];
            resource_filter.push({
                label: "All",
                id: null
            });
            if (response.status_code === 200) {
                response.data.category.map((item) => {
                    return (
                        resource_filter.push({
                            label: item.label,
                            id: item.value
                        })
                    )
                });
                setResourceFilter(resource_filter);
                if (location.state === null) {
                    setTabIndex(resource_filter[0].id);
                    setSelectedCategory(resource_filter[0].label);
                } else {
                    setTabIndex(location.state.tab_index);
                    const index = resource_filter.findIndex((item) => item.id === location.state.tab_index);
                    setSelectedCategory(resource_filter[index].label);
                }
            } else {
                setResourceFilter([]);
            }
            setPageLoader(false);
        });
    }

    // API - resource list
    const _getResources = (category = null, page_no = 1) => {
        setTabIndex(category);
        setCurrentPage(page_no);

        setScreenLoader(true)
        const url = "/resource/list";
        const request = {
            "page_num": page_no,
            "page_size": 12,
            "category_ids": category === null ? [] : [category]
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setResourceList(response.data.data);
                setTotalCount(response.data.total_page);
            } else {
                setResourceList([]);
            }
            setScreenLoader(false);
        })
    }

    return (
        pageLoader === true ?
            <Loader height="h-80vh" />
            :
            <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                <Header type={2} />
                <div className="row ">
                    <div className="col-lg-11 padding-144px-bottom">
                        <section className="px-4 ">
                            <Breadcrumb mainPage="Home"
                                currentPage="Learn"
                                type={1}
                                handleNavigate={_navigatePreviousPage} />

                            <SectionTitle label="Resources"
                                className="e-line-height-38 margin-30px-top" />

                            <div className="border-bottom-1px border-chinese-white  margin-12px-top">
                                <CustomTabBar selectedTab={tabIndex}
                                    tabClass="px-2"
                                    data={resourceFilter}
                                    onSelectTab={_handleTabIndex} />
                            </div>

                            <div className="mt-4">

                                <h6 className="e-font-16 e-line-height-24 e-montserrat-semi-bold mb-4 color-eerie-black">
                                    {selectedCategory}
                                </h6>
                                {
                                    screenLoader === true ?
                                        <Loader height="h-80vh" />
                                        :
                                        resourceList.length === 0 ?
                                            <EmptyScreen className="my-5"
                                                title="No resources found"
                                                width={200}
                                                height="h-auto"
                                                type={1}
                                                description="" />
                                            :
                                            <Fragment>
                                                <div className="row">
                                                    {
                                                        resourceList.map((item, index) => {
                                                            return (
                                                                <div key={index} className="col-md-4 mb-3" onClick={() => _handleNavigate(item.resource_id)}>
                                                                    <ResourceCard data={item} />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <ReactPaginate previousLabel={<PrevIcon />}
                                                    nextLabel={<NextIcon />}
                                                    breakLabel={<BreakLabel />}
                                                    pageCount={totalCount}
                                                    pageRangeDisplayed={2}
                                                    marginPagesDisplayed={1}
                                                    onPageChange={(value) => _handlePageChange(value.selected)}
                                                    renderOnZeroPageCount={null}
                                                    forcePage={currentPage - 1}
                                                    containerClassName="e-pagination "
                                                    activeClassName="e-pagination-active" />
                                            </Fragment>
                                }
                            </div>
                        </section>
                    </div>
                </div>
            </div>
    )
}

export default ResourceList